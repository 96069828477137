import {$http} from '@/utils/http'
import {objectToEncodedQueryString} from '@/helpers/variables/index';

import {
  MASTERCLASS_LIST,
  MASTERCLASS_LIST_LOADING,
  MASTERCLASS_DATA,
  MASTERCLASS_DATA_LOADING, CONSULTATION_LOADING,
} from '../mutation-types';

import {
  MASTERCLASS_LIST_GET,
  MASTERCLASS_DATA_GET, SIGN_CONSULTATION,
} from '../action-types';


const state = {
  masterClasses: {
    list: [],
    meta: null,
    isLoading: false,
    isDone: false,
    error: null,
    limit: 3,
  },
  masterClass: {
    data: null,
    isLoading: false,
    isDone: false,
    error: null,
  },
  consultationLoading: false

};

const getters = {
  consultationLoading: state => state.consultationLoading,
  masterClassesList: state => state.masterClasses.list,
  masterClassesLimit: state => state.masterClasses.limit,
  masterClassesMeta: state => state.masterClasses.meta,
  isLoadingMasterClasses: state => state.masterClasses.isLoading,

  masterClassData: state => state.masterClass.data,
  isLoadingMasterClass: state => state.masterClass.isLoading,
};

const actions = {
  [SIGN_CONSULTATION]: async ({commit}, payload) => {
    try {
      let slug = payload.slug
      delete payload.slug;

      commit(CONSULTATION_LOADING, true);
      const result = await $http.post(`/v1/consultations`, payload);

    } catch (e) {
      throw e;
    } finally {
      commit(CONSULTATION_LOADING, false);
    }
  },
  [MASTERCLASS_LIST_GET]: async ({commit}, params) => {
    commit(MASTERCLASS_LIST_LOADING, true);
    try {
      const query = `limit=${state.masterClasses.limit}&${objectToEncodedQueryString(params)}`;
      const response = await $http.get(`v1/library/master_class?${query}`);
      commit(MASTERCLASS_LIST, response.data);
    } catch (e) {
      throw e;
    } finally {
      commit(MASTERCLASS_LIST_LOADING, false);
    }
  },

  [MASTERCLASS_DATA_GET]: async ({commit}, slug) => {
    commit(MASTERCLASS_DATA_LOADING, true);
    try {
      const response = await $http.get(`v1/library/master_class/${slug}`);
      commit(MASTERCLASS_DATA, response.data);
    } catch (e) {
      throw e;
    } finally {
      commit(MASTERCLASS_DATA_LOADING, false);
    }
  },
};

const mutations = {
  [MASTERCLASS_LIST](state, data) {
    state.masterClasses.list = data.data ? data.data : [];
    state.masterClasses.meta = data.meta ? data.meta : null;
  },
  [MASTERCLASS_LIST_LOADING](state, status) {
    state.masterClasses.isLoading = status;
  },
  [MASTERCLASS_DATA](state, data) {
    state.masterClass.data = data.data ? data.data : null;
  },
  [MASTERCLASS_DATA_LOADING](state, status) {
    state.masterClass.isLoading = status;
  },
  [CONSULTATION_LOADING](state, status) {
    state.consultationLoading = status
  }
};
export default {
  state,
  getters,
  actions,
  mutations,
};
