import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store'


Vue.use(Router);

const isAuthenticated = (to, from, next) => {
  if (store.state.auth.isAuthenticated) {
    next();
    return
  }
  next('/')
};

// const isNotAuthenticated = (to, from, next) => {
//     if (!store.state.auth.isAuthenticated) {
//         next();
//         return
//     }
//     next('/')
// };


const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '*',
      name: 'not-found',
      component: () => import('@/modules/server-not-found/index.vue'),
    },
    {
      path: '*',
      name: 'server-error',
      component: () => import('@/modules/server-error/index.vue'),
    },
    {
      path: '/:lang?/server-not-found',
      name: 'server-not-found',
      component: import('@/modules/system/server-not-found/index.vue'),
    },
    {
      path: '/:lang?',
      name: 'home',
      component: () => import('@/modules/home/index.vue'),
    },
    {
      path: '/:lang?/delivery',
      name: 'delivery',
      component: () => import('@/modules/delivery/index.vue'),
      meta: {
        breadcrumbs: [
          {
            bcDynamic: false,
            bcLabel: 'home',
            name: 'home',
          },
          {
            bcDynamic: true,
            bcLabel: 'delivery',
            name: 'delivery',
          },
        ]
      },
      children: [
        {
          path: 'ukraine',
          name: 'delivery-ua',
          component: () => import('@/modules/delivery/pages/ukraine/index.vue'),
          meta: {
            breadcrumbs: [
              {
                bcDynamic: false,
                bcLabel: 'home',
                name: 'home',
              },
              {
                bcDynamic: false,
                bcLabel: 'shippingPayment',
                name: 'shippingPayment'
              },
              {
                bcDynamic: false,
                bcLabel: 'inUkraine',
                name: 'inUkraine',
              },
            ]
          }
        },
        {
          path: '/:lang?/delivery/international',
          name: 'delivery-international',
          component: () => import('@/modules/delivery/pages/international/index.vue'),
          meta: {
            breadcrumbs: [
              {
                bcDynamic: false,
                bcLabel: 'home',
                name: 'home',
              },
              {
                bcDynamic: false,
                bcLabel: 'shippingPayment',
                name: 'shippingPayment',
              },
              {
                bcDynamic: false,
                bcLabel: 'international',
                name: 'international',
              },
            ]
          },
        },
      ]
    },
    {
      path: '/:lang?/media/:slug',

      name: 'media',
      component: () => import('@/modules/media-content/index.vue'),
      meta: {
        breadcrumbs: [
          {
            bcLabel: 'home',
            name: 'home',
          },
          // {
          //   bcLabel: 'about-us',
          //   name: 'aboutUs',
          // },
          {
            bcDynamic: false,
            bcLabel: 'media',
            name: 'media',
          },
        ]
      }
    },
    {
      path: '/:lang?/library',
      redirect: '/:lang?/library/faq',
      name: 'library',
      component: () => import('@/modules/library/index.vue'),
      meta: {
        breadcrumbs: [
          {
            bcDynamic: false,
            bcLabel: 'home',
            name: 'home',
          },
          {
            bcDynamic: false,
            bcLabel: 'library',
            name: 'library',
          },
        ]
      },
      children: [
        {
          path: 'faq',
          name: 'faq',
          meta: {
            breadcrumbs: [
              {
                bcDynamic: false,
                bcLabel: 'home',
                name: 'home',
              },
              {
                bcDynamic: false,
                bcLabel: 'library',
                name: 'library',
              },
              {
                bcDynamic: false,
                bcLabel: 'faq',
                name: 'faq',
              },
            ]
          },
          component: () => import('@/modules/library/pages/faq/index.vue')
        },
        {
          path: 'master-classes',
          name: 'master-classes',
          meta: {
            breadcrumbs: [
              {
                bcDynamic: false,
                bcLabel: 'home',
                name: 'home',
              },
              {
                bcDynamic: false,
                bcLabel: 'library',
                name: 'library',
              },
              {
                bcDynamic: false,
                bcLabel: 'masterClasses',
                name: 'master-classes',
              },
            ]
          },
          component: () => import('@/modules/library/pages/master-classes/index.vue')
        },
        {
          path: 'master-class/:slug',
          name: 'master-class',
          meta: {
            breadcrumbs: [
              {
                bcDynamic: false,
                bcLabel: 'home',
                name: 'home',
              },
              {
                bcDynamic: false,
                bcLabel: 'library',
                name: 'library',
              },
              {
                bcDynamic: false,
                bcLabel: 'masterClasses',
                name: 'master-classes',
              },
              {
                bcDynamic: true,
                bcModule: 'masterClasses',
                bcGetter: 'masterClassData',
                name: 'master-class',
              },
            ]
          },
          component: () => import('@/modules/library/pages/master-class/index.vue')
        },
        {
          path: 'ingredients',
          name: 'ingredients',
          meta: {
            breadcrumbs: [
              {
                bcDynamic: false,
                bcLabel: 'home',
                name: 'home',
              },
              {
                bcDynamic: false,
                bcLabel: 'library',
                name: 'library',
              },
              {
                bcDynamic: false,
                bcLabel: 'ingredients',
                name: 'ingredients',
              },
            ]
          },
          component: () => import('@/modules/library/pages/ingredients/index.vue')
        },
        {
          path: 'ingredient/:slug',
          name: 'ingredient',
          meta: {
            breadcrumbs: [
              {
                bcDynamic: false,
                bcLabel: 'home',
                name: 'home',
              },
              {
                bcDynamic: false,
                bcLabel: 'library',
                name: 'library',
              },
              {
                bcDynamic: false,
                bcLabel: 'ingredients',
                name: 'ingredients',
              },
              {
                bcDynamic: true,
                bcModule: 'ingredients',
                bcGetter: 'ingredientData',
                name: 'ingredient',
              },
            ]
          },
          component: () => import('@/modules/library/pages/ingredient/index.vue')
        },
        {
          path: 'blog',
          name: 'blog',
          meta: {
            breadcrumbs: [
              {
                bcDynamic: false,
                bcLabel: 'home',
                name: 'home',
              },
              {
                bcDynamic: false,
                bcLabel: 'library',
                name: 'library',
              },
              {
                bcDynamic: false,
                bcLabel: 'blog',
                name: 'blog',
              },
            ]
          },
          component: () => import('@/modules/library/pages/blog/index.vue')
        },
        {
          path: 'current-article/:id',
          name: 'current-article',
          meta: {
            breadcrumbs: [
              {
                bcDynamic: false,
                bcLabel: 'home',
                name: 'home',
              },
              {
                bcDynamic: false,
                bcLabel: 'library',
                name: 'library',
              },
              {
                bcDynamic: false,
                bcLabel: 'blog',
                name: 'blog',
              },
              {
                currentArticle: true,
              },
            ]
          },
          component: () => import('@/modules/library/pages/current-article/index.vue'),
        },
        {
          path: 'articles',
          name: 'articles',
          meta: {
            breadcrumbs: [
              {
                bcDynamic: false,
                bcLabel: 'home',
                name: 'home',
              },
              {
                bcDynamic: false,
                bcLabel: 'library',
                name: 'library',
              },
              {
                bcDynamic: false,
                bcLabel: 'articles',
                name: 'articles',
              },
            ]
          },
          component: () => import('@/modules/library/pages/articles/index.vue')
        },
        {
          path: 'article/:slug',
          name: 'article',
          meta: {
            breadcrumbs: [
              {
                bcDynamic: false,
                bcLabel: 'home',
                name: 'home',
              },
              {
                bcDynamic: false,
                bcLabel: 'library',
                name: 'library',
              },
              {
                bcDynamic: false,
                bcLabel: 'articles',
                name: 'articles',
              },
              {
                bcDynamic: true,
                bcModule: 'articles',
                bcGetter: 'articleData',
                name: 'ingredient',
              },
            ]
          },
          component: () => import('@/modules/library/pages/article/index.vue')
        },
        {
          path: 'videos',
          name: 'videos',
          meta: {
            breadcrumbs: [
              {
                bcDynamic: false,
                bcLabel: 'home',
                name: 'home',
              },
              {
                bcDynamic: false,
                bcLabel: 'library',
                name: 'library',
              },
              {
                bcDynamic: false,
                bcLabel: 'videos',
                name: 'videos',
              },
            ]
          },
          component: () => import('@/modules/library/pages/videos/index.vue')
        },
        {
          path: 'video/:slug',
          name: 'video',
          meta: {
            breadcrumbs: [
              {
                bcDynamic: false,
                bcLabel: 'home',
                name: 'home',
              },
              {
                bcDynamic: false,
                bcLabel: 'library',
                name: 'library',
              },
              {
                bcDynamic: false,
                bcLabel: 'videos',
                name: 'videos',
              },
              {
                bcDynamic: true,
                bcModule: 'videos',
                bcGetter: 'videoData',
                name: 'video',
              },
            ]
          },
          component: () => import('@/modules/library/pages/video/index.vue')
        },
        {
          path: 'photos',
          name: 'photos',
          meta: {
            breadcrumbs: [
              {
                bcDynamic: false,
                bcLabel: 'home',
                name: 'home',
              },
              {
                bcDynamic: false,
                bcLabel: 'library',
                name: 'library',
              },
              {
                bcDynamic: false,
                bcLabel: 'beforeAfterTitle',
                name: 'photos',
              },
            ]
          },
          component: () => import('@/modules/library/pages/photos/index.vue')
        },
      ]
    },
    {
      path: '/:lang?/discount-club',
      redirect: '/:lang?/discount-club/customer',
      name: 'discount-club',
      component: () => import('@/modules/discount-club/index.vue'),
      meta: {
        breadcrumbs: [
          {
            bcDynamic: false,
            bcLabel: 'home',
            name: 'home',
          },
          {
            bcDynamic: false,
            bcLabel: 'discount-club',
            name: 'discount-club',
          },
        ]
      },
      children: [
        {
          path: 'customer',
          name: 'customer',
          component: () => import('@/modules/discount-club/pages/customer/index.vue'),
          meta: {
            breadcrumbs: [
              {
                bcDynamic: false,
                bcLabel: 'home',
                name: 'home',
              },
              {
                bcDynamic: false,
                bcLabel: 'discount-club',
                name: 'discount-club',
              },
              {
                bcDynamic: false,
                bcLabel: 'customer',
                name: 'customer',
              }
            ]
          }
        }, {
          path: 'professional',
          name: 'professional',
          component: () => import('@/modules/discount-club/pages/professional/index.vue'),
          meta: {
            breadcrumbs: [
              {
                bcDynamic: false,
                bcLabel: 'home',
                name: 'home',
              },
              {
                bcDynamic: false,
                bcLabel: 'discount-club',
                name: 'discount-club',
              },
              {
                bcDynamic: false,
                bcLabel: 'professional',
                name: 'professional',
              }
            ]
          }
        }
      ]
    },
    {
      path: '/:lang?/about-us',
      name: 'about-us',
      component: () => import('@/modules/about-us/index.vue'),
      meta: {
        breadcrumbs: [
          {
            bcDynamic: false,
            bcLabel: 'home',
            name: 'home',
          },
          {
            bcDynamic: false,
            bcLabel: 'aboutUs',
            name: 'about-us',
          },
        ]
      },
    },
    {
      path: '/:lang?/catalog/:slug',
      name: 'catalog',
      component: () => import('./modules/catalog/index.vue')
    },
    {
      path: '/:lang?/catalog/search/:key',
      name: 'catalog-search',
      component: () => import('./modules/catalog/index.vue')
    },
    {
      path: '/:lang?/categories/:categorySlug',
      name: 'categories',
      component: () => import('@/modules/categories/index.vue'),
      meta: {
        breadcrumbs: [
          {
            bcDynamic: false,
            bcLabel: 'home',
            name: 'home',
          },
          {
            bcDynamic: false,
            bcLabel: 'catalogProduct',
            name: 'categories',
          },
          {
            bcDynamic: true,
            bcModule: 'categories',
            bcGetter: 'locallist',
            name: 'categories',
          }
        ]
      }
    },
    {
      path: '/:lang?/categories/:id/card/:item',
      // path: '/:lang?/card',
      name: 'card',
      meta: {
        breadcrumbs: [
          {
            bcDynamic: false,
            bcLabel: 'home',
            name: 'home',
          },
          {
            bcDynamic: false,
            bcLabel: 'catalogProduct',
            name: 'categories',
          },
          {
            bcDynamic: true,
            bcModule: 'categories',
            bcGetter: 'locallist',
            name: 'categories',
          },
          {
            bcDynamic: true,
            bcModule: 'card',
            bcGetter: 'product',
            name: 'card',
          }

        ]
      },
      component: () => import('@/modules/card/index.vue'),
    },
    {
      path: '/:lang?/partners',
      name: 'partners',
      component: () => import('@/modules/partners/index.vue'),
      meta: {
        breadcrumbs: [
          {
            bcDynamic: false,
            bcLabel: 'home',
            name: 'home',
          },
          {
            bcDynamic: false,
            bcLabel: 'partners',
            name: 'partners',
          }
        ]
      }
    },
    {
      path: '/:lang?/contacts',
      name: 'contacts',
      component: () => import('@/modules/contacts/index.vue'),
      meta: {
        breadcrumbs: [
          {
            bcDynamic: false,
            bcLabel: 'home',
            name: 'home',
          },
          {
            bcDynamic: false,
            bcLabel: 'contact',
            name: 'contacts',
          }
        ]
      }
    },
    {
      path: '/:lang?/user',
      name: 'user',
      beforeEnter: isAuthenticated,
      component: () => import('@/modules/profile/user/index.vue'),
      meta: {
        breadcrumbs: [
          {
            bcDynamic: false,
            bcLabel: 'home',
            name: 'home',
          },
          {
            bcDynamic: false,
            bcLabel: 'profile',
            name: 'profile',
          }
        ]
      },
      children: [
        {
          path: 'personal-data',
          name: 'personal-data',
          meta: {
            breadcrumbs: [
              {
                bcDynamic: false,
                bcLabel: 'home',
                name: 'home',
              },
              {
                bcDynamic: false,
                bcLabel: 'personal-data',
                name: 'personalData',
              }
            ]
          },
          component: () => import('@/modules/profile/user/pages/personal-data/index.vue')
        },
        {
          path: 'bonus',
          name: 'bonus',
          meta: {
            breadcrumbs: [
              {
                bcDynamic: false,
                bcLabel: 'home',
                name: 'home',
              },
              {
                bcDynamic: false,
                bcLabel: 'profile',
                name: 'profile',
              },
              {
                bcDynamic: false,
                bcLabel: 'bonus',
                name: 'bonus',
              }
            ]
          },
          component: () => import('@/modules/profile/user/pages/bonus/index.vue')
        },
        {
          path: 'favorites',
          name: 'favorites',
          meta: {
            breadcrumbs: [
              {
                bcDynamic: false,
                bcLabel: 'home',
                name: 'home',
              },
              {
                bcDynamic: false,
                bcLabel: 'profile',
                name: 'profile',
              },
              {
                bcDynamic: false,
                bcLabel: 'favorites',
                name: 'favorites',
              }
            ]
          },
          component: () => import('@/modules/profile/user/pages/favorites/index.vue')
        },
        {
          path: 'recommendations',
          name: 'recommendations',
          meta: {
            breadcrumbs: [
              {
                bcDynamic: false,
                bcLabel: 'home',
                name: 'home',
              },
              {
                bcDynamic: false,
                bcLabel: 'profile',
                name: 'profile',
              },
              {
                bcDynamic: false,
                bcLabel: 'recommendations',
                name: 'recommendations',
              }
            ]
          },
          component: () => import('@/modules/profile/user/pages/recommendations/index.vue')
        },
        {
          path: 'pricing',
          name: 'pricing',
          meta: {
            breadcrumbs: [
              {
                bcDynamic: false,
                bcLabel: 'home',
                name: 'home',
              },
              {
                bcDynamic: false,
                bcLabel: 'profile',
                name: 'profile',
              },
              {
                bcDynamic: false,
                bcLabel: 'pricing',
                name: 'pricing',
              }
            ]
          },
          component: () => import('@/modules/profile/user/pages/pricing/index.vue')
        },
        {
          path: 'mailing',
          name: 'mailing',
          meta: {
            breadcrumbs: [
              {
                bcDynamic: false,
                bcLabel: 'home',
                name: 'home',
              },
              {
                bcDynamic: false,
                bcLabel: 'profile',
                name: 'profile',
              },
              {
                bcDynamic: false,
                bcLabel: 'mailing',
                name: 'mailing',
              }
            ]
          },
          component: () => import('@/modules/profile/user/pages/mailing/index.vue')
        },
        {
          path: 'questionary',
          name: 'questionary',
          meta: {
            breadcrumbs: [
              {
                bcDynamic: false,
                bcLabel: 'home',
                name: 'home',
              },
              {
                bcDynamic: false,
                bcLabel: 'profile',
                name: 'profile',
              },
              {
                bcDynamic: false,
                bcLabel: 'questionary',
                name: 'questionary',
              }
            ]
          },
          component: () => import('@/modules/profile/user/pages/questionary/index.vue')
        },
        {
          path: 'current-orders',
          name: 'current-orders',
          meta: {
            breadcrumbs: [
              {
                bcDynamic: false,
                bcLabel: 'home',
                name: 'home',
              },
              {
                bcDynamic: false,
                bcLabel: 'profile',
                name: 'profile',
              },
              {
                bcDynamic: false,
                bcLabel: 'current-orders',
                name: 'currentOrders',
              }
            ]
          },
          component: () => import('@/modules/profile/user/pages/current-orders/index.vue')
        },
        {
          path: 'history-orders',
          name: 'history-orders',
          meta: {
            breadcrumbs: [
              {
                bcDynamic: false,
                bcLabel: 'home',
                name: 'home',
              },
              {
                bcDynamic: false,
                bcLabel: 'profile',
                name: 'profile',
              },
              {
                bcDynamic: false,
                bcLabel: 'history-orders',
                name: 'historyOrders',
              }
            ]
          },
          component: () => import('@/modules/profile/user/pages/current-orders/index.vue')
        },

      ]
    },
    {
      path: '/:lang?/professional',
      name: 'professional-profile',
      component: () => import('@/modules/profile/professional/index.vue'),
      meta: {
        breadcrumbs: [
          {
            bcDynamic: false,
            bcLabel: 'home',
            name: 'home',
          },
          {
            bcDynamic: false,
            bcLabel: 'profile',
            name: 'profile',
          }
        ]
      },
      children: [
        {
          path: 'data',
          name: 'professional-data',
          meta: {
            breadcrumbs: [
              {
                bcDynamic: false,
                bcLabel: 'home',
                name: 'home',
              },
              {
                bcDynamic: false,
                bcLabel: 'professional-data',
                name: 'professionalData',
              }
            ]
          },
          component: () => import('@/modules/profile/professional/pages/personal-data/index.vue')
        },
        {
          path: 'bonus',
          name: 'professional-bonus',
          meta: {
            breadcrumbs: [
              {
                bcDynamic: false,
                bcLabel: 'home',
                name: 'home',
              },
              {
                bcDynamic: false,
                bcLabel: 'profile',
                name: 'profile',
              },
              {
                bcDynamic: false,
                bcLabel: 'professional-bonus',
                name: 'professionalBonus',
              }
            ]
          },
          component: () => import('@/modules/profile/professional/pages/bonus/index.vue')
        },
        {
          path: 'recommendations',
          name: 'professional-recommendations',
          meta: {
            breadcrumbs: [
              {
                bcDynamic: false,
                bcLabel: 'home',
                name: 'home',
              },
              {
                bcDynamic: false,
                bcLabel: 'profile',
                name: 'profile',
              },
              {
                bcDynamic: false,
                bcLabel: 'professional-recommendations',
                name: 'professionalRecommendations',
              }
            ]
          },
          component: () => import('@/modules/profile/professional/pages/recommendations/index.vue')
        },
        {
          path: 'mailing',
          name: 'professional-mailing',
          meta: {
            breadcrumbs: [
              {
                bcDynamic: false,
                bcLabel: 'home',
                name: 'home',
              },
              {
                bcDynamic: false,
                bcLabel: 'profile',
                name: 'profile',
              },
              {
                bcDynamic: false,
                bcLabel: 'professional-mailing',
                name: 'professionalMailing',
              }
            ]
          },
          component: () => import('@/modules/profile/professional/pages/mailing/index.vue')
        },
        {
          path: 'orders',
          name: 'professional-orders',
          meta: {
            breadcrumbs: [
              {
                bcDynamic: false,
                bcLabel: 'home',
                name: 'home',
              },
              {
                bcDynamic: false,
                bcLabel: 'profile',
                name: 'profile',
              },
              {
                bcDynamic: false,
                bcLabel: 'professionalOrders',
                name: 'professionalOrders',
              }
            ]
          },
          component: () => import('@/modules/profile/professional/pages/current-orders/index.vue')
        },

      ]
    },
    {
      path: '/:lang?/professional-registration',
      name: 'professional-registration',
      component: () => import('@/modules/professional-registration/index.vue'),
      meta: {
        breadcrumbs: [
          {
            bcDynamic: false,
            bcLabel: 'home',
            name: 'home',
          },
          {
            bcDynamic: false,
            bcLabel: 'professional-registration',
            name: 'professional-registration',
          }
        ]
      }
    },
    {
      path: '/:lang?/buy',
      redirect: '/:lang?/buy/basket',
      name: 'buy-flow',
      component: () => import('@/modules/buy-flow/index.vue'),
      meta: {
        breadcrumbs: [
          {
            bcDynamic: false,
            bcLabel: 'home',
            name: 'home',
          },
          {
            bcDynamic: false,
            bcLabel: 'basket',
            name: 'basket',
          }
        ]
      },
      children: [
        {
          path: '/:lang?/buy/basket',
          name: 'basket',
          component: () => import('@/modules/buy-flow/pages/basket/index.vue'),
          meta: {
            breadcrumbs: [
              {
                bcDynamic: false,
                bcLabel: 'home',
                name: 'home',
              },
              {
                bcDynamic: false,
                bcLabel: 'basket',
                name: 'basket',
              }
            ]
          }
        },
        {
          path: 'order',
          name: 'order',
          meta: {
            title: 'Order',
            breadcrumbs: [
              {
                bcDynamic: false,
                bcLabel: 'home',
                name: 'home',
              },
              {
                bcDynamic: false,
                bcLabel: 'basket',
                name: 'basket',
              },
              {
                bcDynamic: false,
                bcLabel: 'order',
                name: 'order',
              },
            ]
          },
          component: () => import('./modules/buy-flow/pages/order/index.vue'),
        }

      ]
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    let el = document.querySelector('.app');
    if (to.path !== from.path) {
      if (savedPosition) {
        return savedPosition
      } else {
        el.scrollLeft = 0;
        el.scrollTop = 0;

        return {x: 0, y: 0}
      }
    }
  }
})


export default router;
