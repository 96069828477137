import {fixBody} from '@/helpers/variables/index';
import {$http} from "@/utils/http";
import {
    CHANGE_POPUP_USER_COSMETOLOGIST,
    SET_BONUSES,
    SET_DATE_BONUSES,
    SET_PROMO,
    SET_SAVE_TEST,
    SET_TEST, SHOW_CHANGE_PROMOCODE, SHOW_GENERATE_PROMO,
    SHOW_POPUP_ADD_PROMO, SHOW_POPUP_ERROR_PROMO,

} from '../mutation-types';
import {
    BIND_PROMO,
    DATE_BONUSES,
    DELETE_FILES,
    GENERATE_PROMO,
    GET_TEST,
    PAY_BONUSES,
    SAVE_TEST,
    UPLOAD_FILES,
    UPLOAD_IMG,
    DELETE_FILES_TEST
} from "../action-types";

const state = {
    changeCosmetologist: false,
    dateBonuses: '',
    test: '',
    generatedPromo: '',
    saveTest: '',
    showAddPromo: false,
    showerrorPromo: false,
    showChangePromo: false,
    generatePromo: false
};
//
const getters = {
    changeCosmetologist: state => state.changeCosmetologist,
    dateBonuses: state => state.dateBonuses,
    test: state => state.test,
    generatedPromo: state => state.generatedPromo,
    saveTest: state => state.saveTest,
    showAddPromo: state => state.showAddPromo,
    showerrorPromo: state => state.showerrorPromo,
    showChangePromo: state => state.showChangePromo,
    generatePromo: state => state.generatePromo
};
const actions = {

    [DELETE_FILES_TEST]: async ({commit}, id) => {

        try {
            const result = await $http.delete(`v1/test/drop_file/${id}`);

        } catch (e) {
            throw e;
        } finally {

        }
    },

    [UPLOAD_IMG]: async ({commit}, files) => {
        // commit(SET_FAST_BUY_LOADING, true);
        try {
            const response = await $http.post(`v1/test/upload_file`, files);

        } catch (e) {
            throw e;
        } finally {
            // commit(SET_FAST_BUY_LOADING, false);
        }
    },
    [BIND_PROMO]: async ({commit}, payload) => {
        // commit(SET_FAST_BUY_LOADING, true);
        try {
            const response = await $http.post(`v1/auth/me/bind_promo`, payload);

        } catch (e) {
            throw e;
        } finally {
            // commit(SET_FAST_BUY_LOADING, false);
        }
    },
    [SAVE_TEST]: async ({commit}, payload) => {
        // commit(SET_FAST_BUY_LOADING, true);
        try {
            const response = await $http.post(`v1/test/create`, payload);
            commit(SET_SAVE_TEST, response.data.data);
        } catch (e) {
            throw e;
        } finally {
            // commit(SET_FAST_BUY_LOADING, false);
        }
    },
    [GENERATE_PROMO]: async ({commit}, payload) => {
        // commit(SET_FAST_BUY_LOADING, true);
        try {
            const response = await $http.post(`v1/auth/me/generate_promo`, payload);
            commit(SET_PROMO, response.data.data);
        } catch (e) {
            throw e;
        } finally {
            // commit(SET_FAST_BUY_LOADING, false);
        }
    },
    [GET_TEST]: async ({commit}) => {
        // commit(SET_FAST_BUY_LOADING, true);
        try {
            const response = await $http.get(`v1/test`);
            commit(SET_TEST, response.data.data);
        } catch (e) {
            throw e;
        } finally {
            // commit(SET_FAST_BUY_LOADING, false);
        }
    },
    [DATE_BONUSES]: async ({commit}, payload) => {
        // commit(SET_FAST_BUY_LOADING, true);
        try {
            const response = await $http.post(`v1/auth/me/date_bonuses`, payload);
            commit(SET_DATE_BONUSES, response.data);
        } catch (e) {
            throw e;
        } finally {
            // commit(SET_FAST_BUY_LOADING, false);
        }
    },
};

const mutations = {
    [SET_PROMO](state, data) {
        state.generatedPromo = data
    },
    [SET_TEST](state, data) {
        state.test = data
    },
    [CHANGE_POPUP_USER_COSMETOLOGIST](state, params) {
        state.changeCosmetologist = params;
        fixBody(params);
    },
    [SET_DATE_BONUSES](state, data) {
        state.dateBonuses = data
    },
    [SET_SAVE_TEST](state, data) {
        state.saveTest = data
    },
    [SHOW_POPUP_ADD_PROMO](state, status) {
        state.showAddPromo = status
    },
    [SHOW_POPUP_ERROR_PROMO](state, status) {
        state.showerrorPromo = status
    },
    [SHOW_CHANGE_PROMOCODE](state, status) {
        state.showChangePromo = status
    },
    [SHOW_GENERATE_PROMO](state, status) {
        state.generatePromo = status
    }

};
export default {
    state,
    getters,
    actions,
    mutations,
};
