import {mapActions, mapGetters, mapMutations} from "vuex";


export default {
  name: 'change-cosmetologist',
  data() {
    return {
      check: false,
      clickButton: false,
      payload: {
        type: 1
      }
    }
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapActions({
      changeProfi: 'profile/CHANGE_PROFESSIONAL'
    }),
    ...mapMutations({
      changePopupUserCosmetologist: 'user/CHANGE_POPUP_USER_COSMETOLOGIST',
      changeShowPopupDeleteProfi: 'profile/CHANGE_SHOW_DELETE_PROFI'
    }),
    sendRequest() {
      this.clickButton = true
      if (this.check) {
        this.clickButton = false
        this.changeProfi(this.payload).then(() => {
          this.changePopupUserCosmetologist(false)
          this.changeShowPopupDeleteProfi(true)
          this.$toasted.success(this.$t('professionalSendSuccess'))
        })
      } else {
        this.clickButton = true
        this.$toasted.error(this.$t('codeCopySuccess'))
      }
    }

  },
}
