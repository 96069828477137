import {mapActions, mapGetters, mapMutations} from "vuex";
import {FETCH_USER_DATA, LINK_WITH_SOCIAL, SING_IN_WITH_SOCIAL} from '@/store/action-types';


export default {
    name: 'google',
    data() {
        return {
            // client_id is the only required property but you can add several more params, full list down bellow on the Auth api section
            googleSignInParams: {
                client_id: process.env.VUE_APP_GOOGLE_IDENTITY
            },
        }
    },
    props: ['activeItem', 'errorSocial'],
    components: {},
    created() {

    },
    methods: {
        ...mapActions({
            link: `auth/${SING_IN_WITH_SOCIAL}`,
            auth: `auth/${LINK_WITH_SOCIAL}`,
            fetchUser: `profile/${FETCH_USER_DATA}`,
            fetchFavouriteList: `favorites/GET_FAVORITES_LIST`,
            linkWithSocial: `auth/LINK_WITH_SOCIAL`,
            unlinkWithSocial: 'auth/UNLINK_WITH_SOCIAL'
        }),
        ...mapMutations({
            changePopupRegistration: 'card/CHANGE_POPUP_REGISTRATION',
        }),
        onSignInSuccess(googleUser) {
            const userToken = localStorage.getItem('user_token')
            const profile = googleUser.getAuthResponse('access_token').access_token;
            const socialProvider = localStorage.getItem('socialConnection')
            if (!userToken) {
                this.link({provider: 'google', token: profile})
                    .then(() => {
                        this.fetchUser()
                            .then(() => {
                                try {
                                    this.changePopupRegistration(false)
                                    this.$router.push({name: 'personal-data'})
                                        .catch(() => {
                                            console.log()
                                        });
                                    this.fetchFavouriteList();
                                } catch (e) {
                                    console.log(e);
                                }
                            });
                        this.$toasted.success(this.$t('successAuth'));
                    })
                    .catch(error => {
                        console.log(error);
                        if (error.status === 418) {
                            this.$toasted.error(error.data.message);
                        }
                    })
            }
            if (userToken) {
                this.linkWithSocial({provider: 'google', token: profile}).then(() => {
                    this.$toasted.success(this.$t('urrrraaaaaa'));
                }).catch((error) => {
                    this.$toasted.error(error.data.message);
                })
            }
            if (socialProvider) {
                if (socialProvider === 'facebook') {
                    this.errorSocial === true
                } else {
                    this.unlinkWithSocial(provider)
                }

            }


            // if (this.$route.name.includes('buy-address')) {
            //     this.auth({token: token, provider: 'google'})
            //         .then(() => {
            //             this.fetchUser().then(() => {
            //                 this.$toasted.success(this.$t('successAuth'));
            //             })
            //         })
            //         .catch(error => {
            //             if (error.status === 418) {
            //                 this.$toasted.error(error.data.message);
            //             }
            //         })
            // }
            // if (this.$route.name.includes('user-data')) {
            //     this.link({token: token, provider: 'google'})
            //         .then(() => {
            //             this.fetchUser().then(() => {
            //                 this.$toasted.success(this.$t('successLinkToAccount'));
            //                 this.fetchFavouriteList();
            //             })
            //         })
            //         .catch(error => {
            //             if (error.status === 418) {
            //                 this.$toasted.error(error.data.message);
            //             }
            //         })
            // }

        },
        onSignInError(error) {
            console.log(error);
        }
    }
}
