import {mapActions, mapGetters, mapMutations} from "vuex";
import {minLength, maxLength, required} from "vuelidate/lib/validators";

export default {
  name: 'consultation',
  data() {
    return {
      payload: {
        name: '',
        message: '',
        second_name: '',
        phone: '',
        product_id: '',
        count: 1
      },
      validationErrors: {},
    }
  },
  validations: {
    payload: {
      phone: {
        required,
        minLength: minLength(19)
      },
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(20)
      },
      second_name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(20)
      },
      message: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(200)
      },
    },
  },
  watch: {},
  computed: {
    ...mapGetters({
      popupConsultation: `header/popupConsultation`,
      sendingProgress: 'masterClasses/consultationLoading'
    }),
    nameErrors() {
      let error = [];
      if (!this.$v.payload.name.$dirty) {
        return error;
      }
      if (!this.$v.payload.name.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.name.minLength) {
        error.push(this.$t('validationMin') + ' 3');
      }
      if (!this.$v.payload.name.maxLength) {
        error.push(this.$t('validationMax') + ' 20');
      }
      if (this.validationErrors.name) {
        this.validationErrors.name.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    secondNameErrors() {
      let error = [];
      if (!this.$v.payload.second_name.$dirty) {
        return error;
      }
      if (!this.$v.payload.second_name.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.second_name.minLength) {
        error.push(this.$t('validationMin') + ' 3');
      }
      if (!this.$v.payload.second_name.maxLength) {
        error.push(this.$t('validationMax') + ' 20');
      }
      if (this.validationErrors.second_name) {
        this.validationErrors.second_name.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    messageErrors() {
      let error = [];
      if (!this.$v.payload.message.$dirty) {
        return error;
      }
      if (!this.$v.payload.message.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.message.minLength) {
        error.push(this.$t('validationMin') + ' 10');
      }
      if (!this.$v.payload.message.maxLength) {
        error.push(this.$t('validationMax') + ' 200');
      }
      if (this.validationErrors.message) {
        this.validationErrors.message.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    phoneErrors() {
      let error = [];
      if (!this.$v.payload.phone.$dirty) {
        return error;
      }
      if (!this.$v.payload.phone.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.phone.minLength) {
        error.push(this.$t('validationMin') + ' 12');
      }
      if (this.validationErrors.phone) {
        this.validationErrors.phone.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
  },
  methods: {
    ...mapActions({
      sendForm: 'masterClasses/SIGN_CONSULTATION'
    }),
    ...mapMutations({
      changePopupConsultation: `header/CHANGE_POPUP_CONSULTATION`,
    }),
    submit(){

    },
    submitData() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let obj = {};
        obj.name = this.payload.name;
        obj.phone = this.payload.phone.replace(/[\s.,%()-]/g, '');
        obj.message = this.payload.message;
        obj.second_name = this.payload.second_name;
        this.sendForm(obj)
          .then(() => {
            this.$toasted.success(this.$t('successConsultation'));
            this.changePopupConsultation(false);
          }).catch(error => {
          if (error.response.status === 418) {
            this.$toasted.error(error.response.data.data.error.message);
          }
          if (error.response.status === 422) {
            this.$toasted.error(error.response.data.message);
          }
        });
      }
    }
  },
  created() {

  },
  destroyed() {

  }
}
