import {mapActions, mapGetters, mapMutations} from "vuex";
import {minLength, maxLength, required} from "vuelidate/lib/validators";


export default {
  name: 'fast-buy',
  data() {
    return {
      payload: {
        name: '',
        phone: '',
        product_id: '',
        count: 1
      },
      validationErrors: {},
    }
  },
  validations: {
    payload: {
      phone: {
        required,
        minLength: minLength(19)
      },
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(20)
      },
    },
  },
  watch: {},
  computed: {
    ...mapGetters({
      popupFastBuy: `card/popupFastBuy`,
      fastBuyLoading: `home/fastBuyRequest`,
      selectedProduct: 'home/fastBuyProduct',
      isAuthenticated: `auth/isAuthenticated`,
      certificateResponse: 'order/certificateStatus'
    }),

    nameErrors() {
      let error = [];
      if (!this.$v.payload.name.$dirty) {
        return error;
      }
      if (!this.$v.payload.name.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.name.minLength) {
        error.push(this.$t('validationMin') + '3');
      }
      if (!this.$v.payload.name.maxLength) {
        error.push(this.$t('validationMax') + 20);
      }
      if (this.validationErrors.name) {
        this.validationErrors.name.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    phoneErrors() {
      let error = [];
      if (!this.$v.payload.phone.$dirty) {
        return error;
      }
      if (!this.$v.payload.phone.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.phone.minLength) {
        error.push(this.$t('validationMin') + '12');
      }
      if (this.validationErrors.phone) {
        this.validationErrors.phone.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
  },
  methods: {
    ...mapActions({
      fastBuy: `basket/FAST_BUY_ORDER`,
    }),
    ...mapMutations({
      changeFastBuy: `card/CHANGE_POPUP_FAST_BUY`,
      showPopup: 'order/CHANGE_SHOW_THANKS_POPUP'
    }),
    test() {

    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        console.log(this.selectedProduct);
        let obj = {}
        let products = []

        if (this.certificateResponse) {
          obj.certificate_id = this.certificateResponse.data.id
        }

        products.push({
          id: this.selectedProduct.id,
          count: this.selectedProduct.select_count,
          weight_id: this.selectedProduct.weights[0].id
        })
        obj.count = 1
        obj.fast_buy = true
        obj.weight_id= this.selectedProduct.weights[0].id
        obj.products = products
        obj.first_name = this.payload.name
        obj.phone = this.payload.phone.replace(/[\s.,%()-]/g, '');
        this.fastBuy(obj)
          .then(() => {
            this.$toasted.success(this.$t('successCreateFastBuy'));
            this.changeFastBuy(false);
            this.showPopup(true)
          }).catch(error => {
          if (error.response.status === 418) {
            this.$toasted.error(error.response.data.data.error.message);
          }
          if (error.response.status === 422) {
            this.$toasted.error(error.response.data.message);
          }
        });
      }
    }
  },
  created() {

  },
  destroyed() {

  }
}
