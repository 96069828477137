export default {
  methods: {
    navigateTo(params){
      try {
        this.$router.push({ name: params }).catch(() => {console.log()});
      }catch (e) {
        console.log();
      }
    },
  }
}