import Vue from 'vue';
import Vuelidate from 'vuelidate'

Vue.use(Vuelidate);
import {validationMixin} from 'vuelidate';
import {required, sameAs, minLength, maxLength} from 'vuelidate/lib/validators';

import {mapActions, mapMutations} from "vuex";

import {
  SET_NEW_PASSWORD
} from "@/store/action-types";

import {
  SHOW_POPUP_TEXT_CONTENT,
  POPUP_TEXT_CONTENT,
  CHANGE_POPUP_NEW_PASSWORD
} from '@/store/mutation-types'

export default {
  name: 'set-new-password',
  mixin: [validationMixin],
  data() {
    return {
      passwordType:'password',
      payload: {
        password: '',
        password_confirmation:'',
        token: this.$route.query.token,
        email: this.$route.query.email
      },
      validationErrors: {}
    }
  },
  validations: {
    payload: {
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(30),
      },
      password_confirmation: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(20),
        sameAsPassword: sameAs('password')
      },
    }
  },
  watch: {

  },
  computed: {
    passwordErrors() {
      let error = [];
      if (!this.$v.payload.password.$dirty) {
        return error;
      }
      if (!this.$v.payload.password.maxLength) {
        error.push(this.$t('validationMax') + ' 30')
      }
      if (!this.$v.payload.password.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.password.minLength) {

        error.push(this.$t('validationMin') + ' 8')
      }
      if (this.validationErrors.password) {
        this.validationErrors.password.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    passwordConfirmationErrors() {
      let error = [];
      if (!this.$v.payload.password_confirmation.$dirty) {
        return error;
      }
      if (!this.$v.payload.password_confirmation.maxLength) {
        error.push(this.$t('validationMax') + '30');
      }
      if (!this.$v.payload.password_confirmation.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.password_confirmation.sameAsPassword) {
        error.push(this.$t('validationSameAs'));
      }
      if (!this.$v.payload.password_confirmation.minLength) {
        error.push(this.$t('validationMin') + '8');
      }
      if (this.validationErrors.password_confirmation) {
        this.validationErrors.password_confirmation.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
  },
  methods: {
    ...mapActions({
      send: `auth/${SET_NEW_PASSWORD}`
    }),
    ...mapMutations({
      showPopup: `system/${SHOW_POPUP_TEXT_CONTENT}`,
      changePopupNewPassword: `auth/${CHANGE_POPUP_NEW_PASSWORD}`,
      changeContentPopup: `system/POPUP_TEXT_CONTENT`,
      changePopupSetNewPassword:'auth/CHANGE_POPUP_SET_NEW_PASSWORD',
    }),
    setPasswordType() {
      this.passwordType === 'password' ? this.passwordType = 'text' : this.passwordType = 'password';
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.send(this.payload)
          .then(() => {
            this.changePopupNewPassword(false);
            this.changeContentPopup({
              title: this.$t('setPassword'),
              text: this.$t('passwordSuccessUpdate'),
            });
            this.$toasted.success(this.$t('successChangePassword'))
            this.changePopupSetNewPassword(false);
          })
          .catch(error => {
            if (error.response.status === 422) {
              for (let field in error.response.data.errors) {
                this.validationErrors[field] = error.response.data.errors[field];
              }
            }
          });
      }
    }
  },
  destroyed() {

  }
}
