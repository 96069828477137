import Vue from 'vue';
import VueTheMask from 'vue-the-mask';
import Toasted from 'vue-toasted';
import VueLazyload from 'vue-lazyload'
import Vuelidate from 'vuelidate';
import VueFlatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import vueSmoothScroll from 'vue2-smooth-scroll'
import VueHead from 'vue-head'
// import * as Tabs from 'vue-slim-tabs'
import 'moment/locale/uk'
import 'moment/locale/ru'
import LazyTube from "vue-lazytube";



//use
Vue.use(Toasted,{duration:5000});
Vue.use(VueTheMask);
Vue.use(VueLazyload, {
    preLoad: 1.3,
    attempt: 1
})
Vue.use(Vuelidate);
Vue.use(VueFlatPickr);
Vue.use(vueSmoothScroll);
Vue.use(VueHead);
Vue.use(LazyTube);
// Vue.use(Tabs);

