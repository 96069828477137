import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: 'main-footer',
  data() {
    return {
      viber: '',
      email: '',
      location: '',
      phone: '',
      mobileList: {
        contacts: false,
        about: false,
        library: false,
        delivery: false,
      },
      showPhonesList: false,
    }
  },
  computed: {
    ...mapGetters({
      staticLinks: `about/staticLinks`,
      variables: 'setting/variables',
    }),
  },
  created() {
    this.fetchVariables().then(() => {
      let email = this.variables.find(el => el.key === 'email')
      let viber = this.variables.find(el => el.key === 'viber')
      let location = this.variables.find(el => el.key === 'address')
      let phone = this.variables.find(el => el.key === 'phone')

      this.phone = phone.value
      this.location = location.value
      this.viber = viber.value
      this.email = email.value
    })

  },
  methods: {
    activeLink() {
      this.isActive = true
    },
    ...mapMutations({}),
    ...mapActions({
      fetchVariables: 'setting/GET_VARIABLES'
    })
  }
}
