export const GET_VARIABLES = 'GET_VARIABLES';
export const SUBSCRIBE = 'SUBSCRIBE';

//auth
export const GET_TOKEN = 'GET_TOKEN';
export const GET_TOKEN_PHONE = 'GET_TOKEN_PHONE';
export const LOGOUT = 'LOGOUT';
export const REGISTRATION = 'REGISTRATION';
export const SING_IN_WITH_GOOGLE = 'SING_IN_WITH_GOOGLE';
export const SING_IN_WITH_FACEBOOK = 'SING_IN_WITH_FACEBOOK';
export const SEND_CODE_PHONE = 'SEND_CODE_PHONE';
export const RESTORE_PASSWORD = 'RESTORE_PASSWORD';
export const SET_NEW_PASSWORD = 'SET_NEW_PASSWORD';
export const LINK_WITH_SOCIAL = 'LINK_WITH_SOCIAL';
export const UNLINK_WITH_SOCIAL = 'UNLINK_WITH_SOCIAL';
export const UPLOAD_FILES = 'UPLOAD_FILES';
export const DELETE_FILES = 'DELETE_FILES'
export const GET_RECOMMENDATIONS = 'GET_RECOMMENDATIONS'
export const GET_CURRENT_RECOMMENDATION = 'GET_CURRENT_RECOMMENDATION'
export const GET_PRICES = 'GET_PRICES'
export const GET_REFERRALS = 'GET_REFERRALS'
export const GET_STATE = 'GET_STATE'
export const GET_PARTNERS='GET_PARTNERS'


//setting
export const GET_TRANSLATIONS = 'GET_TRANSLATIONS';
export const GET_CATEGORIES_MENU = 'GET_CATEGORIES_MENU';
export const GET_CATEGORY_FILTERS = 'GET_CATEGORY_FILTERS';
export const GET_CURRENCY = 'GET_CURRENCY'

//support
export const GET_SUPPORT_LIST = 'GET_SUPPORT_LIST';

//search
export const GET_SEARCH_LIST = 'GET_SEARCH_LIST';
export const GET_HEADER_SEARCH_TOOLTIP = 'GET_HEADER_SEARCH_TOOLTIP';


//contacts
export const SEND_FEEDBACK = 'SEND_FEEDBACK';
export const GET_CONTACTS = 'GET_CONTACTS';
export const GET_ADDRESS_PARTNER = 'GET_ADDRESS_PARTNER'

//---faq
export const FAQ_LIST_GET = 'FAQ_LIST_GET';

//---blog
export const BLOG_DATA_GET = 'BLOG_DATA_GET';

//---videos
export const VIDEOS_LIST_GET = 'VIDEOS_LIST_GET';
export const VIDEOS_DATA_GET = 'VIDEOS_DATA_GET';

//---master-classes
export const MASTERCLASS_LIST_GET = 'MASTERCLASS_LIST_GET';
export const MASTERCLASS_DATA_GET = 'MASTERCLASS_DATA_GET';
export const SIGN_CONSULTATION = 'SIGN_CONSULTATION'

//---articles
export const ARTICLES_LIST_GET = 'ARTICLES_LIST_GET';
export const ARTICLES_DATA_GET = 'ARTICLES_DATA_GET';
export const SEND_ARTICLE_COMMENT = 'SEND_ARTICLE_COMMENT'

//---before-after
export const BEFORE_AFTER_DATA_GET = 'BEFORE_AFTER_DATA_GET';

//---ingredient
export const INGREDIENTS_SEARCH_GET = 'INGREDIENTS_SEARCH_GET';
export const INGREDIENT_DATA_GET = 'INGREDIENT_DATA_GET';

//---about-us
export const ABOUT_MEDIA_GET = 'ABOUT_MEDIA_GET';
export const ABOUT_STATIC_GET = 'ABOUT_STATIC_GET';
export const GET_ABOUT_MEDIA='GET_ABOUT_MEDIA'

//home
export const GET_HOME_SLIDER = 'GET_HOME_SLIDER';
export const GET_PRODUCTS_LIST = 'GET_PRODUCTS_LIST';
export const GET_BANNER_LIST = 'GET_BANNER_LIST';
export const GET_CLIENTS_SLIDER = 'GET_CLIENTS_SLIDER';
export const GET_PRODUCTS_ITEM = 'GET_PRODUCTS_ITEM';
export const GET_MOBILE_PRODUCTS_LIST = 'GET_MOBILE_PRODUCTS_LIST';
export const GET_CERTIFICATE_LIST = 'GET_CERTIFICATE_LIST'
export const GET_CATEGORIES='GET_CATEGORIES'


//basket
export const GET_RECOMMENDED_LIST = 'GET_RECOMMENDED_LIST';
export const CREATE_ORDER = 'CREATE_ORDER';
export const GET_BASKET_LIST = 'GET_BASKET_LIST';
export const ADD_PRODUCT_TO_BASKET = 'ADD_PRODUCT_TO_BASKET';
export const REMOVE_PRODUCT_FROM_BASKET = 'REMOVE_PRODUCT_FROM_BASKET';
export const SEND_BASKET = 'SEND_BASKET';
export const CHECK_DISCOUNT = 'CHECK_DISCOUNT'
export const CHECK_BONUSES = 'CHECK_BONUSES'
export const PAY_BONUSES = 'PAY_BONUSES'
export const FAST_BUY_ORDER = 'FAST_BUY_ORDER'
export const UPDATE_BASKET='UPDATE_BASKET'

//category
export const GET_FILTER_PRODUCTS_LIST = 'GET_FILTER_PRODUCTS_LIST';
export const GET_ATTRIBUTES_PRODUCTS_LIST = 'GET_ATTRIBUTES_PRODUCTS_LIST';
export const GET_ALL_PRODUCTS_LIST = 'GET_ALL_PRODUCTS_LIST';
export const GET_ALL_PRODUCTS_LIST_WITH_ATTRIBUTES = 'GET_ALL_PRODUCTS_LIST_WITH_ATTRIBUTES';
export const GET_BREADCRUMBS = 'GET_BREADCRUMBS';
export const GET_FILTERS = 'GET_FILTERS';


//card
export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_PROMO_LIST = 'GET_PROMO_LIST';
export const GET_NEW_COLLECTION_LIST = 'GET_NEW_COLLECTION_LIST';
export const GET_PRODUCT_REVIEWS = 'GET_PRODUCT_REVIEWS';
export const SEND_REVIEW = 'SEND_REVIEW';
export const FAST_BUY = 'FAST_BUY';
export const ADD_CARD_PRODUCT_TO_BASKET = 'ADD_CARD_PRODUCT_TO_BASKET';
export const SEND_QUESTION = 'SEND_QUESTION';

//setting
export const FETCH_USER_DATA = 'FETCH_USER_DATA';

//profile
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD';
export const GET_ACTIVE_ORDER_LIST = 'GET_ACTIVE_ORDER_LIST';
export const GET_ARCHIVE_ORDER_LIST = 'GET_ARCHIVE_ORDER_LIST';
export const GET_FAVOURITE_LIST = 'GET_FAVOURITE_LIST';
export const ADD_USER_ADDRESS = 'ADD_USER_ADDRESS';
export const REMOVE_USER_ADDRESS = 'REMOVE_USER_ADDRESS';
export const EDIT_USER_ADDRESS = 'EDIT_USER_ADDRESS';
export const ADD_USER_ICON = 'ADD_USER_ICON';
export const LINK_WITH_GOOGLE = 'LINK_WITH_GOOGLE';
export const LINK_WITH_FACEBOOK = 'LINK_WITH_FACEBOOK';
export const UNLINK_WITH_GOOGLE = 'UNLINK_WITH_GOOGLE';
export const UNLINK_WITH_FACEBOOK = 'UNLINK_WITH_FACEBOOK';
export const CHANGE_PROFESSIONAL = 'CHANGE_PROFESSIONAL'


//favorites
export const GET_FAVORITES_LIST = 'GET_FAVORITES_LIST';
export const ADD_PRODUCT_TO_FAVORITE = 'ADD_PRODUCT_TO_FAVORITE';
export const REMOVE_PRODUCT_IN_FAVORITE = 'REMOVE_PRODUCT_IN_FAVORITE';

// payments-methods
export const GET_PAYMENT_METHODS_LIST = 'GET_PAYMENT_METHODS_LIST';

// delivery-methods
export const GET_DELIVERY_METHODS_LIST = 'GET_DELIVERY_METHODS_LIST';

// order
export const NOVA_POSHTA_GET_DEPARTMENS = 'NOVA_POSHTA_GET_DEPARTMENS';
export const NOVA_POSHTA_GET_TOWNS = 'NOVA_POSHTA_GET_TOWNS';
export const CREATE_INVOICE = 'CREATE_INVOICE';
export const CREATE_TRANSACTION = 'CREATE_TRANSACTION';
export const GET_CURRENT_ORDERS = 'GET_CURRENT_ORDERS';
export const REPEAT_ORDER = 'REPEAT_ORDER';
export const SING_IN_WITH_SOCIAL = 'SING_IN_WITH_SOCIAL';
export const REGISTRATION_PROFESSIONAL = 'REGISTRATION_PROFESSIONAL';
export const GET_CERTIFICATE_CHECK = 'GET_CERTIFICATE_CHECK'
export const GET_DELIVERY_TYPES = 'GET_DELIVERY_TYPES'
export const GET_UKRPOCHTA_AREA = 'GET_UKRPOCHTA_AREA'


// user
export const DATE_BONUSES = 'DATE_BONUSES'
export const GET_TEST = 'GET_TEST'
export const GENERATE_PROMO = 'GENERATE_PROMO'
export const SAVE_TEST = 'SAVE_TEST'
export const BIND_PROMO = 'BIND_PROMO'
export const UPLOAD_IMG = 'UPLOAD_IMG'
export const DELETE_FILES_TEST = 'DELETE_FILES_TEST'
