<template>
  <div class="global">
    <svg width="478" height="348" viewBox="0 0 478 348" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path id="caisson-cables" d="M461.233 0.626953H418.815L402.217 24.604L356.11 26.4484L332.135 67.0249L311.848 37.5147L120.045 35.6703L29.6761 186.91L75.7827 256.997L27.8319 258.841L0.167969 303.107L29.6761 347.372L85.004 345.527L107.135 308.64L127.422 339.994H282.34H319.225L407.75 192.443L409.594 188.755L359.799 114.979L409.594 113.135L435.414 68.8693L431.725 63.3361H457.545L477.832 31.9815L461.233 0.626953ZM136.643 327.084L96.0695 268.063L62.8728 214.576L132.955 102.068H276.807L346.889 214.576L276.807 327.084H136.643ZM359.799 214.576L282.34 89.1576H127.422L55.4958 205.354L42.586 186.91L125.578 48.581H304.471L398.528 188.755L315.537 327.084H291.561L359.799 214.576ZM35.2089 334.461L13.0778 303.107L33.3647 269.908H75.7827L97.9138 301.262L77.6269 334.461H35.2089ZM422.504 61.4918L426.192 68.8693L405.905 102.068H363.487L341.356 70.7137L361.643 37.5147H400.373L422.504 61.4918ZM413.282 30.1372L424.348 11.6933H453.856L468.61 30.1372L455.7 48.581H428.036L413.282 30.1372Z" fill="#045238"/>
      <path id="caisson-cables" d="M215.94 197.975C215.94 196.131 215.94 196.131 215.94 194.287V192.442C215.94 192.442 215.94 192.442 215.94 190.598V186.909C214.096 183.22 212.252 179.531 210.407 175.843C203.03 166.621 193.809 159.243 182.744 155.554C175.366 153.71 169.834 153.71 164.301 153.71H162.457C160.612 153.71 156.924 153.71 155.08 153.71H153.235H151.391H114.506V159.243C114.506 181.376 114.506 205.353 114.506 229.33V253.307H120.039C125.571 253.307 132.948 253.307 138.481 253.307C144.014 253.307 149.547 253.307 155.08 253.307C156.924 253.307 158.768 253.307 162.457 253.307H167.989C169.834 253.307 171.678 253.307 173.522 253.307C182.744 251.463 190.121 247.774 195.653 244.085C204.875 238.552 210.407 231.174 214.096 221.952L215.94 218.264C215.94 218.264 215.94 218.264 215.94 216.419V214.575C215.94 212.73 215.94 211.739 215.94 206.754C215.94 201.768 215.94 199.82 215.94 197.975ZM156.924 231.174C155.08 231.174 151.391 231.174 149.547 231.174H145.858C145.858 210.886 145.858 190.598 145.858 170.309C145.858 170.309 145.858 170.309 147.703 170.309H149.547C151.391 170.309 155.08 170.309 156.924 170.309H158.768C162.457 170.309 166.145 172.154 167.989 173.998C171.678 175.843 173.522 177.687 173.522 177.687C175.366 179.531 177.211 181.376 179.055 185.065C180.899 186.909 180.899 190.598 182.744 194.287V196.131C182.744 197.975 182.744 199.82 182.744 201.664V203.508V205.353C182.744 207.197 182.744 207.197 182.744 209.042V210.886C182.744 210.886 182.249 214.93 180.218 217.57C176.871 221.922 173.522 223.846 165.49 228.365L162.457 229.62C158.21 231.671 158.768 231.174 156.924 231.174Z" fill="#045238"/>
      <path id="caisson-cables" d="M304.469 229.33C304.469 229.33 302.625 229.33 302.625 231.174C302.625 231.174 302.625 231.174 300.781 231.174C297.092 233.019 289.715 233.019 282.338 233.019H273.117C271.272 233.019 267.584 233.019 265.74 233.019C265.74 221.952 265.74 210.886 265.74 199.82C265.74 186.909 265.74 173.998 265.74 159.243V153.71H247.297C243.609 153.71 241.764 153.71 239.92 153.71H238.076L234.387 155.554L232.543 159.243V253.307H239.92C250.986 253.307 262.051 253.307 273.117 253.307C284.182 253.307 295.248 253.307 306.313 253.307H311.846L313.69 247.774C313.69 245.929 313.69 245.929 313.69 244.085C313.69 242.241 313.69 240.396 313.69 238.552L315.535 229.33H304.469Z" fill="#045238"/>
    </svg>

  </div>
</template>
<style lang="scss">
.global {
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;

  svg {
    height: 200px;
    width: 100%;
    //height: 100%;
    //position: absolute;
    //left: 0;
    //right: 0;
    //bottom: 0;
    margin: auto;
  }

  .word {
    max-width: 400px;
    top: 200px;
  }

  #logo-remplissage {
    fill: none;
    stroke: #045238;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke-dasharray: 1630;
    stroke-dashoffset: 1000;
    animation: draw 2s;
    animation-delay: .2s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-timing-function: linear;
  }

  #caisson-cables {
    fill: none;
    stroke: #045238;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: draw 3s;
    animation-delay: .2s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-timing-function: linear;
  }


  @keyframes draw {
    to {
      stroke-dashoffset: 0;
      opacity: 1;
    }
  }

  @keyframes show {
    to {
      fill-opacity: 1;
      opacity: 1;
      stroke-opacity: 1;
    }
  }
}

@media all and (max-width: 500px) {
  .loader {
    svg {
      height: 100px;
    }

    .word {
      max-width: 300px;
      top: 100px;
    }
  }
  #logo-remplissage {
    stroke-width: 1;
  }
}

</style>
<script>
export default {
  name: 'globalLoader',
}
</script>

