import navigateMixin from '../../../../mixins/buy-flow.js'
import {mapMutations} from "vuex";

export default {
  name: "discount-one-time",
  mixins: [navigateMixin],
  methods:{
    ...mapMutations({
      changePopupDiscountOneTime: 'basket/CHANGE_DISCOUNT_ONE_TIME'
    })
  }

}
