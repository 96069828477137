<template>
  <div id="app" class="app">
    <transition name="component-fade" mode="out-in">
      <GlobalLoader v-if="loading"/>
      <slot v-else></slot>
    </transition>
    <div id="bg_wrapper">
    </div>
    <div class="app-container">
      <div class="wrapper" id="blur">
        <div class="wrapper-top">
          <main-header/>

          <div class="content-wrap">
            <!--            <breadcrumbs v-if="isShowBreadcrumbs" />-->
            <transition name="component-fade" mode="out-in">
              <router-view/>
            </transition>
          </div>
        </div>
        <div class="wrapper-bottom">
          <main-footer/>
        </div>
      </div>
    </div>
    <hidden/>
    <!--    <div class="global-loader">-->
    <!--      <loader></loader>-->
    <!--    </div>-->

  </div>
</template>

<style src="@/assets/scss/main.scss" lang="scss"></style>


<script>

import {mapGetters, mapActions, mapMutations, mapState} from 'vuex';
import MainHeader from '@/components/header/index.vue';
import MainFooter from '@/components/footer/index.vue';
import Hidden from '@/components/hidden/index.vue';
import Breadcrumbs from '@/components/breadcrumbs/index.vue';
import {isMobile} from '@/helpers/variables/index'

import VueMeta from 'vue-meta'
import Vue from 'vue'
import axios from 'axios';

Vue.use(VueMeta)
export default {
  name: 'App',
  data() {
    return {
      showBreadcrumbs: false,
      breadcrumbsExceptPages: ['home', 'card', 'categories', 'server-not-found', 'not-found'],
      isLoading: false
    }
  },

  components: {
    MainHeader,
    MainFooter,
    Hidden,
    Breadcrumbs,
  },
  metaInfo() {
    return {
      meta: [
        {
          n: 'description',
          c: 'DeLav косметика. Космецевтические продукты, созданные специально для полного спектра ухода за чувствительной кожей.',
          id: 'description'
        },
        {n: 'keywords', c: 'Delav', id: 'keywords'},
        {
          n: 'og:description',
          c: 'DeLav косметика. Космецевтические продукты, созданные специально для полного спектра ухода за чувствительной кожей.',
          id: 'og:description'
        },
      ]
    }
  },
  computed: {
    ...mapState('loader', ['loading']),
    ...mapGetters({
      globalLoader: 'system/globalLoader',
      isAuthenticated: `auth/isAuthenticated`,
      staticList: 'about/staticList',
      menu: 'settings/menu',


      homeSlider: 'home/homeSlider',
      homeSliderLoading: 'home/homeSliderLoading',
      bannerList: 'home/bannerList',
      bannerListLoading: 'home/bannerListLoading',
      listProduct: 'home/list',
      listProductLength: 'home/listLength',
      listProductLoading: 'home/listLoading',
      activeProductCategory: 'home/activeCategory',
      wishList: 'favorites/whichList'
    }),
    isShowBreadcrumbs() {
      return this.breadcrumbsExceptPages.indexOf(this.$route.name) < 0
    }
  },
  created() {
    this.fetchVariables()
    this.fetchMenu();
    this.fetchAboutLinks();
    if (this.isAuthenticated) {
      this.fetchUser()
      this.fetchFavoriteList()
    }
    if (!localStorage.getItem('products')) {
      localStorage.setItem('products', '[]');
    }

    //axios loader
    // axios.interceptors.request.use(
    //     config => {
    //       if (config.showLoader) {
    //         store.dispatch('loader/pending');
    //       }
    //       return config;
    //     },
    //     error => {
    //       if (error.config.showLoader) {
    //         store.dispatch('loader/done');
    //       }
    //       return Promise.reject(error);
    //     }
    // );
    // axios.interceptors.response.use(
    //     response => {
    //       if (response.config.showLoader) {
    //         store.dispatch('loader/done');
    //       }
    //
    //       return response;
    //     },
    //     error => {
    //       let response = error.response;
    //
    //       if (response.config.showLoader) {
    //         store.dispatch('loader/done');
    //       }
    //
    //       return Promise.reject(error);
    //     }
    // )
  },
  mounted() {
    window.addEventListener('load', () => {
      this.isLoading = true
    })
    if (!isMobile.any()) {
      document.querySelector('body').classList.add('hover');
    }
  },
  // watch:{
  //   '$route.name'() {
  //     alert('da')
  //      this.checkRoute()
  //   },
  // },
  methods: {
    ...mapMutations({}),
    ...mapActions({
      fetchAboutLinks: `about/ABOUT_STATIC_GET`,
      fetchMenu: `setting/GET_CATEGORIES_MENU`,
      fetchUser: `profile/FETCH_USER_DATA`,
      fetchFavoriteList: `favorites/GET_FAVORITES_LIST`,
      fetchVariables: 'setting/GET_VARIABLES'
    }),
    checkRoute() {
      switch (this.$route.name) {
        case 'home':
          this.showBreadcrumbs = !this.showBreadcrumbs
          break;
        case 'categories':
          this.showBreadcrumbs = !this.showBreadcrumbs
          break;
        case 'card':
          this.showBreadcrumbs = !this.showBreadcrumbs
          break;
      }
      return this.showBreadcrumbs
    },
  },
  destroyed() {
  }
}
</script>

