import {mapActions, mapGetters, mapMutations} from "vuex";


export default {
    name: 'generate-promo',
    props: ['name'],
    data() {
        return {}
    },
    computed: {
        ...mapGetters({
            user: `profile/user`,
        }),
    },
    methods: {
        ...mapActions({
            fetchUser: `profile/FETCH_USER_DATA`

        }),
        ...mapMutations({
            changeShowGenerate: 'user/SHOW_GENERATE_PROMO'
        }),
        redirectToUser() {
            this.$router.push('bonus')
            this.changeShowGenerate(false)
        }

    },
    created() {
        this.fetchUser()
    }
}
