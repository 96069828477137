import {mapActions, mapGetters, mapMutations} from "vuex";

import VFacebookLogin from "vue-facebook-login-component";

export default {
    name: 'facebook-widget',
    data() {
        return {
            facebook: {
                FB: {},
                model: {},
                appId: process.env.VUE_APP_FACEBOOK_IDENTITY,
            },
            updateRender: true,
        }
    },
    props: ['activeItem', 'errorSocial'],
    components: {
        VFacebookLogin
    },
    computed: {
        ...mapGetters({
            popupRegister: 'auth/popupRegister',
            signInLoading: 'auth/singInFacebookLoading'
        })
    },
    methods: {
        ...mapActions({
            auth: `auth/SING_IN_WITH_SOCIAL`,
            link: `profile/LINK_WITH_FACEBOOK`,
            fetchUser: `profile/FETCH_USER_DATA`,
            fetchFavouriteList: `favorites/GET_FAVORITES_LIST`,
            linkWithSocial: `auth/LINK_WITH_SOCIAL`,
            unlinkWithSocial: `auth/UNLINK_WITH_SOCIAL`
        }),
        ...mapMutations({
            changePopupRegistration: 'card/CHANGE_POPUP_REGISTRATION',
        }),
        getUserData() {
            const socialProvider = localStorage.getItem('socialConnection')
            const userToken = localStorage.getItem('user_token')
            const {api} = this.facebook.FB;
            const provider = 'facebook'
            api('/me', {fields: 'id, name, email, first_name, last_name'}, (response) => {
                const token = this.facebook.FB.getAccessToken();
                if (this.$route.name.includes('info')) {
                    this.link({token: token})
                        .then(() => {
                            this.fetchUser().then(() => {
                                this.$toasted.success(this.$t('successLinkToAccount'));
                                this.handleLogout();
                                this.fetchFavouriteList();
                            });

                        })
                        .catch(error => {
                            if (error.response.status === 418) {
                                this.$toasted.error(error.response.data.data.error.message);
                            }
                            this.handleLogout();
                            this.changePopupRegistration(false);
                        })
                } else {
                    if (!userToken) {
                        this.auth({token: token, provider: provider})
                            .then(() => {
                                this.$toasted.success(this.$t('successAuth'));
                                this.changePopupRegistration(false);
                                this.fetchUser().then(() => {
                                    this.$router.push({name: 'profile'}).catch(() => {
                                        console.log()
                                    });
                                    this.fetchFavouriteList();
                                });
                                this.handleLogout();
                            })
                            .catch(error => {
                                if (error.response.status === 418) {
                                    this.$toasted.error(error.response.data.data.error.message);
                                }
                                this.handleLogout();
                                this.changePopupRegistration(false);
                            })
                    }
                    if (userToken) {
                        this.linkWithSocial({token: token, provider: provider})
                            .then(() => {
                                localStorage.setItem('socialConnection', provider)
                                this.$toasted.success(this.$t('successAuth'));
                            })
                            .catch(error => {
                                if (error.response.status === 418) {
                                    this.$toasted.error(error.response.data.data.error.message);
                                }
                            })
                    }
                    if (socialProvider) {
                        if (socialProvider === 'google') {
                            this.errorSocial === true
                        } else {
                            this.unlinkWithSocial(provider)
                        }

                    }

                }
            })
        },
        handleSdkInit({FB}) {
            FB.init({
                xfbml: true,
                version: 'v2.8',
                appId: process.env.VUE_APP_FACEBOOK_IDENTITY,
                status: false,
                cookie: false,
            });
            this.facebook.FB = FB;
        },
        mounted() {
            this.handleLogout();
        },
        handleConnect() {

        },
        handleLogout() {
            this.facebook.FB.getLoginStatus(response => {
                if (response.status === 'connected') {
                    this.facebook.FB.logout(resp => {
                        this.updateRender = false;
                        setTimeout(() => {
                            this.updateRender = true;
                        }, 10)
                    });
                }
            })
        },
        login() {
            this.getUserData();
        },
        handleClick() {
            // eslint-disable-next-line
        },
    },
    destroyed() {
        this.handleLogout();
    }
}
