//utils
import './utils/plugins'
import './utils/translations'
import './utils/global-components'
import axios from 'axios';

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'vue-search-select/dist/VueSearchSelect.css'

axios.defaults.showLoader = true;
Vue.config.productionTip = false

// set auth status
if (localStorage.getItem('user_token')) {
  store.state.auth.isAuthenticated = true
}
if (localStorage.getItem('professional')) {
  store.state.auth.isProfessional = true
}

const app = new Vue({
  router,
  store,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
});

Vue.initI18nManager().then(() => {
  app.$mount('#app')
});

import GSignInButton from 'vue-google-signin-button'
Vue.use(GSignInButton)

const eventHub = new Vue()
Vue.mixin({
  data: function () {
    return {
      eventHub: eventHub
    }
  }
})
