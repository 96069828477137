// import pages
import Vue from 'vue';
import Loader from '@/components/misc/loader.vue';
import PopupWrap from '@/components/misc/popup-wrap.vue';
import smallLoader from '@/components/misc/small-loader.vue';
import VueSmoothScroll from 'vue2-smooth-scroll'
import mainButton from '@/components/buttons/main-button/index.vue';
import secondaryButton from '@/components/buttons/secondary-button/index.vue';
import 'leaflet/dist/leaflet.css';
import {Icon} from 'leaflet';
import VCalendar from 'v-calendar';
import zoomOnHover from '@/components/misc/vue-zoom-on-hover/index.vue' ;
import GlobalLoader from '@/components/misc/globalLoader.vue'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import currency from "@/mixins/currency.js";
Vue.mixin(currency);
Vue.component('zoom-on-hover', zoomOnHover);


Vue.config.productionTip = false;

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

import VueExpandableImage from 'vue-expandable-image'

Vue.use(VueExpandableImage)

import DatePicker from 'vue2-datepicker';
var Paginate = require('vuejs-paginate')
Vue.component('paginate', Paginate)
import 'vue2-datepicker/index.css';

Vue.use(DatePicker)
// use global pages
Vue.component('main-button', mainButton);
Vue.component('secondary-button', secondaryButton);
Vue.use(VueSmoothScroll, {
    duration: 400,
    updateHistory: false,
});
Vue.use(CoolLightBox)
Vue.component('loader', Loader);
Vue.component('GlobalLoader', GlobalLoader);
Vue.component('small-loader', smallLoader);
Vue.component('popup-wrap', PopupWrap);
Vue.component('v-pagination', window['vue-plain-pagination']);


Vue.use(VCalendar, {
    componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
});
