import {$http} from '@/utils/http'
import {objectToEncodedQueryString} from '@/helpers/variables/index';

import {
  BLOG_DATA,
  BLOG_DATA_LOADING,
} from '../mutation-types';

import {
  BLOG_DATA_GET,
} from '../action-types';


const state = {
  list: [],
  meta: null,
  isLoading: false,
  isDone: false,
  error: null,
  limit: 6,
};

const getters = {
  blogList: state => state.list,
  blogLimit: state => state.limit,
  blogMeta: state => state.meta,
  isLoadingBlog: state => state.isLoading,
};

const actions = {
  [BLOG_DATA_GET]: async ({commit}, params) => {

    commit(BLOG_DATA_LOADING, true);
    try {
      const query = `limit=${state.limit}&${objectToEncodedQueryString(params)}`;
      const response = await $http.get(`v1/library/blog?${query}`);
      commit(BLOG_DATA, response.data);
    } catch (e) {
      throw e;
    } finally {
      commit(BLOG_DATA_LOADING, false);
    }
  },
};

const mutations = {
  [BLOG_DATA](state, data) {
    state.list = data.data ? data.data : [];
    state.meta = data.meta ? data.meta : null;
  },
  [BLOG_DATA_LOADING](state, status) {
    state.isLoading = status;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
