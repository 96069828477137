import {mapActions, mapGetters, mapMutations} from "vuex";
import store from '@/store';
import headerSearch from '@/components/header-search/index.vue'
import cosmeticConsultationButton from '@/components/buttons/cosmetic-consultation-button/index.vue'
import _ from "lodash";

export default {
  name: 'main-header',
  data() {
    return {
      phone: '',
      email: '',
      viber: '',
      telegram: '',
      instagram: '',
      facebook: '',
      logo: '',
      description_site: '',
      querySearch: '',
      activeSearch: false,
      show: false,
      mobileToggler: false,
      catalogToggler: false,
      mobileList: {
        about: false,
        library: false,
        delivery: false,
        discount: false,
        contacts: false,
      },
      catalogList: {
        package: false,
        type: false,
        care: false,
        old: false,
      },
      active: false,
      lang: '',
      authUser: false,
      authProf: false,
      basketItems: {},
      // selectedCurrency: {},
      currentCurrency: '',
      currency: [],
    }
  },
  directives: {},
  components: {
    headerSearch,
    cosmeticConsultationButton
  },
  computed: {
    ...mapGetters({
      user: `profile/user`,
      isAuthenticated: `auth/isAuthenticated`,
      countBasketItems: 'basket/countBasketItems',
      userFakeAuth: 'header/userFakeAuth',
      profFakeAuth: 'headequerySearchr/profFakeAuth',
      menuList: 'setting/menu',
      basket: 'basket/basket',
      staticLinks: `about/staticLinks`,
      showPopupRegistration: 'card/popupRegistration',
      showPopupConsultation: 'header/popupConsultation',
      certificates: 'header/certificates',
      searchList: 'search/searchList',
      searchLoading: 'search/searchLoading',
      variables: 'setting/variables',
      currencyList: 'setting/currencyList',
      selectedCurrency: 'setting/selectCurrency',
      checkedCurrency: 'setting/checkedCurrency',
      updatedBasket: 'basket/updatedBasket'
    }),
    ...mapGetters([
      'languages',
      'currentLanguage',

    ]),
  },
  watch: {
    '$route'() {
      this.clearSearch()
    }
  },

  created() {
    this.getCurrencies().then(() => {
      console.log(this.menuList,'menuList');
      this.currencyList.forEach(el => {
        if (el.code === "UAH") {
          el.title = '₴';
        }
        if (el.code === "USD") {
          el.title = '$';
        }
        if (el.code === "EUR") {
          el.title = '€';
        }
        this.currency.push(el)
      })
      this.currency.forEach(el => {
        if (el.code === JSON.parse(this.checkedCurrency)) {
          this.currentCurrency = el.title
        }
      })
    })
    this.fetchVariables().then(() => {
      let logo = this.variables.find(el => el.key === 'logo')
      let description = this.variables.find(el => el.key === 'description_site')
      let email = this.variables.find(el => el.key === 'email')
      let viber = this.variables.find(el => el.key === 'viber')
      let telegram = this.variables.find(el => el.key === 'telegram')
      let instagram = this.variables.find(el => el.key === 'instagram')
      let facebook = this.variables.find(el => el.key === 'facebook')
      let phone = this.variables.find(el => el.key === 'phone')
      let favicon = this.variables.find(el => el.key === 'favicon')

      this.phone = phone.value
      this.facebook = facebook.value
      this.instagram = instagram.value
      this.telegram = telegram.value
      this.viber = viber.value
      this.email = email.value
      this.logo = logo.value
      this.description_site = description.value

      const icon = document.getElementById("favicon");
      icon.href = favicon
    })
    this.setCurrentLang()
    this.setCurrency()
    this.checkUserFakeAuth()
    this.checkProfFakeAuth()
    this.updateBasket()
  },
  mounted() {

  },
  methods: {
    ...mapActions({
      fetchUpdateBasket: 'basket/UPDATE_BASKET',
      fetchSearchItems: 'search/GET_SEARCH_LIST',
      fetchUserData: 'profile/FETCH_USER_DATA',
      fetchCertificateList: 'home/GET_CERTIFICATE_LIST',
      fetchVariables: 'setting/GET_VARIABLES',
      getCurrencies: 'setting/GET_CURRENCY'
    }),
    ...mapMutations({
      changePopupRegistration: 'card/CHANGE_POPUP_REGISTRATION',
      changePopupConsultation: 'header/CHANGE_POPUP_CONSULTATION',
      saveSelectCurrency: 'setting/SET_CURRENT_CURRENCY'
    }),
    updateBasket() {
      let products = []
      let basketProducts = JSON.parse(localStorage.getItem('products'))
      console.log(basketProducts,'basketProducts');
      basketProducts.forEach(el => {
        products.push({'weight_id': el.select_volume.id})
      })
      this.fetchUpdateBasket({products: products})
    },
    updateCurrency(currency) {
      this.saveSelectCurrency(currency)
      window.location.reload()

    },
    openMenu() {
      this.mobileToggler = !this.mobileToggler
      if (this.mobileToggler) {
        document.getElementById('html').classList.add('hide')
      } else {
        document.getElementById('html').classList.remove('hide')
      }
    },
    isAuth() {
      if (this.isAuthenticated) {
        this.fetchUserData()
        this.$router.push({name: 'personal-data'}).then(() => {


        }).catch(() => {
          console.log()
        })
      } else {
        this.changePopupRegistration(true)
      }
    },

    sendRequest: _.debounce(function () {
      if (this.querySearch.length > 2) {
        this.fetchSearchItems({key: this.querySearch})
      }
    }, 500),
    changeSearch() {
      this.sendRequest()
      // this.querySearch = ''

    },
    setFocusField(status) {
      this.activeSearch = !this.activeSearch
      this.show = status;
      this.clearSearch()
    },

    clearSearch() {
      return this.querySearch = ''
    },
    changeFavorite() {
      if (this.isAuthenticated) {
        // this.$router.push('/user/favorites/')
        this.$router.push({path: '/user/favorites/'});
      } else {
        this.changePopupRegistration(true)
      }
    },

    checkUserFakeAuth() {
      if (this.userFakeAuth.length && this.userFakeAuth[0] === 'fakeAuthCode') {
        this.authUser = true
      } else {
        this.authUser = false
      }
    },

    checkProfFakeAuth() {
      if (this.userFakeAuth.length && this.profFakeAuth[0] === 'profFakeAuth') {
        this.authProf = true
      } else {
        this.authProf = false
      }
    },
    changeLanguage(code) {
      this.$setLanguage(code).then(data => {
        console.log('data', data)
        window.location.reload();
      });
    },
    mouseOver() {
      this.active = !this.active;
    },
    changeCurrency(key) {
      setTimeout(() => {
        // window.location.reload();

        this.currentCurrency = this.getTitle(key)

      }, 800)
    },
    changeHeader() {
      if (this.$route.name === 'home') {
        window.scrollTo({top: 0, behavior: 'smooth'});
      } else {
        this.$router.push({name: 'home'}).catch(() => {
          console.log();
        });
      }
    },
    getTitle(key) {
      for (let i in this.currency) {
        if (this.currency.hasOwnProperty(i)) {
          if (this.currency[i].key === key)
            return this.currency[i]
        }
      }
    },

    setCurrentLang() {
      let code = store.getters.currentLanguage.code
      if (code) {
        this.lang = code.substr(code.length - 2).toUpperCase();
      } else {
        this.lang = 'UA'
      }
    },
    //TODO : Add currency from store or localstore
    setCurrency() {
      this.currentCurrency = this.currency[2]

    },
  },
  destroyed() {
  }
}
