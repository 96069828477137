import {camelCase} from 'lodash'
export default {
  name: 'breadcrumb',
  props: {
    route: {},
  },
  beforeCreate() {
    this.$options.computed.value = function () {
      return this.route.bcGetter ? this.$store.getters[`${this.route.bcModule}/${this.route.bcGetter}`] : null
    }
  },
  created(){
  },
  computed: {
    formattedValue() {
      return this.value ? this.value.title : null
    },
  },
  methods:{
    toCamelCase(val){
      return camelCase(val)
    }
  }
}
