import {mapGetters} from "vuex";

export default {
  name: 'item-basket',
  props:['item'],
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters({
      showPopupAddBasket: 'basket/showPopupAddBasket',
    }),
  },
}
