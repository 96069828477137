<template>
  <div>
    <div class="card-text__sell-discount-main">
      <span>-15%</span>
    </div>
  <div class="zoom-on-hover"
       v-bind:class="{zoomed}"
       @touchstart="touchzoom"
       @mousemove="move"
       @mouseenter="zoom"
       @mouseleave="unzoom">

    <gallery
            :images="testImg" :index="index" @close="imgClose()"></gallery>

    <div
         :key="index"
         @click="imgClick()" >
      <img class="normal" ref="normal" :src="imgNormal"/>
      <img class="zoom" ref="zoom" :src="imgZoom || imgNormal"/>
    </div>

  </div>
  </div>

</template>
<script>
  import VueGallery from 'vue-gallery';

  function pageOffset(el) {
    // -> {x: number, y: number}
    // get the left and top offset of a dom block element
    var rect = el.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return {
      y: rect.top + scrollTop,
      x: rect.left + scrollLeft
    }
  }

  export default {
    name: 'zoomOnHover',
    props: ["imgNormal", "imgZoom", "scale", "disabled",'activeImg','testImg'],
    components: {
      'gallery': VueGallery,
    },
    data: function() {
      return {
        scaleFactor: 1.5,
        resizeCheckInterval: null,
        zoomed: false,
        index: null,
      }
    },
    methods: {
      imgClick() {
        this.index = this.activeImg.id
        this.disabled = true
        this.zoomed = false
      },
      imgClose(){
        this.index = null
        this.disabled = !this.disabled
        this.zoomed = ! this.zoomed
        this.resizeCheckInterval=null
        this.scaleFactor = this.$props.scale
        this.$refs.zoom.style.transform = "scale(" + this.scaleFactor + ")"
        this.initEventLoaded()
        this.initEventResized()
      },
      touchzoom: function(event) {
        if (this.disabled) return
        this.move(event)
        this.zoomed = !this.zoomed
      },
      zoom: function() {
        if (!this.disabled) this.zoomed = true
      },
      unzoom: function() {
        if (!this.disabled) this.zoomed = false
      },
      move: function(event) {
        if (this.disabled || !this.zoomed) return
        var offset = pageOffset(this.$el)
        var zoom = this.$refs.zoom
        var normal = this.$refs.normal
        var relativeX = event.clientX - offset.x + window.pageXOffset
        var relativeY = event.clientY - offset.y + window.pageYOffset
        var normalFactorX = relativeX / normal.offsetWidth
        var normalFactorY = relativeY / normal.offsetHeight
        var x = normalFactorX * (zoom.offsetWidth * this.scaleFactor - normal.offsetWidth)
        var y = normalFactorY * (zoom.offsetHeight * this.scaleFactor - normal.offsetHeight)
        zoom.style.left = -x + "px"
        zoom.style.top = -y + "px"
      },
      initEventLoaded: function() {
        // emit the "loaded" event if all images have been loaded
        var promises = [this.$refs.zoom, this.$refs.normal].map(function(image) {
          return new Promise(function(resolve, reject) {
            image.addEventListener("load", resolve)
            image.addEventListener("error", reject)
          })
        })
        var component = this
        Promise.all(promises).then(function() {
          component.$emit("loaded")
        })
      },
      initEventResized: function() {
        var normal = this.$refs.normal
        var previousWidth = normal.offsetWidth
        var previousHeight = normal.offsetHeight
        var component = this
        this.resizeCheckInterval = setInterval(function() {
          if ((previousWidth != normal.offsetWidth) || (previousHeight != normal.offsetHeight)) {
            previousWidth = normal.offsetWidth
            previousHeight = normal.offsetHeight
            component.$emit("resized", {
              width: normal.width,
              height: normal.height,
              fullWidth: normal.naturalWidth,
              fullHeight: normal.naturalHeight
            })
          }
        }, 1000)
      }
    },
    mounted: function() {
      if (this.$props.scale) {
        this.scaleFactor = this.$props.scale
        this.$refs.zoom.style.transform = "scale(" + this.scaleFactor + ")"
      }
      this.initEventLoaded()
      this.initEventResized()
    },
    updated: function() {
      this.initEventLoaded()
    },
    beforeDestroy: function() {
      this.resizeCheckInterval && clearInterval(this.resizeCheckInterval)
    }

  }
</script>
<style src="./index.css" ></style>
