import {$http} from '@/utils/http'

import {
  FAQ_LIST,
  FAQ_LIST_LOADING,
} from '../mutation-types';

import {
  FAQ_LIST_GET,
} from '../action-types';


const state = {
  list: [],
  isLoading: false,
};

const getters = {
  list: state => state.list,
  isLoading: state => state.isLoading,
};

const actions = {
  [FAQ_LIST_GET]: async ({commit}) => {
    commit(FAQ_LIST_LOADING, true);
    try {
      const response = await $http.get(`v1/library/faq`);
      commit(FAQ_LIST, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(FAQ_LIST_LOADING, false);
    }
  },
};

const mutations = {
  [FAQ_LIST](state, list) {
    state.list = list;
  },
  [FAQ_LIST_LOADING](state, status) {
    state.isLoading = status;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
