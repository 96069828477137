import {mapActions, mapGetters, mapMutations} from "vuex";
import {minLength, maxLength, required, email} from "vuelidate/lib/validators";


export default {
  name: 'article-comment',
  props: ['slug'],
  data() {
    return {
      payload: {
        name: '',
        email: '',
        message: ''
      },
      validationErrors: {},
    }
  },
  validations: {
    payload: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(20)
      },
      email: {
        required,
        maxLength: maxLength(60),
        email,
      },
      message: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(300),
      },
    },
  },
  watch: {},
  computed: {
    ...mapGetters({
      popupComment: `card/popupComment`,
      isAuth: 'auth/isAuthenticated',
      articleId: 'articles/articleId'
    }),
    emailErrors() {
      let error = [];
      if (!this.$v.payload.email.$dirty) {
        return error;
      }
      if (!this.$v.payload.email.maxLength) {
        error.push(this.$t('validationMax') + '50');
      }
      if (!this.$v.payload.email.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.email.email) {
        error.push(this.$t('validationEmail'))
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    nameErrors() {
      let error = [];
      if (!this.$v.payload.name.$dirty) {
        return error;
      }
      if (!this.$v.payload.name.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.name.minLength) {
        error.push(this.$t('validationMin') + '3');
      }
      if (!this.$v.payload.name.maxLength) {
        error.push(this.$t('validationMax') + '20');
      }
      if (this.validationErrors.name) {
        this.validationErrors.name.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    messageErrors() {
      let error = [];
      if (!this.$v.payload.message.$dirty) {
        return error;
      }
      if (!this.$v.payload.message.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.message.minLength) {
        error.push(this.$t('validationMin') + '12');
      }
      if (!this.$v.payload.message.maxLength) {
        error.push(this.$t('validationMax') + '300');
      }
      if (this.validationErrors.message) {
        this.validationErrors.message.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
  },
  methods: {
    ...mapActions({
      sendComment: `articles/SEND_ARTICLE_COMMENT`,
    }),
    ...mapMutations({
      changePopupComment: `card/changePopupComment`,
      showArticleCommentPopup: 'articles/SHOW_ADD_COMMENT_POPUP'
    }),
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let obj = {};
        obj.name = this.payload.name;
        obj.email = this.payload.email;
        obj.text = this.payload.message;
        obj.article_id = this.articleId
        this.sendComment(obj)
          .then(() => {
            this.$toasted.success(this.$t('successCreateReview'));
            this.showArticleCommentPopup(false)
          }).catch(error => {
          if (error.response.status === 418) {
            this.$toasted.error(error.response.data.data.error.message);
            console.log(error)
          }
          if (error.response.status === 422) {
            this.$toasted.error(error.response.data.message);
            console.log(error)
          }
        }).finally(() => {
          // this.showArticleCommentPopup({status: false})
        });
      }
    }
  },
  created() {

  },
  destroyed() {

  }
}
