import store from '@/store'
import {
    SHOW_GLOBAL_LOADER,
    FIX_BODY,
} from "@/store/mutation-types";

export function globalLoader(state){
    store.commit(`system/${SHOW_GLOBAL_LOADER}`, state)
}
export function fixBody(state){
    store.commit(`system/${FIX_BODY}`, state)
}
export const isMobile = {
    Android: function () {
        return navigator.userAgent.match(/Android/i) ? true : false;
    },
    BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i) ? true : false;
    },
    iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i) ? true : false;
    },
    Windows: function () {
        return navigator.userAgent.match(/IEMobile/i) ? true : false;
    },
    any: function () {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Windows());
    }
};
export const isIPad = {
    iOS: function () {
        return navigator.userAgent.match(/iPad|iPod/i) ? true : false;
    }
};

export function getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function objectIsEmpty(obj) {
    return Object.keys(obj).length === 0;
}

export const objectToQueryString = (params) =>
  Object.keys(params).map(key => key + '=' + params[key]).join('&');

export const objectToEncodedQueryString = (params) =>
  Object.keys(params)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');

export const arrayToEncodedQueryString = (array, arrayName) =>
  array.map((item,index) => `${arrayName}[${index}]=${item}`)
    .join('&');


// //---eslint-disable-next-line no-useless-escape
// export const emailRegex = helpers.regex('email',/^[a-zA-Z0-9\._+\-]+@[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,}$/);
// //---eslint-disable-next-line no-useless-escape
// export const currencyRegex = helpers.regex('currency',/^(0|[1-9]\d{0,})(\.\d{1,2})?$/);
