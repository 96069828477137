import {fixBody, globalLoader} from '@/helpers/variables/index';
import axios from 'axios'
import {$http} from '@/utils/http';

import {
  CREATE_ORDER_LOADING,
  SET_PRODUCTS_LIST,
  SET_CURRENT_ORDERS,
  SET_REPEAT_ORDER,
  NOVA_POSHTA_GET_TOWNS_LOADING,
  NOVA_POSHTA_SET_TOWNS,
  NOVA_POSHTA_SET_DEPARTMENS,
  NOVA_POSHTA_GET_DEPARTMENS_LOADING,
  CHANGE_ADDRESS, CERTIFICATE_CHECK_LOADING, SET_CERTIFICATE_CHECK, CHANGE_SHOW_THANKS_POPUP, SET_DELIVERY_TYPES
} from '../mutation-types';

import {
  CREATE_ORDER,
  GET_CURRENT_ORDERS,
  REPEAT_ORDER,
  NOVA_POSHTA_GET_DEPARTMENS,
  NOVA_POSHTA_GET_TOWNS,
  GET_CERTIFICATE_CHECK, GET_DELIVERY_TYPES, GET_UKRPOCHTA_AREA
} from '../action-types';

const state = {
  orderLoading: false,
  currentOrders: null,
  repeatOrder: null,
  //------------------
  address: {
    town: 'Киев',
  },
  towns: [],
  departments: [],
  isDepartmensLoading: false,
  isTownsLoading: false,
  deliveryNovaPoshtaData: {},
  isCertificateLoading: '',
  certificateStatus: 0,
  showThanksPopup: false,
  deliveryTypes: ''
}

const getters = {
  deliveryTypes: state => state.deliveryTypes,
  showThanksPopup: state => state.showThanksPopup,
  currentOrders: state => state.currentOrders,
  repeatOrder: state => state.repeatOrder,
  //------------
  deliveryNovaPoshtaData: state => state.deliveryNovaPoshtaData,
  towns: state => state.towns,
  departments: state => state.departments,
  address: state => state.address,
  isDepartmensLoading: state => state.isDepartmensLoading,
  isTownsLoading: state => state.isTownsLoading,
  isCertificateLoading: state => state.isCertificateLoading,
  certificateStatus: state => state.certificateStatus
  //------------
}

const actions = {
  [GET_CERTIFICATE_CHECK]: async ({commit}, payload) => {
    try {
      commit(CERTIFICATE_CHECK_LOADING, true);

      const response = await $http.post(`v1/order/certificate/check`, payload);
      commit(SET_CERTIFICATE_CHECK, response.data);

    } catch (e) {
      commit(SET_CERTIFICATE_CHECK, false);
      event.preventDefault()
      throw e;
    } finally {
      commit(CERTIFICATE_CHECK_LOADING, false);
    }
  },


  [GET_UKRPOCHTA_AREA]: async ({commit}, payload) => {
    // commit(NOVA_POSHTA_GET_TOWNS_LOADING, true);
    try {
      const response = await axios({
        method: 'GET',
        url: `https://ukrposhta.ua/address-classifier-ws/get_regions_by_region_ua?region_name=${payload.area}`,
        data: payload
      })
      // commit(NOVA_POSHTA_SET_TOWNS, response.data.data)
      // commit(NOVA_POSHTA_SET_DEPARTMENS, [])
    } catch (e) {
      throw e;
    } finally {
      // commit(NOVA_POSHTA_GET_TOWNS_LOADING, false);
    }
  },
  [NOVA_POSHTA_GET_TOWNS]: async ({commit}, payload) => {
    commit(NOVA_POSHTA_GET_TOWNS_LOADING, true);
    try {
      const response = await axios({
        method: 'POST',
        url: "https://api.novaposhta.ua/v2.0/json/",
        data: payload
      })
      commit(NOVA_POSHTA_SET_TOWNS, response.data.data)
      commit(NOVA_POSHTA_SET_DEPARTMENS, [])
    } catch (e) {
      throw e;
    } finally {
      commit(NOVA_POSHTA_GET_TOWNS_LOADING, false);
    }
  },
  [NOVA_POSHTA_GET_DEPARTMENS]: async ({commit}, payload) => {
    commit(NOVA_POSHTA_GET_DEPARTMENS_LOADING, true);
    try {
      const response = await axios({
        method: 'POST',
        url: "https://api.novaposhta.ua/v2.0/json/",
        data: payload
      })
      commit(NOVA_POSHTA_SET_DEPARTMENS, response.data.data)
    } catch (e) {
      throw e;
    } finally {
      commit(NOVA_POSHTA_GET_DEPARTMENS_LOADING, false);
    }
  },
  [CREATE_ORDER]: async ({commit}, payload) => {
    try {

      commit(CREATE_ORDER_LOADING, true);
      return await $http.post(`v1/order/create`, payload).then(() => {

        //reset certificate after order
        commit(SET_CERTIFICATE_CHECK, false);
      })


    } catch (e) {
      throw e;

    } finally {
      commit(CREATE_ORDER_LOADING, false);
    }
  },
  [GET_CURRENT_ORDERS]: async ({commit}, payload) => {
    try {
      // commit(CREATE_ORDER_LOADING, true);
      const response = await $http.get(`v1/order/history?status=${payload ? payload : ''}`);
      commit(SET_CURRENT_ORDERS, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      // commit(CREATE_ORDER_LOADING, false);
    }
  },
  [GET_DELIVERY_TYPES]: async ({commit}, payload) => {
    try {
      // commit(CREATE_ORDER this.page++_LOADING, true);
      const response = await $http.get(`v1/order/delivery_types`);
      commit(SET_DELIVERY_TYPES, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      // commit(CREATE_ORDER_LOADING, false);
    }
  },
  [REPEAT_ORDER]: async ({commit}, payload) => {
    try {
      // commit(CREATE_ORDER_LOADING, true);
      const response = await $http.get(`v1/order/${payload}/repeat`);
      commit(SET_REPEAT_ORDER, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      // commit(CREATE_ORDER_LOADING, false);
    }
  },
}

const mutations = {
  [CREATE_ORDER_LOADING](state, status) {
    state.orderLoading = status
  },
  [SET_CURRENT_ORDERS](state, obj) {
    state.currentOrders = obj
  },
  [SET_REPEAT_ORDER](state, obj) {
    state.repeatOrder = obj
  },
  [NOVA_POSHTA_SET_DEPARTMENS](state, data) {
    state.departments = data
  },
  [CHANGE_ADDRESS](state, data) {
    Object.assign(state.address, data)
  },
  [NOVA_POSHTA_GET_TOWNS_LOADING](state, status) {
    state.isTownsLoading = status
  },
  [NOVA_POSHTA_GET_DEPARTMENS_LOADING](state, status) {
    state.isDepartmensLoading = status
  },
  [NOVA_POSHTA_SET_TOWNS](state, data) {
    state.towns = data
  },
  [CERTIFICATE_CHECK_LOADING](state, status) {
    state.isCertificateLoading = status
  },
  [SET_CERTIFICATE_CHECK](state, data) {
    state.certificateStatus = data
  },
  [CHANGE_SHOW_THANKS_POPUP](state, status) {
    state.showThanksPopup = status
  },
  [SET_DELIVERY_TYPES](state, data) {
    state.deliveryTypes = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
};
