import {mapActions, mapGetters, mapMutations} from "vuex";


export default {
  name: 'change-promo',
  data() {
    return {
      check: false,
      clickButton: false,
      payload: {
        type: 2
      }
    }
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapActions({
      changeProfi: 'profile/CHANGE_PROFESSIONAL'
    }),
    ...mapMutations({
      changeShowchangePromo: 'user/SHOW_CHANGE_PROMOCODE',
      changeShowPopupDeletePromo: 'profile/CHANGE_SHOW_DELETE_PROMO'
    }),
    sendRequest() {
      this.clickButton = true
      if (this.check) {
        this.clickButton = false
        this.changeProfi(this.payload).then(() => {
          this.changeShowchangePromo(false)
          this.changeShowPopupDeletePromo(true)
          this.$toasted.success(this.$t('promoSendSuccess'))
        })
      } else {
        this.clickButton = true
        this.$toasted.error(this.$t('error'))
      }
    }
  },
}
