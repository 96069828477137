import {mapActions, mapGetters, mapMutations} from "vuex";
import {email, maxLength, minLength, required, sameAs} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";
import FacebookWidget from '@/components/widgets/facebook/index.vue';
import Google from '@/components/widgets/google/index.vue'

export default {
    props: ['title', 'width'],
    components: {
        FacebookWidget,
        Google
    },
    data() {
        return {
            activeItemRepair: false,
            checked: [],
            passwordType: 'password',
            password_confirmation_type: 'password',
            password_registration_type: 'password',
            activeItem: null,
            payload: {
                name: '',
                last_name: '',
                phone: '',
                email: '',
                email_registration: '',
                email_forgot: '',
                product_id: '',
                count: 1,
                activeItem: null,
                password: '',
                password_registration: '',
                password_confirmation: '',
            },
            validationErrors: {},
        }
    },
    validations: {
        payload: {
            phone: {
                required,
                minLength: minLength(19)
            },
            name: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(20)
            },
            last_name: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(20)
            },
            password: {
                required,
                minLength: minLength(8),
                maxLength: maxLength(20),
            },
            password_registration: {
                required,
                minLength: minLength(8),
                maxLength: maxLength(20),
            },
            password_confirmation: {
                required,
                minLength: minLength(8),
                maxLength: maxLength(20),
                sameAsPassword: sameAs('password_registration')
            },
            email: {
                required,
                maxLength: maxLength(60),
                email,
            },
            email_forgot: {
                required,
                maxLength: maxLength(60),
                email,
            },
            email_registration: {
                required,
                maxLength: maxLength(60),
                email,
            },
        },
    },
    watch: {},
    computed: {
        ...mapGetters({
            popupRegistration: `card/popupRegistration`,
            restorePasswordLoading: 'auth/restorePasswordLoading'
        }),
        passwordErrors() {
            let error = [];
            if (!this.$v.payload.password.$dirty) {
                return error;
            }
            if (!this.$v.payload.password.maxLength) {
                error.push(this.$t('validationMax') + ' 20')
            }
            if (!this.$v.payload.password.required) {
                error.push(this.$t('validationRequired'));
            }
            if (!this.$v.payload.password.minLength) {

                error.push(this.$t('validationMin') + ' 8')
            }
            if (this.validationErrors.password) {
                this.validationErrors.password.forEach((row) => {
                    error.push(row);
                })
            }
            return error;
        },
        passwordConfirmationErrors() {
            let error = [];
            if (!this.$v.payload.password_confirmation.$dirty) {
                return error;
            }
            if (!this.$v.payload.password_confirmation.maxLength) {
                error.push(this.$t('validationMax') + '30');
            }
            if (!this.$v.payload.password_confirmation.required) {
                error.push(this.$t('validationRequired'));
            }
            if (!this.$v.payload.password_confirmation.sameAsPassword) {
                error.push(this.$t('validationSameAs'));
            }
            if (!this.$v.payload.password_confirmation.minLength) {
                error.push(this.$t('validationMin') + '8');
            }
            if (this.validationErrors.password_confirmation) {
                this.validationErrors.password_confirmation.forEach((row) => {
                    error.push(row);
                })
            }
            return error;
        },
        passwordRegistrationErrors() {
            let error = [];
            if (!this.$v.payload.password_registration.$dirty) {
                return error;
            }
            if (!this.$v.payload.password_registration.maxLength) {
                error.push(this.$t('validationMax') + '20');
            }
            if (!this.$v.payload.password_registration.required) {
                error.push(this.$t('validationRequired'));
            }
            if (!this.$v.payload.password_registration.minLength) {
                error.push(this.$t('validationMin') + '8');
            }
            if (this.validationErrors.password_registration) {
                this.validationErrors.password_registration.forEach((row) => {
                    error.push(row);
                })
            }
            return error;
        },
        emailErrors() {
            let error = [];
            if (!this.$v.payload.email.$dirty) {
                return error;
            }
            if (!this.$v.payload.email.maxLength) {
                error.push(this.$t('validationMax') + '60');
            }
            if (!this.$v.payload.email.required) {
                error.push(this.$t('validationRequired'))
            }
            if (!this.$v.payload.email.email) {
                error.push(this.$t('validationEmail'))
            }
            if (this.validationErrors.email) {
                this.validationErrors.email.forEach((row) => {
                    error.push(row);
                });
            }
            return error;
        },
        emailRegistrationErrors() {
            let error = [];
            if (!this.$v.payload.email_registration.$dirty) {
                return error;
            }
            if (!this.$v.payload.email_registration.maxLength) {
                error.push(this.$t('validationMax') + '60');
            }
            if (!this.$v.payload.email_registration.required) {
                error.push(this.$t('validationRequired'))
            }
            if (!this.$v.payload.email_registration.email) {
                error.push(this.$t('validationEmail'))
            }
            if (this.validationErrors.email_registration) {
                this.validationErrors.email_registration.forEach((row) => {
                    error.push(row);
                });
            }
            return error;
        },
        emailForgotErrors() {
            let error = [];
            if (!this.$v.payload.email_forgot.$dirty) {
                return error;
            }
            if (!this.$v.payload.email_forgot.maxLength) {
                error.push(this.$t('validationMax') + '60');
            }
            if (!this.$v.payload.email_forgot.required) {
                error.push(this.$t('validationRequired'))
            }
            if (!this.$v.payload.email_forgot.email) {
                error.push(this.$t('validationEmail'))
            }
            if (this.validationErrors.email_forgot) {
                this.validationErrors.email_forgot.forEach((row) => {
                    error.push(row);
                });
            }
            return error;
        },
        nameErrors() {
            let error = [];
            if (!this.$v.payload.name.$dirty) {
                return error;
            }
            if (!this.$v.payload.name.required) {
                error.push(this.$t('validationRequired'))
            }
            if (!this.$v.payload.name.minLength) {
                error.push(this.$t('validationMin') + '3');
            }
            if (!this.$v.payload.name.maxLength) {
                error.push(this.$t('validationMax') + '20');
            }
            if (this.validationErrors.name) {
                this.validationErrors.name.forEach((row) => {
                    error.push(row);
                })
            }
            return error;
        },
        lastNameErrors() {
            let error = [];
            if (!this.$v.payload.last_name.$dirty) {
                return error;
            }
            if (!this.$v.payload.last_name.required) {
                error.push(this.$t('validationRequired'));
            }
            if (!this.$v.payload.last_name.minLength) {
                error.push(this.$t('validationMin') + '3');
            }
            if (!this.$v.payload.last_name.maxLength) {
                error.push(this.$t('validationMax') + '20');
            }
            if (this.validationErrors.last_name) {
                this.validationErrors.last_name.forEach((row) => {
                    error.push(row);
                });
            }
            return error;
        },
        phoneErrors() {
            let error = [];
            if (!this.$v.payload.phone.$dirty) {
                return error;
            }
            if (!this.$v.payload.phone.required) {
                error.push(this.$t('validationRequired'))
            }
            if (!this.$v.payload.phone.minLength) {
                error.push(this.$t('validationMin') + '12');
            }
            if (this.validationErrors.phone) {
                this.validationErrors.phone.forEach((row) => {
                    error.push(row);
                })
            }
            return error;
        },
    },

    mounted() {
        this.activeItem = 0
    },
    methods: {
        ...mapMutations({
            userFakeAuth: 'header/CHANGE_FAKE_USER_AUTH',
        }),
        ...mapActions({
            registration: `auth/REGISTRATION`,
            singIn: `auth/GET_TOKEN`,
            resetPassword: `auth/RESTORE_PASSWORD`
        }),


        showItemRepair() {
            this.activeItemRepair = !this.activeItemRepair

        },

        setFakeUserAuth() {
            const parsed = JSON.stringify(['fakeAuthCode']);
            localStorage.setItem('fakeAuth', parsed);
            this.userFakeAuth(['fakeAuthCode'])
            this.changePopupRegistration(false)
            setTimeout(function () {
                window.location.reload()
            }, 800);
            this.$router.push({name: 'personal-data'}).catch(() => {
                console.log();
            });
        },

        select() {
        },
        setPasswordType() {
            this.passwordType === 'password' ? this.passwordType = 'text' : this.passwordType = 'password';
        },
        setPasswordRegistrationType() {
            this.password_registration_type === 'password' ? this.password_registration_type = 'text' : this.password_registration_type = 'password';
        },
        setPasswordConfType() {
            this.password_confirmation_type === 'password' ? this.password_confirmation_type = 'text' : this.password_confirmation_type = 'password';
        },
        setActive(value) {
            this.activeItemRepair = false
            this.activeItem = value
        },
        closePopup() {
            this.$emit('close-popup');
        },

        ...mapActions({
            fetchUser: `profile/FETCH_USER_DATA`,
            fetchFavoriteList: `favorites/GET_FAVORITES_LIST`
        }),
        ...mapMutations({
            changePopupRegistration: `card/CHANGE_POPUP_REGISTRATION`,
        }),

        submitLogin() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                let obj = {
                    email: this.payload.email,
                    password: this.payload.password,
                };
                this.singIn(obj)

                    .then(() => {
                        this.$toasted.success(this.$t('loginSuccess'));
                        this.$emit('close-popup');
                        // this.fetchFavoriteList()
                        // // this.$router.push({name:'profile'}).catch(()=>{
                        // //   console.log()
                        // // })
                        // console.log(1)
                        // this.fetchUser();
                        window.location.reload()

                    }).catch(error => {


                    console.log(error)
                    if (error.response.status === 418) {
                        this.$toasted.error(this.$t('notMatchAccount'));

                    }
                    if (error.response.status === 422) {
                        this.$toasted.error(this.$t('checkCorrectData'));

                    }
                    if (error.response.status === 427) {
                        this.$toasted.error(this.$t('checkCorrectData'));
                    }
                });
            }
        },
        submitEmailRepair() {
            this.$v.$touch();
            if (!this.$v.payload.email_forgot.$invalid) {
                this.resetPassword(this.payload.email_forgot).then(() => {
                    this.$toasted.success(this.$t('instructionSendOnEmail'));
                    this.changePopupRegistration(false)
                }).catch(error => {
                    if (error.response.status === 400) {
                        this.$toasted.error(this.$t("wrongUser"));
                    }
                })
            }
        },

        submit() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                let obj = {};
                obj.email_registration = this.payload.email_registration;
                obj.password = this.payload.password_registration
                obj.password_confirmation = this.payload.password_confirmation;
                obj.first_name = this.payload.name;
                obj.last_name = this.payload.last_name;
                obj.type = 'user';

                if (obj.password === obj.password_confirmation) {
                    this.registration(obj)
                        .then(() => {
                            this.$toasted.success(this.$t('registrationSuccess'));
                            this.$emit('close-popup');
                            // this.changeFastBuy(false, {
                            //   show: false,
                            // });
                        }).catch(error => {
                        if (error.response.status === 418) {
                            this.$toasted.error(error.response.data.data.error.message);
                        }
                        if (error.response.status === 422) {
                            this.$toasted.error(error.response.data.errors.email_registration);
                        }
                    });
                }


            }
        }
    },
    created() {

    },
    destroyed() {

    }
}
