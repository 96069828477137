import {mapMutations, mapGetters, mapActions} from 'vuex';
import itemBasket from './components/item-in-basket/index.vue'
export default {
  name: 'requisite-check',
  data() {
    return {}
  },
  components:{
    itemBasket
  },
  computed: {
    ...mapGetters({
      showPopupAddBasket:'basket/showPopupAddBasket',
      basket: 'basket/basket',
    }),
    totalAmount() {
      return this.basket.reduce((accumulator, currentValue) => {
        return accumulator + (currentValue.select_price * currentValue.select_count);
      }, 0);
    },
  },
  created() {
  },
  mounted() {
  },
  methods: {
    gotoBasket(){
      this.changeShowPopupAddBasket(false)
      this.$router.push({ path: '/buy/basket/' });
    },
    closePopup(){
      this.changeShowPopupAddBasket(false)
    },

    ...mapActions({}),
    ...mapMutations({
      changeShowPopupAddBasket:'basket/CHANGE_POPUP_ADD_BASKET'
    }),
  },
  destroyed() {
  }
}
