import {$http} from '@/utils/http';
import {fixBody} from '@/helpers/variables/index';
import {includes} from 'lodash'

import {
    changePopupComment,
    CHANGE_POPUP_FAST_BUY,
    CHANGE_POPUP_QUESTION,
    CHANGE_POPUP_REGISTRATION,
    CHANGE_PRODUCT_LOADING,
    SET_LOADING_SEND_REVIEW,
    SET_PRODUCT,
    CHANGE_CURRENT_CATEGORY, CHANGE_POPUP_PROMOTION

} from '../mutation-types';

import {
    GET_NEW_COLLECTION_LIST,
    GET_PRODUCT,
    GET_PRODUCT_REVIEWS,
    GET_PROMO_LIST,
    SEND_REVIEW,
    SEND_QUESTION
} from '../action-types';

const state = {
    showPromotionProduct: false,
    product: null,
    popupComment: false,
    popupQuestion: false,
    popupRegistration: false,
    popupFastBuy: false,
    limit: 10,
    page: 1,
    currentCategory: '',
};
//
const getters = {
    showPromotionProduct: state => state.showPromotionProduct,
    product: state => state.product,
    popupFastBuy: state => state.popupFastBuy,
    popupComment: state => state.popupComment,
    popupQuestion: state => state.popupQuestion,
    popupRegistration: state => state.popupRegistration,
    productLoading: state => state.productLoading,


};
//
const actions = {
    [GET_PRODUCT]: async ({commit}, payload) => {
        commit(CHANGE_PRODUCT_LOADING, true);
        try {
            let resp = null;
            if (payload.id) {
                resp = await $http.get(`v1/product/${payload.slug}?category_slug=${state.currentCategory !== 'all' ? state.currentCategory : ''}`);
                commit(SET_PRODUCT, resp.data.data);
            } else {
                const response = await $http.get(`v1/product/${payload.slug}`);
                resp = await $http.get(`v1/product/${response.data.data.slug}?category_slug=${state.currentCategory !== 'all' ? state.currentCategory : ''}`);
                response.data.data.breadcrumbs = resp.data.data.breadcrumbs
                commit(SET_PRODUCT, response.data.data);
            }

        } catch (e) {
            throw e
        } finally {
            commit(CHANGE_PRODUCT_LOADING, false);
        }
    },

    [SEND_REVIEW]: async ({commit}, payload) => {
        try {
            let slug = payload.slug
            delete payload.slug;

            commit(SET_LOADING_SEND_REVIEW, true);
            const result = await $http.post(`/v1/product/comment/add/${slug}`, payload);

        } catch (e) {
            throw e;
        } finally {
            commit(SET_LOADING_SEND_REVIEW, false);
        }
    },
    [SEND_QUESTION]: async ({commit}, payload) => {
        try {

            // commit(SET_LOADING_SEND_REVIEW, true);
            const result = await $http.post(`/v1/product/question/add/${payload.slug}`, payload.data);
        } catch (e) {
            throw e;
        } finally {
            // commit(SET_LOADING_SEND_REVIEW, false);
        }
    },

};

const mutations = {
    [CHANGE_POPUP_PROMOTION](state, status) {
        state.showPromotionProduct = status
    },
    [CHANGE_POPUP_FAST_BUY](state, params) {
        state.popupFastBuy = params;
        fixBody(params);
    },
    [CHANGE_POPUP_REGISTRATION](state, params) {
        state.popupRegistration = params;
        fixBody(params);
    },
    [CHANGE_CURRENT_CATEGORY](state, category) {
        state.currentCategory = category;
    },
    [CHANGE_POPUP_QUESTION](state, params) {
        state.popupQuestion = params;
        fixBody(params);
    },
    changePopupComment(state, status) {
        state.popupComment = status
        fixBody(status);
    },


//   [SET_PRODUCT](state, obj) {
//     const filters = {};
//
//     if( obj.simular_products.length ){
//       obj.simular_products.forEach(e => {
//         e.attributes.forEach(t => {
//
//           if (!filters.hasOwnProperty(t.attribute.slug)) {
//             filters[t.attribute.slug] = {};
//             filters[t.attribute.slug].list = [];
//             filters[t.attribute.slug].title = t.attribute.title
//           }
//           t.slug = e.slug;
//           t.id = e.id;
//           t.active = false;
//           filters[t.attribute.slug].list.push(t)
//         })
//       });
//
//
//       const slugs = obj.options.map(e => e.slug);
//       for (let i in filters) {
//         if (!slugs.includes(i)) {
//           delete filters[i]
//         }
//       }
//
//       //comparison all filters with options
//       const newFilter = {};
//       obj.options.forEach(e => {
//         newFilter[e.slug] = filters[e.slug]
//       });
//
//       const selectedAttributes = {}
//       obj.attributes.forEach(e => {
//         if (slugs.includes(e.attribute.slug))
//           selectedAttributes[e.attribute.slug] = e
//
//       });
//
//       for (let i in selectedAttributes) {
//         for (let y in newFilter) {
//           if (i === y) {
//             selectedAttributes[i].active = true;
//             newFilter[i].list.unshift(selectedAttributes[i])
//           }
//         }
//       }
//
//
//       for (let i in newFilter){
//         if(!selectedAttributes.hasOwnProperty(i)){
//           delete newFilter[i]
//         }
//       }
//
//       obj.filters = newFilter;
//     }
//
//     state.product = obj;
//     state.product.images.unshift({
//       link: state.product.image
//     })
//   },
//   [RESET_PRODUCT](state) {
//       state.product = null;
//   },
    [SET_PRODUCT](state, product) {
        product.slider.unshift({"link": product.image});
        product.selected = false
        state.product = product;
    },
//   [SET_REVIEWS](state, obj) {
//     state.reviewList = obj;
//   },
//   [CHANGE_REVIEWS_LOADING](state, status) {
//     state.reviewListLoading = status;
//   },
    [SET_LOADING_SEND_REVIEW](state, status) {
        state.sendReviewsLoading = status;
    },
//   [SET_PROMO_LIST](state, list) {
//     state.promoList = list;
//   },
//   [SET_PROMO_LOADING](state, status) {
//     state.promoListLoading = status;
//   },
    [CHANGE_PRODUCT_LOADING](state, status) {
        state.productLoading = status;
    },
//   [SET_NEW_COLLECTION_LIST](state, list) {
//     state.newCollectionList = list;
//   },
//   [SET_NEW_COLLECTION_LIST_LOADING](state, status) {
//     state.newCollectionListLoading = status;
//   },
//   [SET_PROMO_LIST_LENGTH](state, data) {
//     state.promoListLength = data;
//   },
//   [SET_NEW_LIST_LENGTH](state, data) {
//     state.newCollectionListLength = data;
//   },
//   [SET_REVIEWS_LENGTH](state, data) {
//     state.reviewListLength = data;
//   },

//   [REVIEWS_CHANGE_PAGE](state, page) {
//     state.page = page;
//   },
};
export default {
    state,
    getters,
    actions,
    mutations,
};
