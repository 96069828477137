import {
    FIX_BODY,
    SHOW_GLOBAL_LOADER,
    CHANGE_SHOW_CATALOG_MENU,
    CHANGE_SHOW_CATALOG_MENU_MOBILE,
    CHANGE_OPEN_HEADER_MENU,
    CHANGE_OPEN_FILTER
} from '@/store/mutation-types';


const state = {
    globalLoader: false,
    fixBody: false,
    showCatalogMenu: false,
    showCatalogMenuMobile: false,
    openHeaderMenu: false,
    showFilter: true,
};

const getters = {
    fixBody: state => state.fixBody,
    showCatalogMenu: state => state.showCatalogMenu,
    showCatalogMenuMobile: state => state.showCatalogMenuMobile,
    globalLoader: state => state.globalLoader,
    openHeaderMenu: state => state.openHeaderMenu,
    showFilter: state => state.showFilter,
};

const mutations = {
    [SHOW_GLOBAL_LOADER](state, status) {
        state.globalLoader = status
    },
    [FIX_BODY](state, param) {
        const body = document.querySelector('body');
        if (param) {
            body.classList.add('fix');
        } else {
            body.classList.remove('fix');
        }
        state.fixBody = param
    },
    [CHANGE_SHOW_CATALOG_MENU](state, status) {
        state.showCatalogMenu = status;
    },
    [CHANGE_SHOW_CATALOG_MENU_MOBILE](state, status) {
        state.showCatalogMenuMobile = status;
    },
    [CHANGE_OPEN_HEADER_MENU](state, status){
        state.openHeaderMenu = status;
    },
    [CHANGE_OPEN_FILTER](state, status) {
        state.showFilter = status;
    },
};

export default {
    state,
    getters,
    mutations,
};
