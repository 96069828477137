import Vue from 'vue';
import {$http} from "@/utils/http";
//
import {
  CHANGE_DISCOUNT_COSMETOLOGIST,
  CHANGE_DISCOUNT_BONUS,
  CHANGE_DISCOUNT_ONE_TIME,
  CHANGE_POPUP_ADD_BASKET,
  ADD_ITEM_TO_BASKET,
  REMOVE_ITEM_FROM_BASKET,
  RESET_BASKET,
  SET_CHECK_DISCOUNT,
//   DECREMENT_BASKET_ITEM,
//   INCREMENT_BASKET_ITEM,
//   SET_BASKET_EDIT_COUNT,
  INCREMENT_BASKET_LIST_COUNT,
  DECREMENT_BASKET_LIST_COUNT,
  SET_BASKET_LIST_COUNT, SET_TOTAL_PRICE, SET_CHECK_BONUSES, SET_BONUSES, SET_PERCENT_DISCOUNT,
  SET_FAST_BUY_LOADING, SET_UPDATE_BASKET
//   CHANGE_SHOW_BASKET,
//   DECREMENT_AVAILABILITY_PROPS,
//   INCREMENT_AVAILABILITY_PROPS,
//   RESET_AVAILABILITY_PROPS,
//   SET_FAST_BUY_LOADING,
//   CHANGE_SHOW_ORDER,
//   CREATE_ORDER_LOADING,
//   SET_BASKET_LIST_LOADING,
//   SET_BASKET_LIST,
//   SET_BASKET_LENGTH,
//   ADD_PRODUCT_TO_BASKET_LOADING,
//   REMOVE_PRODUCT_FROM_BASKET_LOADING,
//   SEND_BASKET_LOADING,
//   SET_ORDER
} from '../mutation-types';
import {
  CHECK_DISCOUNT,
  CHECK_BONUSES,
  PAY_BONUSES,
  FAST_BUY_ORDER, UPDATE_BASKET,
} from "../action-types";
import {cloneDeep} from "lodash";
//
// import {
//   FAST_BUY,
//   CREATE_ORDER,
//   SEND_BASKET
//
// } from '../action-types';
//
function save() {

  const parsed = JSON.stringify(state.basket);
  localStorage.setItem('products', parsed);
}

//
function calculateAmountItem(item) {
  item.totalAmount = item.select_count * (item.discount_price ? item.discount_price : item.price);
  return item
}

//
const state = {
  percentDiscount: 0,
  showPopupAddBasket: false,
  showPopupDiscountCosmetologist: false,
  showPopupDiscountBonus: false,
  showPopupDiscountOneTime: false,
//   showBasket: false,
//   showOrder: false,
//   fastBuyLoading: false,
//   addBasketLoading: false,
  basketListLength: 0,
//   removeBasketLoading: false,
//   setBasketLoading: false,
//   orderLoading: false,
  countBasketItems: 0,
  count: 1,
  basket: localStorage.getItem('products') ? JSON.parse(localStorage.getItem('products')) : [],
//   basketLoading: false,
//   orderResult: null,
  discount: '',
  totalPrice: '',
  bonuses: 0,
  setBonuses: 0,
  updatedBasket: ''
};
//
const getters = {
  updatedBasket: state => state.updatedBasket,
  percentDiscount: state => state.percentDiscount,
  setBonuses: state => state.setBonuses,
  bonuses: state => state.bonuses,
  totalPrice: state => state.totalPrice,
  discount: state => state.discount,
  showPopupAddBasket: state => state.showPopupAddBasket,
  showPopupDiscountCosmetologist: state => state.showPopupDiscountCosmetologist,
  showPopupDiscountBonus: state => state.showPopupDiscountBonus,
  showPopupDiscountOneTime: state => state.showPopupDiscountOneTime,
  countBasketItems: state => {
    let count = 0;
    state.basket.forEach(e => {
      count = count + e.select_count;
    });
    return count;
  },
//   showBasket: state => state.showBasket,
//   addBasketLoading: state => state.addBasketLoading,
//   removeBasketLoading: state => state.removeBasketLoading,
//   showOrder: state => state.showOrder,
  fastBuyLoading: state => state.fastBuyLoading,
//   setBasketLoading: state => state.setBasketLoading,
//   basketLoading: state => state.basketLoading,
//   orderLoading: state => state.orderLoading,
  basket: state => state.basket || [],
  count: state => state.count,
  basketListLength: state => state.basketListLength,
//   orderResult: state => state.orderResult,
};
//
//
const actions = {

  [UPDATE_BASKET]: async ({commit}, payload) => {
    // commit(SET_FAST_BUY_LOADING, true);
    try {
      const response = await $http.post(`v1/update_basket`, payload);
      commit(SET_UPDATE_BASKET, response.data.data);
    } catch (e) {
      e.preventDefault()
      throw e;
    } finally {
      // commit(SET_FAST_BUY_LOADING, false);
    }
  },
  [PAY_BONUSES]: async ({commit}, payload) => {
    // commit(SET_FAST_BUY_LOADING, true);
    try {
      const response = await $http.post(`v1/order/pay/bonuses`, payload);
      commit(SET_BONUSES, payload.bonus_sum);
    } catch (e) {
      e.preventDefault()
      throw e;
    } finally {
      // commit(SET_FAST_BUY_LOADING, false);
    }
  },
  [CHECK_BONUSES]: async ({commit}, payload) => {
    // commit(SET_FAST_BUY_LOADING, true);
    try {
      const response = await $http.post(`v1/order/chek_bonus`, payload);
      commit(SET_CHECK_BONUSES, response.data);
    } catch (e) {
      throw e;
    } finally {
      // commit(SET_FAST_BUY_LOADING, false);
    }
  },
  [CHECK_DISCOUNT]: async ({commit}, payload) => {
    // commit(SET_FAST_BUY_LOADING, true);
    try {
      const response = await $http.post(`v1/order/chek_discount`, payload);
      commit(SET_CHECK_DISCOUNT, response.data);
    } catch (e) {
      throw e;
    } finally {
      // commit(SET_FAST_BUY_LOADING, false);
    }
  },
  [FAST_BUY_ORDER]: async ({commit}, payload) => {
    commit(SET_FAST_BUY_LOADING, true);
    try {
      return await $http.post('v1/order/create', payload);
    } catch (e) {
      throw e;
    } finally {
      commit(SET_FAST_BUY_LOADING, false);
    }
  },
//   [CREATE_ORDER]: async ({commit}, payload) => {
//     commit(CREATE_ORDER_LOADING, true);
//     try {
//       const response =  await $http.post(`v1/cart/submit`, payload);
//       commit(SET_ORDER, response.data.data);
//     } catch (e) {
//       throw e;
//     } finally {
//       commit(CREATE_ORDER_LOADING, false);
//     }
//   },
//   [SEND_BASKET]: async ({commit}, payload) => {
//     commit(SEND_BASKET_LOADING, true);
//     try {
//       return await $http.post(`v1/cart/set`, payload);
//     } catch (e) {
//       throw e;
//     } finally {
//       commit(SEND_BASKET_LOADING, false);
//     }
//   },
};

const mutations = {
  [SET_PERCENT_DISCOUNT](state, data) {
    state.percentDiscount = data
  },
  [SET_BONUSES](state, data) {
    state.setBonuses = data
  },
  [CHANGE_POPUP_ADD_BASKET](state, status) {
    state.showPopupAddBasket = status;
  },
  [CHANGE_DISCOUNT_COSMETOLOGIST](state, status) {
    state.showPopupDiscountCosmetologist = status;
  },
  [CHANGE_DISCOUNT_BONUS](state, status) {
    state.showPopupDiscountBonus = status;
  },
  [CHANGE_DISCOUNT_ONE_TIME](state, status) {
    state.showPopupDiscountOneTime = status;
  },
  [SET_CHECK_DISCOUNT](state, data) {
    state.discount = data
  },
  [SET_CHECK_BONUSES](state, data) {
    state.bonuses = data
  },
  [SET_TOTAL_PRICE](state, data) {
    state.totalPrice = data
  },
  [SET_UPDATE_BASKET](state, data) {
    let basketProducts = cloneDeep(state.basket);
    let arr = []
    data.map(item => {
      basketProducts.forEach(product => {
        product.weights.forEach((el, idx) => {
          if (el.id === item.id) {
            let obj = {}
            obj.price = item.price
            item = Object.assign({}, item, product, obj, {
              select_price: item.price
            });
            item.weights[idx].price = item.price
            return item
          }
        })
      })
      arr.push(item)
    });

    state.basket = arr
    save();
  },
//   [SET_BASKET_LIST](state, list) {
//     state.basket = list;
//
//     state.basket.forEach((e) => {
//       calculateAmountItem(e);
//     });
//   },
//   [SET_BASKET_LIST_LOADING](state, status) {
//     state.basketLoading = status;
//   },
//   [SEND_BASKET_LOADING](state, status) {
//     state.setBasketLoading = status;
//   },
//   [ADD_PRODUCT_TO_BASKET_LOADING](state, status) {
//     state.addBasketLoading = status;
//   },
//   [REMOVE_PRODUCT_FROM_BASKET_LOADING](state, status) {
//     state.removeBasketLoading = status;
//   },
//   [SET_BASKET_LENGTH](state, data) {
//     state.basketListLength = data;
//   },
//
//
//   // add item to basket
  [ADD_ITEM_TO_BASKET](state, obj) {
    if (!state.basket.length) {
      // obj.select_count = obj.select_count
      console.log(obj, 'obj')
      state.basket.push(obj);
      save();
      return;
    }
    const list = state.basket.filter(e => e.id === obj.id)
    if (list.length) {
      const item = state.basket.find(e => e.id === obj.id && e.select_volume.id === obj.select_volume.id);
      if (item) {
        const index = state.basket.findIndex(e => e.id === obj.id && e.select_volume.id === obj.select_volume.id);
        obj.select_count = state.basket[index].select_count + obj.select_count
        Vue.set(state.basket, index, obj)
      } else {
        if (!obj.select_count) {
          obj.select_count = 1
        }
        state.basket.push(obj);
      }
    } else {
      if (!obj.select_count) {
        obj.select_count = 1
      }
      state.basket.push(obj);
    }
    save();
    // state.basket.map((e, i) => {
    //   if (e.id === obj.id && e.select_volume === obj.select_volume) {
    //     if (e.to === obj.to && e.from === obj.from ) {
    //       obj.select_count = obj.select_count + e.select_count;
    //       Vue.delete(state.basket, i);
    //     }
    //   }
    // });

    // state.basket.push(calculateAmountItem(obj));
    // save();
  },
//
//
//
//    //remove item
  [REMOVE_ITEM_FROM_BASKET](state, obj) {
    const index = state.basket.findIndex(e => e.id === obj.id)
    state.basket.splice(index, 1);
    save();
  },
//
//
//
//
//
  [RESET_BASKET](state) {
    localStorage.removeItem('products');
    state.basket = [];
  },
//
//
//
//
//   // increment decrement count in basket
  [INCREMENT_BASKET_LIST_COUNT](state, obj) {
    const index = state.basket.findIndex(e => e.id === obj.id)
    obj.select_count = obj.select_count + 1;
    Vue.set(state.basket, index, calculateAmountItem(obj));
    save();
  },

  [SET_BASKET_LIST_COUNT](state, obj) {
    const index = state.basket.findIndex(e => e.id === obj.id)
    Vue.set(state.basket, index, obj);
    save();
  },

  [DECREMENT_BASKET_LIST_COUNT](state, obj) {
    const index = state.basket.findIndex(e => e.id === obj.id)
    obj.select_count = obj.select_count - 1;
    Vue.set(state.basket, index, calculateAmountItem(obj));
    save();
  },
//
//
//
//   [SET_BASKET_EDIT_COUNT](state, val) {
//     state.editCount = val;
//   },
//   [CHANGE_SHOW_BASKET](state, status) {
//     state.showBasket = status;
//   },
//   [CHANGE_SHOW_ORDER](state, status) {
//     state.showOrder = status;
//   },
//   [DECREMENT_AVAILABILITY_PROPS](state) {
//     if(state.count > 1) {
//       state.count--;
//     }
//   },
//   [INCREMENT_AVAILABILITY_PROPS](state) {
//     state.count++;
//   },
//   [RESET_AVAILABILITY_PROPS](state) {
//     state.count = 1;
//   },
//   [DECREMENT_BASKET_ITEM](state) {
//     state.count++;
//   },
//   [INCREMENT_BASKET_ITEM](state) {
//     if(state.count > 1) {
//       state.count--;
//     }
//   },
//
  [SET_FAST_BUY_LOADING](state, status) {
    state.fastBuyLoading = status;
  },
//   [CREATE_ORDER_LOADING](state, status) {
//     state.orderLoading = status;
//   },
//   [SET_ORDER](state, data) {
//     state.orderResult = data;
//   },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
