import navigateMixin from '../../../../mixins/buy-flow.js'
import {mapMutations} from "vuex";

export default {
  name: "discount-bonus",
  mixins: [navigateMixin],
  methods:{
    ...mapMutations({
      changePopupDiscountBonus: 'basket/CHANGE_DISCOUNT_BONUS',
    })
  }
}
