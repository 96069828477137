import {mapMutations, mapActions, mapGetters} from "vuex";
import VueHtml2pdf from 'vue-html2pdf'

export default {
    name: "recommendation-popup",
    props: ['id'],
    components: {
        VueHtml2pdf
    },
    data() {
        return {
            item: null
        }
    },
    created() {
        this.getCurrentRecommendation(this.id)


    },
    computed: {
        ...mapGetters({
            currentRecommendation: 'auth/currentRecommendation'
        })
    },
    methods: {
        ...mapActions({
            getCurrentRecommendation: 'auth/GET_CURRENT_RECOMMENDATION'
        }),
        downloadPdf(){
            this.$refs.html2Pdf.generatePdf()
        },

    }
}
