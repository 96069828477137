export default {
  computed: {
    currencySign() {
      let currency = JSON.parse(localStorage.getItem('currency'))
      if (currency === 'USD') {
        return '$'
      }
      if (currency === 'EUR') {
        return '€'
      }
      if (currency === 'UAH') {
        return 'грн'
      }
    }
  },
};
