import _ from 'lodash'
import {$http} from '@/utils/http'

import {
  SET_LIST_LENGTH,
  SET_BREADCRUMBS,
  SET_LOADING_PRODUCTS_LIST,
  SET_PRODUCTS_LIST,
  SET_CHECKED_FILTERS,
  PRODUCTS_CHANGE_PAGE,
  // SET_LOADING_FILTER_PRODUCTS_LIST,
  SET_FILTER_PRODUCTS_LIST,
  GET_FILTRED_PRODUCTS_LIST,
  SET_FILTERS,
  CHANGE_STATUS_FAVORITE,
  SET_PRODUCTS_SORT_TYPE,
  RESET_FILTERS, INCREMENT_SKIP, RESET_CATEGORY_SKIP,
  // SET_FILTER_LIST_LENGTH,
  // SET_LOADING_ALL_PRODUCTS_LIST,
  // GET_TYPES
} from '../mutation-types';

import {
  GET_PRODUCTS_LIST,
  GET_BREADCRUMBS,
  GET_FILTER_PRODUCTS_LIST,
  GET_ALL_PRODUCTS_LIST,
  GET_FILTERS
} from '../action-types';


const state = {
  selectedSortType: {
    id: 0,
    title: 'Default',
    key: 'default'
  },
  list: null,
  listLoading: false,
  listLength: false,
  checkedFilters: null,
  breadcrumbs: null,
  listFilter: null,
  filters: null,
  resetFilters: false,
  skip: 0,
  count: 0,
  limit: 12,
  page: 1,
  categorySkip: 1,
  categoryLimit: 12,
  types: [{
    id: 0,
    title: 'type1'
  },
    {
      id: 1,
      title: 'type2'
    }]
};
//
const getters = {
  categorySkip: state => state.categorySkip,
  categoryLimit: state => state.categoryLimit,
  selectedSortType: state => state.selectedSortType,
  list: state => state.list,
  breadcrumbs: state => state.breadcrumbs,
  skip: state => state.skip,
  count: state => state.count,
  listLoading: state => state.listLoading,
  listLength: state => state.listLength,
  limit: state => state.limit,
  page: state => state.page,
  listFilter: state => state.listFilter,
  // listFilterLoading: state => state.listFilterLoading,
  // loadingAllProductList: state => state.loadingAllProductList,
  // listFilterLength: state => state.listFilterLength,
  types: state => state.types,
  filters: state => state.filters,
  resetFilters: state => state.resetFilters,


  checkedFilters: state => state.checkedFilters,
};
//
const actions = {


  [GET_ALL_PRODUCTS_LIST]: async ({commit}) => {
    commit(SET_LOADING_PRODUCTS_LIST, true);
    try {
      const response = await $http.get(`v1/catalog`);
      commit(SET_PRODUCTS_LIST, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(SET_LOADING_PRODUCTS_LIST, false);
    }
  },


  [GET_PRODUCTS_LIST]: async ({commit}, payload) => {
    commit(SET_LOADING_PRODUCTS_LIST, true);
    try {
      const response = await $http.get(`v1/category/${payload.slug}`);
      // const resp = await $http.get(`v1/category/${response.data.data.slug}?category_id=${response.data.data.id}`);
      // commit(SET_BREADCRUMBS, resp.data.data.breadcrumbs);
      commit(SET_FILTERS, response.data.data);
      // commit(SET_LIST_LENGTH, response.data.data.products.length);
    } catch (e) {
      throw e;
    } finally {
      commit(SET_LOADING_PRODUCTS_LIST, false);
    }
  },
  [GET_FILTER_PRODUCTS_LIST]: async ({commit}, payload) => {
    commit(SET_LOADING_PRODUCTS_LIST, true);
    try {
      const response = await $http.post(`v1/category/${payload.slug}/filters?sort=${state.selectedSortType.key}&category_slug=${payload.slug !== 'all' ? payload.slug : ''}?skip=${state.categorySkip}&limit=${state.categoryLimit}`, payload.data);
      // const responseImg = await $http.get(`v1/category/${payload.slug}`);
      // response.data.data.image = responseImg.data.data.image
      // response.data.data.title = responseImg.data.data.title
      commit(SET_PRODUCTS_LIST, response.data.data);
      // commit(SET_LIST_LENGTH, response.data.data.products.length);
    } catch (e) {
      throw e;
    } finally {
      commit(SET_LOADING_PRODUCTS_LIST, false);
    }
  },
  [GET_FILTERS]: async ({commit}, payload) => {
    try {
      const response = await $http.get(`v1/category/${payload.slug}/filters`);
      commit(SET_FILTERS, response.data.data);
    } catch (e) {
      throw e;
    } finally {

    }
  },
  [GET_FILTRED_PRODUCTS_LIST]: async ({commit}, payload) => {
    commit(SET_LOADING_PRODUCTS_LIST, true);
    try {
      const response = await $http.post(`v1/category/${payload.slug}/filters`, payload.data);
      commit(SET_PRODUCTS_LIST, response.data.data);

    } catch (e) {
      throw e;
    } finally {
      commit(SET_LOADING_PRODUCTS_LIST, false);
    }
  },
};
//
const mutations = {
  [SET_PRODUCTS_SORT_TYPE](state, data) {
    state.selectedSortType = data;
  },
  [SET_PRODUCTS_LIST](state, list) {
    list.products.map(e => {
      e.selected = false
    })
    state.list = list;
  },
  [CHANGE_STATUS_FAVORITE](state, data) {
    if (state.list) {
      const index = state.list.findIndex(e => e.id === data.product_id)
      state.list[index].selected = true
    }
  },
  [SET_FILTERS](state, list) {
    state.filters = list;
  },
  [SET_LOADING_PRODUCTS_LIST](state, status) {
    state.listLoading = status;
  },
  [SET_LIST_LENGTH](state, data) {
    state.listLength = data;
  },
  [PRODUCTS_CHANGE_PAGE](state, page) {
    state.page = page;
  },
  [SET_FILTER_PRODUCTS_LIST](state, list) {
    state.list = list;
  },
  [SET_FILTER_PRODUCTS_LIST](state, list) {
    state.listFilter = list;
  },
  [SET_BREADCRUMBS](state, list) {
    state.breadcrumbs = list;
  },
  [RESET_FILTERS](state, status) {
    state.resetFilters = status;
  },
  [SET_CHECKED_FILTERS](state, filter) {

    if (state.checkedFilters === null) {
      state.checkedFilters = filter;
    } else if (_.isEmpty(filter)) {
      state.checkedFilters = null;
    } else {
      let arr = _.values(filter)
      state.checkedFilters[Object.keys(filter)[0]] = arr[0]
    }
  },
  [INCREMENT_SKIP](state) {
    state.categorySkip++
    state.categoryLimit = state.categorySkip * 12
    console.log(state.categorySkip);
    console.log(state.categoryLimit);
  },
  [RESET_CATEGORY_SKIP](state) {
    state.categorySkip = 1
    state.categoryLimit = 12
    console.log(state.categorySkip, 'reset');
    console.log(state.categoryLimit, 'reset');
  }
  // [SET_LOADING_FILTER_PRODUCTS_LIST](state, status) {
  //     state.listFilterLoading = status;
  // },
  // [SET_LOADING_ALL_PRODUCTS_LIST](state, status) {
  //     state.loadingAllProductList = status;
  // },
  // [SET_FILTER_LIST_LENGTH](state, data) {
  //     state.listFilterLength = data;
  // },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
