import Vue from 'vue';
import store from '@/store';
import router from '@/router';
import axios from 'axios';
import VueAxios from 'vue-axios';

import VueAuthenticate from 'vue-authenticate'
import {REMOVE_TOKEN} from "../store/mutation-types";


Vue.use(VueAxios, axios);
Vue.use(VueAuthenticate, {
  baseUrl: 'http://localhost:8080',
  providers: {
    instagram: {
      name: 'instagram',
      url: '/auth/instagram',
      authorizationEndpoint: 'https://api.instagram.com/oauth/authorize',
      redirectUri: getRedirectUri(),
      requiredUrlParams: ['scope'],
      scope: ['basic'],
      scopeDelimiter: '+',
      oauthType: '2.0',
      popupOptions: {width: null, height: null},
    },
  }
})

// for multiple parallel requests
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

export const $http = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
  headers: {
    'Authorization': `Bearer ${localStorage.getItem('user_token')}`,
    'Accept': 'application/json',
  }
});
$http.interceptors.request.use((config) => {
  config.headers['Authorization'] = `Bearer ${localStorage.getItem('user_token')}`;
  config.headers['X-localization'] = store.getters.currentLanguage.translationKey;
  config.headers['X-currency'] = JSON.parse(localStorage.getItem('currency'));
  console.log('store.getters.currentLanguage',store.getters.currentLanguage)
  return config;
});

$http.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    const originalRequest = error.config;
    switch (error.response.status) {
      case 500: {
        router.push({name: 'server-error'}).catch(() => {
          console.log()
        });
        break;
      }
      case 404: {
        router.push({name: 'not-found'}).catch(() => {
          console.log()
        });
        break;

      }
      case 400: {
        break;
      }


      case 418: {
        // store.commit(`auth/REMOVE_TOKEN`);
        router.push({name: 'home'}).then(() => {

          // window.location.reload()
        }).catch(() => {
          console.log()
        });
        break;

      }

    }
    if (error.response.status === 401 && !originalRequest.retry) {
      if (isRefreshing) {
        return new Promise(((resolve, reject) => {
          failedQueue.push({resolve, reject});
        })).then((token) => {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          return Vue.axios(originalRequest);
        }).catch(error => error);
      }

      originalRequest.retry = true;
      isRefreshing = true;

      return new Promise(((resolve, reject) => {
        $http.post('v1/auth/refresh', {token: localStorage.getItem('user_token')})
          .then(response => {
            store.commit(`auth/SET_TOKEN`, response.data.access_token);
            originalRequest.headers.Authorization = `Bearer ${response.data.access_token}`;
            processQueue(null, response.data.access_token);
            resolve(Vue.axios(originalRequest));
          })
          .catch((error) => {
            console.log(error);
            router.push({name: 'home'}).catch(() => {
              console.log()
            });
            store.commit(`auth/REMOVE_TOKEN`);
            processQueue(error, null);
            reject(error);
          })
          .then(() => {
            isRefreshing = false;
          });
      }));
    }
    return Promise.reject(error);
  }
);

export default function install(Vue) {
  Object.defineProperty(Vue.prototype, '$http', {
    get() {
      return $http
    }
  })
}

export function getRedirectUri(uri) {
  try {
    return !isUndefined(uri)
      ? `${$window.location.origin}${uri}`
      : $window.location.origin;
  } catch (e) {
  }

  return uri || null;
}
