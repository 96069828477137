import {$http} from '@/utils/http'
import {objectToEncodedQueryString} from '@/helpers/variables/index';

import {
  ABOUT_STATIC_LINKS,
  ABOUT_STATIC_LINKS_LOADING,
  ABOUT_STATIC_LIST,
  ABOUT_STATIC_LIST_LOADING,
  ABOUT_MEDIA,
  ABOUT_MEDIA_LOADING, SAVE_MEDIA_CONTENT, BLOG_DATA_LOADING, BLOG_DATA, SET_ABOUT_MEDIA,
} from '../mutation-types';

import {
  ABOUT_MEDIA_GET,
  ABOUT_STATIC_GET, BLOG_DATA_GET, GET_ABOUT_MEDIA,
} from '../action-types';


const state = {
  aboutMedia: '',
  staticLinks: {
    list: [],
    isLoading: false,
    isDone: false,
    error: null,
  },
  staticList: {
    list: [],
    isLoading: false,
    isDone: false,
    error: null,
  },
  media: {
    list: [],
    meta: null,
    isLoading: false,
    isDone: false,
    error: null,
    limit: 6,
  },
  mediaContent: ''
};

const getters = {
  aboutMedia: state => state.aboutMedia,
  mediaContent: state => state.mediaContent,
  staticLinks: state => state.staticLinks.list,
  isLoadingStaticLinks: state => state.staticLinks.isLoading,
  staticList: state => state.staticList.list,
  isLoadingStaticList: state => state.staticList.isLoading,
  mediaList: state => state.media.list,
  mediaLimit: state => state.media.limit,
  mediaMeta: state => state.media.meta,
  isLoadingMedia: state => state.media.isLoading,
};

const actions = {
  [ABOUT_STATIC_GET]: async ({commit}, isGetFullData) => {

    if (isGetFullData) {
      commit(ABOUT_STATIC_LIST_LOADING, true);
      try {
        let include = 'content';
        const response = await $http.get(`v1/about?include=${include}`);
        commit(ABOUT_STATIC_LIST, response.data.data);

      } catch (e) {
        throw e;
      } finally {
        commit(ABOUT_STATIC_LIST_LOADING, false);
      }
    } else {
      commit(ABOUT_STATIC_LINKS_LOADING, true);
      try {
        const response = await $http.get(`v1/about`);
        commit(ABOUT_STATIC_LINKS, response.data.data);

      } catch (e) {
        throw e;
      } finally {
        commit(ABOUT_STATIC_LINKS_LOADING, false);
      }
    }
  },
  [ABOUT_MEDIA_GET]: async ({commit}, params) => {

    commit(ABOUT_MEDIA_LOADING, true);
    try {
      const query = `limit=${state.media.limit}&${objectToEncodedQueryString(params)}`;
      const response = await $http.get(`v1/about/media?${query}`);
      commit(ABOUT_MEDIA, response.data);
    } catch (e) {
      throw e;
    } finally {
      commit(ABOUT_MEDIA_LOADING, false);
    }
  },
  [GET_ABOUT_MEDIA]: async ({commit}, slug) => {
    // commit(BLOG_DATA_LOADING, true);
    try {

      const response = await $http.get(`v1/about/media/${slug}`);
      commit(SET_ABOUT_MEDIA, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      // commit(BLOG_DATA_LOADING, false);
    }
  },
};

const mutations = {
  [SET_ABOUT_MEDIA](state, data) {
    state.aboutMedia = data
  },
  [ABOUT_STATIC_LINKS](state, list) {
    state.staticLinks.list = list;
  },
  [ABOUT_STATIC_LINKS_LOADING](state, status) {
    state.staticLinks.isLoading = status;
  },
  [ABOUT_STATIC_LIST](state, list) {
    state.staticList.list = list;
  },
  [ABOUT_STATIC_LIST_LOADING](state, status) {
    state.staticList.isLoadingList = status;
  },
  [ABOUT_MEDIA](state, data) {
    state.media.list = data.data ? data.data : [];
    state.media.meta = data.meta ? data.meta : null;
  },
  [ABOUT_MEDIA_LOADING](state, status) {
    state.media.isLoading = status;
  },
  [SAVE_MEDIA_CONTENT](state, data) {
    state.mediaContent = data
  }
};
export default {
  state,
  getters,
  actions,
  mutations,
};
