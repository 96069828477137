import {mapActions, mapGetters, mapMutations} from "vuex";


export default {
    name: 'change-cosmetologist',
    props: ['name'],
    data() {
        return {}
    },
    computed: {
        ...mapGetters({
            user: `profile/user`,
        }),
    },
    methods: {
        ...mapActions({}),
        ...mapMutations({
            changeShowAddPromo: 'user/SHOW_POPUP_ERROR_PROMO'
        }),
        redirectToUser() {
            this.$router.push('bonus')
            this.changeShowAddPromo(false)
        }

    },
}
