import {$http} from '@/utils/http';
import {fixBody, globalLoader} from '@/helpers/variables/index';

import {
  AUTH_LOGIN,
  SET_TOKEN,
  SET_COUNTRIES,
  GET_COUNTRIES,
  GET_TOWNS,
  SET_TOWNS,
  REMOVE_TOKEN,
//   CHANGE_POPUP_RESTORE_PASSWORD,
//   CHANGE_POPUP_PERSONAL_DATA,
  SET_LOADING_REGISTRATION,
//   CHANGE_POPUP_SET_NEW_PASSWORD,
//   CHANGE_POPUP_REGISTER,
  SING_IN_WITH_EMAIL_LOADING,
//   SING_IN_WITH_FACEBOOK_LOADING,
//   SING_IN_WITH_GOOGLE_LOADING,
//   SING_IN_WITH_PHONE_LOADING,
//   SEND_CODE_LOADING,
  RESTORE_PASSWORD_LOADING,
  CHANGE_POPUP_SET_NEW_PASSWORD,
  SET_NEW_PASSWORD_LOADING,
  SET_LOGIN_TOKEN,
  SET_TOKEN_SOCIAL,
  SET_LINK_SOCIAL,
  SET_RECOMMENDATIONS,
  SET_CURRENT_RECOMMENDATION, SET_PRICES, SET_REFERRALS, SET_STATE, SET_PARTNERS
} from '../mutation-types';

import {
  GET_TOKEN,
//   GET_TOKEN_PHONE,
  REGISTRATION,
  LOGOUT,
//   SING_IN_WITH_GOOGLE,
//   SING_IN_WITH_FACEBOOK,
//   SEND_CODE_PHONE,
  RESTORE_PASSWORD,
  REGISTRATION_PROFESSIONAL,
  SET_NEW_PASSWORD,
  SING_IN_WITH_SOCIAL,
  LINK_WITH_SOCIAL,
  UNLINK_WITH_SOCIAL,
  UPLOAD_FILES,
  DELETE_FILES,
  GET_RECOMMENDATIONS,
  GET_CURRENT_RECOMMENDATION,
  GET_PRICES, GET_REFERRALS, GET_STATE, GET_PARTNERS
} from '../action-types';

const state = {
  state: [],
  referrals: '',
  prices: '',
  currentRecommendation: '',
  recommendations: '',
  wotSocial: '',
  isAuthenticated: false,
  isProfessional: false,
  countriesRegistration: [],
  // showRestorePassword: false,
  // showPersonalData: false,
  // showSetNewPassword: false,
  loadingRegistration: false,
  popupSetNewPassword: false,
  // singInEmailLoading: false,
  // singInPhoneLoading: false,
  // popupRegister: {
  //   show: false,
  //   isRedirect: true
  // },
  // checkEmailLoading: false,
  // singInGoogleLoading: false,
  // singInFacebookLoading: false,
  // sendCodeLoading: false,
  restorePasswordLoading: false,
  // setNewPasswordLoading: false,
  townsRegistration: [],
  partnersList: ''
};
const getters = {
  partnersList: state => state.partnersList,
  state: state => state.state,
  referrals: state => state.referrals,
  prices: state => state.prices,
  currentRecommendation: state => state.currentRecommendation,
  recommendations: state => state.recommendations,
  wotSocial: state => state.wotSocial,
  isAuthenticated: state => state.isAuthenticated,
  isProfessional: state => state.isProfessional,
  countriesRegistration: state => state.countriesRegistration,
  townsRegistration: state => state.townsRegistration,
  popupSetNewPassword: state => state.popupSetNewPassword,
//   checkEmailLoading: state => state.checkEmailLoading,
//   singInEmailLoading: state => state.singInEmailLoading,
//   singInPhoneLoading: state => state.singInPhoneLoading,
//   showRestorePassword: state => state.showRestorePassword,
//   showPersonalData: state => state.showPersonalData,
//   showSetNewPassword: state => state.showSetNewPassword,
  singIn: state => state.singIn,
  loadingRegistration: state => state.loadingRegistration,
//   popupRegister: state => state.popupRegister,
  singInGoogleLoading: state => state.singInGoogleLoading,
  singInFacebookLoading: state => state.singInFacebookLoading,
//   sendCodeLoading: state => state.sendCodeLoading,
  restorePasswordLoading: state => state.restorePasswordLoading,
//   setNewPasswordLoading: state => state.setNewPasswordLoading
};
// //
const actions = {
  [GET_PARTNERS]: async ({commit}, payload) => {

    try {
      const result = await $http.get(`v1/partners?state=${payload.state}&city=${payload.city}`);
      commit(SET_PARTNERS, result.data.data);
      return result;
    } catch (e) {
      throw e;
    } finally {

    }
  },
  [GET_REFERRALS]: async ({commit}) => {

    try {
      const result = await $http.get(`v1/auth/me/referrals`);
      commit(SET_REFERRALS, result.data.data);
      return result;
    } catch (e) {
      throw e;
    } finally {

    }
  },
  [GET_PRICES]: async ({commit}) => {

    try {
      const result = await $http.get(`v1/auth/me/prices`);
      commit(SET_PRICES, result.data.data);
      return result;
    } catch (e) {
      throw e;
    } finally {

    }
  },
  [GET_CURRENT_RECOMMENDATION]: async ({commit}, id) => {

    try {
      const result = await $http.get(`v1/auth/recommendation/${id}`);
      commit(SET_CURRENT_RECOMMENDATION, result.data);
      return result;
    } catch (e) {
      throw e;
    } finally {

    }
  },
  [GET_RECOMMENDATIONS]: async ({commit}) => {

    try {
      const result = await $http.get('v1/auth/recommendation');
      commit(SET_RECOMMENDATIONS, result.data.data);
      return result;
    } catch (e) {
      throw e;

    } finally {

    }
  },
  [DELETE_FILES]: async ({commit}, id) => {

    try {
      const result = await $http.delete(`v1/auth/drop_file/${id}`);

    } catch (e) {
      throw e;
    } finally {

    }
  },
  [UPLOAD_FILES]: async ({commit}, files) => {

    try {
      const result = await $http.post('v1/auth/update_files', files);

    } catch (e) {
      throw e;
    } finally {

    }
  },
  [UNLINK_WITH_SOCIAL]: async ({commit}, provider) => {

    try {
      const result = await $http.post('v1/auth/unlink', provider);


    } catch (e) {
      throw e;
    } finally {

    }
  },
  [GET_TOKEN]: async ({commit}, payload) => {
    commit(SING_IN_WITH_EMAIL_LOADING, true);
    try {
      const result = await $http.post('v1/auth/login', payload);
      commit(SET_TOKEN, result.data);
    } catch (e) {
      throw e;
    } finally {
      commit(SING_IN_WITH_EMAIL_LOADING, false);
    }
  },
  [REGISTRATION]: async ({commit}, payload) => {
    try {
      commit(SET_LOADING_REGISTRATION, true);
      let type = payload.type
      delete payload['type'];
      const result = await $http.post(`v1/auth/registration?type=${type}`, payload);

      commit(SET_LOGIN_TOKEN, result.data.access_token);
    } catch (e) {
      throw e;
    } finally {
      commit(SET_LOADING_REGISTRATION, false);
    }
  },
  [REGISTRATION_PROFESSIONAL]: async ({commit}, payload) => {
    try {
      SET_RECOMMENDATIONS
      commit(SET_LOADING_REGISTRATION, true);
      const result = await $http.post(`v1/auth/registration?type=professional`, payload);
    } catch (e) {
      throw e;
    } finally {
      commit(SET_LOADING_REGISTRATION, false);
    }
  },
  [AUTH_LOGIN]: async ({commit}, payload) => {
    globalLoader(true);
    try {
      const result = await $http.post('login', payload);
      commit(SET_TOKEN, result.data.access_token);
      return result;
    } catch (e) {
      throw e;
    } finally {
      globalLoader(false);
    }
  },

  [GET_COUNTRIES]: async ({commit}) => {
    globalLoader(true);
    try {
      const result = await $http.get('v1/countries');
      commit(SET_COUNTRIES, result.data.data);
      return result;
    } catch (e) {
      throw e;
    } finally {
      globalLoader(false);
    }
  },
  [GET_STATE]: async ({commit}, country) => {

    try {
      const result = await $http.get(`v1/countries/${country}/states`);
      commit(SET_STATE, result.data.data);
      return result;
    } catch (e) {
      throw e;
    } finally {
      // globalLoader(false);
    }
  },
  [GET_TOWNS]: async ({commit}, {country, state}) => {
    try {
      const result = await $http.get(`v1/countries/${country}/states/${state}/cities`);
      commit(SET_TOWNS, result.data.data);
      return result;
    } catch (e) {
      throw e;
    } finally {
      // globalLoader(false);
    }
  },

//   [SEND_CODE_PHONE]: async ({commit}, payload) => {
//     commit(SEND_SET_PARTNERSCODE_LOADING, true);
//     try {
//       const result = await $http.post(`v1/auth/sendCode/${payload.phone}`, payload);
//     } catch (e) {
//       throw e;
//     } finally {
//       commit(SEND_CODE_LOADING, false);
//     }
//   },
//   [GET_TOKEN_PHONE]: async ({commit}, payload) => {
//     commit(SING_IN_WITH_PHONE_LOADING, true);
//     try {
//       const result = await $http.post('v1/auth/loginByCode', payload);
//       commit(SET_TOKEN, result.data.access_token);
//     } catch (e) {
//       throw e;
//     } finally {
//       commit(SING_IN_WITH_PHONE_LOADING, false);
//     }
//   },


//   [SING_IN_WITH_GOOGLE]: async ({commit}, payload) => {
//     commit(SING_IN_WITH_GOOGLE_LOADING, true);
//     try {
//       const result = await $http.post('v1/auth/login/google', payload);
//       commit(SET_TOKEN, result.data.access_token);
//     } catch (e) {
//       throw e;
//     } finally {
//       commit(SING_IN_WITH_GOOGLE_LOADING, false);
//     }recommendations
//   },
//   [SING_IN_WITH_FACEBOOK]: async ({commit}, payload) => {
//     commit(SING_IN_WITH_FACEBOOK_LOADING, true);
//     try {
//       const result = await $http.post('v1/auth/login/facebook', payload);
//       commit(SET_TOKEN, result.data.access_token);
//     } catch (e) {
//       throw e;
//     } finally {
//       commit(SING_IN_WITH_FACEBOOK_LOADING, false);
//     }
//   },
  [LINK_WITH_SOCIAL]: async ({commit}, socialInfo) => {
    globalLoader(true);
    try {
      const result = await $http.post('v1/auth/link', socialInfo);
      commit(SET_LINK_SOCIAL, socialInfo.provider)
    } catch (e) {
      throw e;
    } finally {
      globalLoader(false);
    }
  },
  [SING_IN_WITH_SOCIAL]: async ({commit}, payload) => {
    // commit(SING_IN_WITH_GOOGLE_LOADING, true);
    try {
      const result = await $http.post('v1/auth/social_login', payload);
      commit(SET_TOKEN_SOCIAL, result.data.access_token, payload);
    } catch (e) {
      throw e;
    } finally {
      // commit(SING_IN_WITH_GOOGLE_LOADING, false);
    }
  },
  [LOGOUT]: async ({commit}) => {
    try {
      globalLoader(true);
      return await $http.post('v1/auth/logout');
    } catch (e) {
      throw e;
    } finally {
      commit(REMOVE_TOKEN);
    }
  },


  [RESTORE_PASSWORD]: async ({commit}, payload) => {
    try {
      commit(RESTORE_PASSWORD_LOADING, true);
      return await $http.post('v1/auth/password/reset', {email: payload});
    } catch (e) {
      throw e;
    } finally {
      commit(RESTORE_PASSWORD_LOADING, false);
    }
  },// eslint-disable-next-line


  [SET_NEW_PASSWORD]: async ({commit}, payload) => {
    try {
      commit(SET_NEW_PASSWORD_LOADING, true);

      return await $http.post('v1/auth/password/restore', payload);
    } catch (e) {
      throw e;
    } finally {
      commit(SET_NEW_PASSWORD_LOADING, false);
    }
  },
};
// //
const mutations = {
  [SET_PARTNERS](state, data) {
    state.partnersList = data
  },
  [SET_REFERRALS](state, data) {
    state.referrals = data
  },
  [SET_PRICES](state, data) {
    state.prices = data
  },
  [SET_CURRENT_RECOMMENDATION](state, data) {
    state.currentRecommendation = data
  },
  [SET_RECOMMENDATIONS](state, data) {
    state.recommendations = data
  },
  [SET_LINK_SOCIAL](state, data) {
    state.wotSocial = data
  },
  [SET_TOKEN](state, data) {
    localStorage.setItem('user_token', data.access_token);
    state.isAuthenticated = true;
    if (data.role === 'Professional') {
      localStorage.setItem('professional', true);
      state.isProfessional = true
    }

  }, [SET_TOKEN_SOCIAL](state, data, payload) {
    localStorage.setItem('user_token', data);
    state.wotSocial = payload
    state.isAuthenticated = true;
    if (data.role === 'Professional') {
      localStorage.setItem('professional', true);
      state.isProfessional = true
    }

  },
  [SET_LOGIN_TOKEN](state, data) {
    localStorage.setItem('user_token', data);
    state.isAuthenticated = true;
    if (data.role === 'Professional') {
      localStorage.setItem('professional', true);
      state.isProfessional = true
    }
  },
  [REMOVE_TOKEN](state) {
    localStorage.removeItem('user_token');
    localStorage.removeItem('professional');
    window.location.reload();
    state.isAuthenticated = false
  },
//   [CHANGE_POPUP_RESTORE_PASSWORD](state, status) {
//     state.showRestorePassword = status;
//     fixBody(status)
//   },
//   [CHANGE_POPUP_SET_NEW_PASSWORD](state, status) {
//     state.showSetNewPassword = status;
//     fixBody(status)
//   },
//   [CHANGE_POPUP_PERSONAL_DATA](state, status) {
//     state.showPersonalData = status;
//     fixBody(status)
//   },
  [SET_LOADING_REGISTRATION](state, status) {
    state.loadingRegistration = status
  },
  [SET_COUNTRIES](state, status) {
    state.countriesRegistration = status
  },
  [SET_STATE](state, data) {
    state.state = data
  },
  [SET_TOWNS](state, status) {
    state.townsRegistration = status
  },
  [CHANGE_POPUP_SET_NEW_PASSWORD](state, status) {
    state.popupSetNewPassword = status
  },
//   [CHANGE_POPUP_REGISTER](state, params) {
//     state.popupRegister = params
//   },
  [SING_IN_WITH_EMAIL_LOADING](state, status) {
    state.singInEmailLoading = status;
  },
//   [SING_IN_WITH_GOOGLE_LOADING](state, status) {
//     state.singInGoogleLoading = status;
//   },
//   [SING_IN_WITH_FACEBOOK_LOADING](state, status) {
//     state.singInFacebookLoading = status;
//   },
//   [SEND_CODE_LOADING](state, status) {
//     state.sendCodeLoading = status;
//   },
  [RESTORE_PASSWORD_LOADING](state, status) {
    state.restorePasswordLoading = status;
  },
//   [SET_NEW_PASSWORD_LOADING](state, status) {
//     state.setNewPasswordLoading = status;
//   },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
