import {$http} from '@/utils/http'

import {
  ARTICLES_LIST,
  ARTICLES_LIST_LOADING,
  ARTICLES_DATA,
  ARTICLES_DATA_LOADING, SHOW_ADD_COMMENT_POPUP, ARTICLES_COMMENT_LOADING, SET_ARTICLE_ID
} from '../mutation-types';

import {
  ARTICLES_LIST_GET,
  ARTICLES_DATA_GET, SEND_ARTICLE_COMMENT
} from '../action-types';


const state = {
  articles: {
    list: [],
    isLoading: false,
    isDone: false,
    error: null,
  },
  article: {
    data: null,
    isLoading: false,
    isDone: false,
    error: null,
  },
  showPopup: false,
  commentLoading: false,
  articleId: ''
};

const getters = {
  articleId: state => state.articleId,
  commentLoading: state => state.commentLoading,
  articlesList: state => state.articles.list,
  isLoadingArticles: state => state.articles.isLoading,
  showPopup: state => state.showPopup,
  articleData: state => state.article.data,
  isLoadingArticle: state => state.article.isLoading,
};

const actions = {
  [ARTICLES_LIST_GET]: async ({commit}, params) => {
    commit(ARTICLES_LIST_LOADING, true);
    try {
      const response = await $http.get(`v1/library/article`);
      commit(ARTICLES_LIST, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(ARTICLES_LIST_LOADING, false);
    }
  },

  [ARTICLES_DATA_GET]: async ({commit}, slug) => {
    commit(ARTICLES_DATA_LOADING, true);
    try {
      const response = await $http.get(`v1/library/article/${slug}`);
      commit(ARTICLES_DATA, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(ARTICLES_DATA_LOADING, false);
    }
  },
  [SEND_ARTICLE_COMMENT]: async ({commit}, payload) => {
    commit(ARTICLES_COMMENT_LOADING, true);


    try {
      const response = await $http.post(`v1/library/article/create_comment`, payload);
      // commit(ARTICLES_DATA, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(ARTICLES_COMMENT_LOADING, false);
    }
  },
};

const mutations = {
  [ARTICLES_LIST](state, data) {
    // state.articles.list = data ? SHOW_ADD_COMMENT_POPUPdata : [];
    state.articles.list = data;
  },
  [ARTICLES_LIST_LOADING](state, status) {
    state.articles.isLoading = status;
  },
  [ARTICLES_DATA](state, data) {
    // state.article.data = data ? data : null;
    state.article.data = data;
  },
  [ARTICLES_DATA_LOADING](state, status) {
    state.article.isLoading = status;
  },
  [SHOW_ADD_COMMENT_POPUP](state, status) {
    state.showPopup = status
  },
  [SET_ARTICLE_ID](state, id) {
    state.articleId = id
  },
  [ARTICLES_COMMENT_LOADING](state, status) {
    state.commentLoading = status
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
