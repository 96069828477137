import {$http} from '@/utils/http'
import {
  SET_HOME_SLIDER_LOADING,
  SET_HOME_SLIDER,
  SET_BANNER_LIST,
  SET_BANNER_LIST_LOADING,
  SET_PRODUCTS_LIST,
  SET_LOADING_PRODUCTS_LIST,
  SET_LIST_LENGTH,
  CHANGE_SKIP,
  CHANGE_CATEGORY, SET_CERTIFICATE_LIST, SET_BUY_REQUEST_SENDING, SET_FAST_BUY_PRODUCT, RESET_PRODUCTS, SET_CATEGORIES
} from '../mutation-types';

import {
  GET_HOME_SLIDER,
  GET_BANNER_LIST,
  GET_PRODUCTS_LIST, GET_CERTIFICATE_LIST, GET_CATEGORIES,
} from '../action-types';


const state = {
  homeSlider: null,
  homeSliderLoading: false,
  bannerList: null,
  bannerListLoading: false,
  list: null,
  listLoading: false,
  skip: 4,
  limit: 4,
  listLength: 0,
  activeCategory: 'rating',
  certificates: '',
  fastBuyRequest: false,
  fastBuyProduct: '',
  skipProduct: 0,
  limitProduct: 8,
  categoriesList: ''
};

const getters = {
  categoriesList: state => state.categoriesList,
  skipProduct: state => state.skipProduct,
  fastBuyProduct: state => state.fastBuyProduct,
  homeSlider: state => state.homeSlider,
  homeSliderLoading: state => state.homeSliderLoading,
  bannerList: state => state.bannerList,
  bannerListLoading: state => state.bannerListLoading,
  list: state => state.list,
  listLoading: state => state.listLoading,
  skip: state => state.skip,
  limit: state => state.limit,
  listLength: state => state.listLength,
  activeCategory: state => state.activeCategory,
  certificates: state => state.certificates,
  fastBuyRequest: state => state.fastBuyRequest
};

const actions = {
  [GET_CATEGORIES]: async ({commit}) => {
    commit(SET_BUY_REQUEST_SENDING, true);
    try {
      const response = await $http.get(`v1/main_categories?limit=5`);
      commit(SET_CATEGORIES, response.data.data);

    } catch (e) {
      throw e;
    } finally {
      commit(SET_BUY_REQUEST_SENDING, false);
    }
  },
  [GET_CERTIFICATE_LIST]: async ({commit}) => {
    commit(SET_BUY_REQUEST_SENDING, true);
    try {
      const response = await $http.get(`v1/product/certificates`);
      commit(SET_CERTIFICATE_LIST, response.data.data);

    } catch (e) {
      throw e;
    } finally {
      commit(SET_BUY_REQUEST_SENDING, false);
    }
  },
  [GET_HOME_SLIDER]: async ({commit}) => {
    commit(SET_HOME_SLIDER_LOADING, true);
    try {
      const response = await $http.get(`v1/banners`);
      commit(SET_HOME_SLIDER, response.data.data);

    } catch (e) {
      throw e;
    } finally {
      commit(SET_HOME_SLIDER_LOADING, false);
    }
  },
  [GET_BANNER_LIST]: async ({commit}) => {
    commit(SET_BANNER_LIST_LOADING, true);
    try {
      // const response = await $http.get(`v1/images`);
      // commit(SET_BANNER_LIST, response.data);

      //TODO: set normal url to img
      commit(SET_BANNER_LIST, [
        {
          id: 0,
          image: require('../../../src/assets/img/home/MaskGroup-2.jpg'),
          title: 'Для лица',
          category: 'Минералы',
        },
        {
          id: 1,
          image: require('../../../src/assets/img/home/MaskGroup-4.jpg'),
          title: 'Для лица',
          category: 'Минералы',
        },
        {
          id: 2,
          image: require('../../../src/assets/img/home/MaskGroup-4.jpg'),
          title: 'Для лица',
          category: 'Минералы',
        },
        {
          id: 3,
          image: require('../../../src/assets/img/home/MaskGroup-3.jpg'),
          title: 'Для лица',
          category: 'Минералы',
        },
        {
          id: 4,
          image: require('../../../src/assets/img/home/MaskGroup-1.jpg'),
          title: 'Для лица',
          category: 'Минералы',
        }
      ]);
    } catch (e) {
      throw e;
    } finally {
      commit(SET_BANNER_LIST_LOADING, false);
    }
  },
  [GET_PRODUCTS_LIST]: async ({commit}, type) => {
    commit(SET_LOADING_PRODUCTS_LIST, true);
    try {
      commit(CHANGE_SKIP);
      const response = await $http.get(`v1/product/${type}?skip=0&limit=${state.skipProduct}`);
      commit(SET_PRODUCTS_LIST, response.data.data);
      commit(SET_LIST_LENGTH, response.data.data.length);

    } catch (e) {
      throw e;
    } finally {
      commit(SET_LOADING_PRODUCTS_LIST, false);
    }
  },
};

const mutations = {
  [SET_FAST_BUY_PRODUCT](state, status) {
    state.fastBuyProduct = status;
  },
  [SET_BUY_REQUEST_SENDING](state, status) {
    state.fastBuyRequest = status;
  },
  [SET_HOME_SLIDER_LOADING](state, status) {
    state.homeSliderLoading = status;
  },
  [SET_CERTIFICATE_LIST](state, data) {
    state.certificates = data;
  },
  [SET_HOME_SLIDER](state, list) {
    state.homeSlider = list;
  },
  [SET_BANNER_LIST](state, list) {
    state.bannerList = list;
  },
  [SET_BANNER_LIST_LOADING](state, status) {
    state.bannerListLoading = status;
  },
  [CHANGE_SKIP](state) {
    state.skipProduct += state.limitProduct
  },
  [SET_LIST_LENGTH](state, data) {
    state.listLength = data;
  },
  [SET_PRODUCTS_LIST](state, list) {
    state.list = list;
  },
  [SET_LOADING_PRODUCTS_LIST](state, status) {
    state.listLoading = status;
  },
  [CHANGE_CATEGORY](state, category) {
    state.activeCategory = category;
    state.skip = 6;
    state.listLength = 0;
    state.list = null;
  },
  [RESET_PRODUCTS](state) {
    state.list = []
    state.skipProduct = 0
    state.limitProduct = 8
  },
  [SET_CATEGORIES](state, data) {
    state.categoriesList = data
  }
};
export default {
  state,
  getters,
  actions,
  mutations,
};
