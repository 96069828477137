import {$http} from '@/utils/http'
import {objectToEncodedQueryString, arrayToEncodedQueryString} from '@/helpers/variables/index';

import {
    INGREDIENTS_SEARCH_LIST,
    INGREDIENTS_SEARCH_LIST_LOADING,
    INGREDIENT_DATA,
    INGREDIENT_DATA_LOADING,
} from '../mutation-types';

import {
    INGREDIENTS_SEARCH_GET,
    INGREDIENT_DATA_GET,
} from '../action-types';


const state = {
    ingredients: {
        list: null,
        isLoading: false,
        isDone: false,
        error: null,
    },
    ingredient: {
        data: null,
        isLoading: false,
        isDone: false,
        error: null,
    },
};

const getters = {
    ingredientsList: state => state.ingredients.list,
    isLoadingIngredients: state => state.ingredients.isLoading,

    ingredientData: state => state.ingredient.data,
    isLoadingIngredient: state => state.ingredient.isLoading,
};

const actions = {
    [INGREDIENTS_SEARCH_GET]: async ({commit}, array) => {
    if (array.letters.length) {
      commit(INGREDIENTS_SEARCH_LIST_LOADING, true);
      try {
          const query = `${arrayToEncodedQueryString(array.letters, 'letters')}`;
        const response = await $http.get(`v1/library/ingredient/search?${query}&locale=${array.locale}`);
        commit(INGREDIENTS_SEARCH_LIST, response.data.data);
      } catch (e) {
        throw e;
      } finally {
        commit(INGREDIENTS_SEARCH_LIST_LOADING, false);
      }
    } else {
      commit(INGREDIENTS_SEARCH_LIST, null);
    }
  },



    [INGREDIENT_DATA_GET]: async ({commit}, slug) => {
        commit(INGREDIENT_DATA_LOADING, true);
        try {
            const response = await $http.get(`v1/library/ingredient/${slug}`);
            commit(INGREDIENT_DATA, response.data);
        } catch (e) {
            throw e;
        } finally {
            commit(INGREDIENT_DATA_LOADING, false);
        }
    },
};

const mutations = {
    [INGREDIENTS_SEARCH_LIST](state, data) {
        state.ingredients.list = data ? data : null;
    },
    [INGREDIENTS_SEARCH_LIST_LOADING](state, status) {
        state.ingredients.isLoading = status;
    },
    [INGREDIENT_DATA](state, data) {
        state.ingredient.data = data.data ? data.data : null;
    },
    [INGREDIENT_DATA_LOADING](state, status) {
        state.ingredient.isLoading = status;
    },
};
export default {
    state,
    getters,
    actions,
    mutations,
};
