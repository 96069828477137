import {$http} from '@/utils/http'
import store from '@/store';
import {
    SEARCH_LIST_LOADING,
    SET_SEARCH_LIST,
} from '../mutation-types';
import {
    GET_SEARCH_LIST
} from '../action-types';


const state = {
    searchList: null,
    searchLoading: false,
};

const getters = {
    searchList: state => state.searchList,
    searchLoading: state => state.searchLoading,
};

const actions = {
    [GET_SEARCH_LIST]: async ({commit}, payload) => {
        commit(SEARCH_LIST_LOADING, true);
        try {
            const response = await $http.get(`v1/autocomplite?search_text=${payload.key}&include=${payload.include ? payload.include : ''}`);
            commit(SET_SEARCH_LIST, response.data.data);
            let obj = {}
            let arr = []
            for (let i in response.data.data) {
                arr.push(response.data.data[i])
            }
            obj.products = arr
            store.commit(`category/SET_PRODUCTS_LIST`, obj, {root: true});
        } catch (e) {
            throw e;
        } finally {
            commit(SEARCH_LIST_LOADING, false);
        }
    },
};

const mutations = {
    [SET_SEARCH_LIST](state, list) {
        state.searchList = list;
    },
    [SEARCH_LIST_LOADING](state, status) {
        state.searchLoading = status;
    },
};
export default {
    state,
    getters,
    actions,
    mutations,
};
