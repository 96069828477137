import {$http} from '@/utils/http'
import {
    SET_LOADING_FEEDBACK,
    SET_CONTACTS,
    SET_CONTACTS_LOADING,
    SET_LOADING_SUBSCRIBE,
    SET_ADDRESS_PARTNER
} from '../mutation-types';

import {
    SEND_FEEDBACK,
    GET_CONTACTS,
    SUBSCRIBE, GET_ADDRESS_PARTNER
} from '../action-types';


const state = {
    partner: '',
    loadingFeedback: false,
    contacts: null,
    loadingContacts: false,
    loadingSubscribe: false,
};

const getters = {
    partner: state => state.partner,
    loadingFeedback: state => state.loadingFeedback,
    contacts: state => state.contacts,
    loadingContacts: state => state.loadingContacts,
    loadingSubscribe: state => state.loadingSubscribe,
};

const actions = {
    [SUBSCRIBE]: async ({commit}, payload) => {
        commit(SET_LOADING_SUBSCRIBE, true);
        try {
            return await $http.post('v1/subscribe', payload);
        } catch (e) {
            throw e;
        } finally {
            commit(SET_LOADING_SUBSCRIBE, false);
        }
    },
    [SEND_FEEDBACK]: async ({commit}, payload) => {
        commit(SET_LOADING_FEEDBACK, true);
        try {
            return await $http.post('v1/feedback', payload);
        } catch (e) {
            throw e;
        } finally {
            commit(SET_LOADING_FEEDBACK, false);
        }
    },
    [GET_CONTACTS]: async ({commit}, payload) => {
        commit(SET_CONTACTS_LOADING, true);
        try {
            const response = await $http.get('v1/contact-info');
            commit(SET_CONTACTS, response)
        } catch (e) {
            throw e;
        } finally {
            commit(SET_CONTACTS_LOADING, false);
        }
    },
    [GET_ADDRESS_PARTNER]: async ({commit}, payload) => {

        try {
            const response = await $http.get(`v1/partners?state=${payload.state}&city=${payload.city}`);
            commit(SET_ADDRESS_PARTNER, response.data.data)
        } catch (e) {
            throw e;
        } finally {

        }
    },
};

const mutations = {
    [SET_LOADING_FEEDBACK](state, status) {
        state.loadingFeedback = status;
    },
    [SET_CONTACTS](state, data) {
        state.contacts = data;
    },
    [SET_CONTACTS_LOADING](state, status) {
        state.loadingContacts = status;
    },
    [SET_LOADING_SUBSCRIBE](state, status) {
        state.loadingSubscribe = status;
    },
    [SET_ADDRESS_PARTNER](state, data) {
        state.partner = data;
    },
};
export default {
    state,
    getters,
    actions,
    mutations,
};
