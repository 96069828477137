import {$http} from '@/utils/http'

import {
  BEFORE_AFTER_DATA,
  BEFORE_AFTER_DATA_LOADING
} from '../mutation-types';

import {
  BEFORE_AFTER_DATA_GET
} from '../action-types';

const state = {
  data: '',
  isLoading: false,
  isDone: false,
  error: null,
};

const getters = {
  beforeAfterData: state => state.data,
  isLoadingBeforeAfter: state => state.isLoading
};

const actions = {
  [BEFORE_AFTER_DATA_GET]: async ({commit}) => {

    commit(BEFORE_AFTER_DATA_LOADING, true);
    try {
      const response = await $http.get(`v1/library/before_after`);
      commit(BEFORE_AFTER_DATA, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(BEFORE_AFTER_DATA_LOADING, false);
    }
  },
};

const mutations = {
  [BEFORE_AFTER_DATA](state, data) {
    // state.data = data.data ? data.data : null;
    state.data =  data
//     state.data = [
//       {
//         id: 0,
//         title: 'Результат использования косметики DeLav',
//         description: `Вік: 30 років
// Время года: Осень
// Период применения средств: 3 месяца
// Использовали только домашний уход
// Ключевые препараты:
// SERUM VITAMIN C 5%.  Наносили на ночь под крем каждый день.
// PROBIOTIC SERUM. Наносили утром под крем через день.
// SERUM HYALURONIC ACID 1% & MILK PEPTIDE.  Наносили утром под крем через день (при температуре плюс на улице).
// CALMING CREAM MOISTURIZING. Наносили утром и вечером после сыворотки.
// Базовые препараты:
// FOAM SOFT CLEANSER. Очищение утром и вечером.
// GINKGO BILOBA TONIC. Тонизация кожи утром и вечером.
// Процедура системного домашнего ухода от DeLav cosmetics:
// GEL MASK GREEN TEA
// SOFTENING FASE MASK
// Базовый курс состоит из 2 - 3 процедур в неделю.
// Общее время 1 процедуры 30 - 45 минут.
// В одной процедуре используется поочередно 2 маски.`,
//         photos: [
//           {
//             before: 'https://picsum.photos/200/300',
//             after: 'https://picsum.photos/200/300'
//           },
//           {
//             before: 'https://picsum.photos/200/300',
//             after: 'https://picsum.photos/200/300'
//           },
//           {
//             before: 'https://picsum.photos/200/300',
//             after: 'https://picsum.photos/200/300'
//           }
//         ]
//       },
//       {
//         id: 1,
//         title: 'Результат использования косметики DeLav',
//         description: `Вік: 30 років
// Время года: Осень
// Период применения средств: 3 месяца
// Использовали только домашний уход
// Ключевые препараты:
// SERUM VITAMIN C 5%.  Наносили на ночь под крем каждый день.
// PROBIOTIC SERUM. Наносили утром под крем через день.
// SERUM HYALURONIC ACID 1% & MILK PEPTIDE.  Наносили утром под крем через день (при температуре плюс на улице).
// CALMING CREAM MOISTURIZING. Наносили утром и вечером после сыворотки.
// Базовые препараты:
// FOAM SOFT CLEANSER. Очищение утром и вечером.
// GINKGO BILOBA TONIC. Тонизация кожи утром и вечером.
// Процедура системного домашнего ухода от DeLav cosmetics:
// GEL MASK GREEN TEA
// SOFTENING FASE MASK
// Базовый курс состоит из 2 - 3 процедур в неделю.
// Общее время 1 процедуры 30 - 45 минут.
// В одной процедуре используется поочередно 2 маски.`,
//         photos: [
//           {
//             before: 'https://picsum.photos/200/300',
//             after: 'https://picsum.photos/200/300'
//           },
//           {
//             before: 'https://picsum.photos/200/300',
//             after: 'https://picsum.photos/200/300'
//           },
//           {
//             before: 'https://picsum.photos/200/300',
//             after: 'https://picsum.photos/200/300'
//           }
//         ]
//       },
//       {
//         id: 2,
//         title: 'Результат использования косметики DeLav',
//         description: `Вік: 30 років
// Время года: Осень
// Период применения средств: 3 месяца
// Использовали только домашний уход
// Ключевые препараты:
// SERUM VITAMIN C 5%.  Наносили на ночь под крем каждый день.
// PROBIOTIC SERUM. Наносили утром под крем через день.
// SERUM HYALURONIC ACID 1% & MILK PEPTIDE.  Наносили утром под крем через день (при температуре плюс на улице).
// CALMING CREAM MOISTURIZING. Наносили утром и вечером после сыворотки.
// Базовые препараты:
// FOAM SOFT CLEANSER. Очищение утром и вечером.
// GINKGO BILOBA TONIC. Тонизация кожи утром и вечером.
// Процедура системного домашнего ухода от DeLav cosmetics:
// GEL MASK GREEN TEA
// SOFTENING FASE MASK
// Базовый курс состоит из 2 - 3 процедур в неделю.
// Общее время 1 процедуры 30 - 45 минут.
// В одной процедуре используется поочередно 2 маски.`,
//         photos: [
//           {
//             before: 'https://picsum.photos/200/300',
//             after: 'https://picsum.photos/200/300'
//           },
//         ]
//       }
//     ];
  },
  [BEFORE_AFTER_DATA_LOADING](state, status) {
    state.isLoading = status;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
