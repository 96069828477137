import {mapActions, mapGetters, mapMutations} from "vuex";
import {minLength, maxLength, required, email} from "vuelidate/lib/validators";


export default {
  name: 'add-question',
  data() {
    return {
      payload: {
        name: '',
        email: '',
        message: '',
      },
      validationErrors: {},
    }
  },
  validations: {
    payload: {
      email: {
        required,
        email
      },
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(20)
      },
      message: {
        required
      }
    },
  },
  watch: {},
  computed: {
    ...mapGetters({
      isAuth:'auth/isAuthenticated',
      popupQuestion: `card/popupQuestion`
    }),
    nameErrors() {
      let error = [];
      if (!this.$v.payload.name.$dirty) {
        return error;
      }
      if (!this.$v.payload.name.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.name.minLength) {
        error.push(this.$t('validationMin').replace(':count', 3));
      }
      if (!this.$v.payload.name.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 20));
      }
      if (this.validationErrors.name) {
        this.validationErrors.name.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    messageErrors() {
      let error = [];
      if (!this.$v.payload.message.$dirty) {
        return error;
      }
      if (!this.$v.payload.message.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.message) {
        this.validationErrors.message.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    emailErrors() {
      let error = [];
      if (!this.$v.payload.email.$dirty) {
        return error;
      }
      if (!this.$v.payload.email.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.email.email) {
        error.push(this.$t('validationEmailError'));
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
  },
  methods: {
    ...mapActions({
      sendQuestion: 'card/SEND_QUESTION'
    }),
    ...mapMutations({
      changePopupQuestion: 'card/CHANGE_POPUP_QUESTION',
      changePopupRegistration: `card/CHANGE_POPUP_REGISTRATION`,
    }),
    redirectToRegistration(){
      this.changePopupQuestion(false)
      this.changePopupRegistration(true)
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let obj = {};
        obj.name = this.payload.name;
        obj.email = this.payload.email
        obj.question = this.payload.message
        this.sendQuestion({slug: this.$route.params.item, data: obj})
          .then(() => {
            this.$toasted.success(this.$t('successQuestionSend'));
            this.changePopupQuestion(false)
          }).catch(error => {
          if (error.response.status === 418) {
            this.$toasted.error(error.response.data.data.error.message);
          }
          if (error.response.status === 422) {
            this.$toasted.error(error.response.data.message);
          }
        });
      }
    }
  },
  created() {

  },
  destroyed() {

  }
}
