import {$http} from '@/utils/http';
import {translation} from '@/helpers/translation';
import {globalLoader} from '@/helpers/variables/index';
import axios from 'axios';


function save() {
  const parsed = JSON.stringify(state.selectCurrency);
  localStorage.setItem('currency', parsed);
}

import {
  SET_TRANSLATIONS,
  SET_CATALOG_MENU_LOADING,
  SET_CATALOG_MENU,
  SET_LIST_CATEGORY_FILTERS,
  SET_LIST_CATEGORY_FILTERS_LOADING,
  SET_VARIABLES,
  SEND_FEEDBACK_LOADING,
  SET_CURRENCY,
  SET_CURRENT_CURRENCY
} from '../mutation-types';

import {
  GET_TRANSLATIONS,
  GET_CATEGORIES_MENU,
  GET_CATEGORY_FILTERS,
  GET_VARIABLES,
  GET_CURRENCY
} from '../action-types';

const state = {
  menu: null,
  // config: null,
  // configLoading: false,
  // menuLoading: false,
  translations: null,
  filterCategories: null,
  filterCategoriesLoading: false,
  variables: null,
  feedbackLoading: false,
  currentCurrency: localStorage.getItem('currency') === 'USD' ? '$' : localStorage.getItem('currency') === 'EUR' ? '€' : '₴',
  currencyList: [],
  selectCurrency: 'UAH',
  checkedCurrency: localStorage.getItem('currency'),
};

const getters = {
  checkedCurrency: state => state.checkedCurrency,
  selectCurrency: state => state.selectCurrency,
  currencyList: state => state.currencyList,
  filterCategories: state => state.filterCategories,
  filterCategoriesLoading: state => state.filterCategoriesLoading,
  menu: state => state.menu,
  translations: state => state.translations,
  // config: state => state.config,
  // configLoading: state => state.configLoading,
  currentCurrency: state => state.currentCurrency,
  variables: state => state.variables,
  feedbackLoading: state => state.feedbackLoading,
};

const actions = {
  [GET_CURRENCY]: async ({commit}) => {
    try {
      const response = await $http.get('v1/currencies');
      commit(SET_CURRENCY, response.data.data);
    } catch (e) {
      throw e;
    } finally {

    }
  },
  [GET_VARIABLES]: async ({commit}) => {
    // commit(GET_CONFIG_LOADING, true);
    try {
      const response = await $http.get('v1/variables');
      // let result = Object.assign(response.data.data, config);
      commit(SET_VARIABLES, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      // commit(GET_CONFIG_LOADING, false);
    }
  },
  [GET_CATEGORIES_MENU]: async ({commit}) => {
    globalLoader(true);
    commit(SET_CATALOG_MENU_LOADING, true);
    try {
      const response = await $http.get('v1/catalog');
      commit(SET_CATALOG_MENU, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      globalLoader(false);
      commit(SET_CATALOG_MENU_LOADING, false);
    }
  },
  [GET_CATEGORY_FILTERS]: async ({commit}, payload) => {
    commit(SET_LIST_CATEGORY_FILTERS_LOADING, true);
    try {

      const response = await $http.get(`v1/category/${payload.categorySlug}/filters`);
      commit(SET_LIST_CATEGORY_FILTERS, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(SET_LIST_CATEGORY_FILTERS_LOADING, false);
    }
  },
  [GET_TRANSLATIONS]: async ({commit}) => {
    globalLoader(true);
    try {
      const response = await $http.get('v1/translations');
      let result = Object.assign(response.data, translation);
      commit(SET_TRANSLATIONS, result);

      return result;
      // commit(SET_TRANSLATIONS, translation);
      // return translation;
    } catch (e) {
      throw e;
    } finally {
      globalLoader(false);
    }
  },
};

const mutations = {
  [SET_LIST_CATEGORY_FILTERS](state, list) {
    state.filterCategories = list;
  },
  [SET_LIST_CATEGORY_FILTERS_LOADING](state, status) {
    state.filterCategoriesLoading = status;
  },
  [SET_TRANSLATIONS](state, status) {
    state.translations = status;
  },
  [SET_CATALOG_MENU_LOADING](state, status) {
    state.menuLoading = status;
  },
  [SET_CATALOG_MENU](state, config) {
    state.menu = config;
  },
  [SET_VARIABLES](state, data) {
    state.variables = data;
  },
  [SEND_FEEDBACK_LOADING](state, staus) {
    state.feedbackLoading = staus;
  },
  [SET_CURRENCY](state, data) {
    state.currencyList = data
  },
  [SET_CURRENT_CURRENCY](state, data) {
    state.selectCurrency = data
    save()
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
