import {mapMutations} from "vuex";

export default {
  name: "discount-cosmetologist",
  methods:{
    ...mapMutations({
      changePopupDiscountCosmetologist: 'basket/CHANGE_DISCOUNT_COSMETOLOGIST',
    })
  }

}
