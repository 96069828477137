import {mapMutations, mapGetters} from 'vuex';
import addToBasket from '../popups/add-basket/index.vue'
import fastBuy from '../popups/fast-buy/index.vue'
import addComment from '../popups/add-comment/index.vue'
import addQuestion from '../popups/add-question/index.vue'
import addRegistration from '../popups/registration/index.vue'
import addConsultation from '../popups/consultation/index.vue'
import discountCosmetologist from '../popups/info/discount-cosmetologist/index.vue'
import discountBonus from '../popups/info/discount-bonus/index.vue'
import discountOneTime from '../popups/info/discount-one-time/index.vue'
import changeCosmetologist from '../popups/change-cometologist/index.vue'
import changePassword from '../popups/set-new-password/index.vue'
import professionalRecommendations from '../popups/recommendation/index.vue'
import addPromocod from '../popups/add-promocod/index.vue'
import errorPromocode from '../popups/error-promocode/index.vue'
import changePromo from '../popups/change-promo/index.vue'
import generatePromo from '../popups/generate-promo/index.vue'
import thanksPopup from "@/components/popups/thanks-popup/index.vue";
import articleComment from "@/components/popups/add-article-comment/index.vue"
import {CHANGE_RECOMMENDATION_POPUP} from "../../store/mutation-types";

//
export default {
  name: 'hidden',
  data() {
    return {
      doubleTitle: [this.$t('RegistrationInput'), this.$t('Registration')],
      productPromotion: ''
    }
  },
  components: {
    addToBasket,
    fastBuy,
    addComment,
    addQuestion,
    addRegistration,
    addConsultation,
    discountCosmetologist,
    discountBonus,
    discountOneTime,
    changeCosmetologist,
    changePassword,
    professionalRecommendations,
    addPromocod,
    errorPromocode,
    changePromo,
    generatePromo,
    thanksPopup,
    articleComment
  },
  computed: {
    ...mapGetters({
      showPopupAddBasket: 'basket/showPopupAddBasket',
      showPopupDiscountCosmetologist: 'basket/showPopupDiscountCosmetologist',
      showPopupDiscountBonus: 'basket/showPopupDiscountBonus',
      showPopupDiscountOneTime: 'basket/showPopupDiscountOneTime',
      showPopupComment: 'card/popupComment',
      showPopupQuestion: 'card/popupQuestion',
      showPopupFastBuy: 'card/popupFastBuy',
      showPopupRegistration: 'card/popupRegistration',
      showPopupConsultation: 'header/popupConsultation',
      showPopupCosmetologist: 'user/changeCosmetologist',
      showPopupSetNewPassword: 'auth/popupSetNewPassword',
      recommendationPopup: 'profile/recommendationPopup',
      showAddPromo: 'user/showAddPromo',
      showErrorPromo: 'user/showerrorPromo',
      showChangePromo: 'user/showChangePromo',
      generatePromo: 'user/generatePromo',
      showPromotionProduct: 'card/showPromotionProduct',
      isShowPopup: 'order/showThanksPopup',
      isShowDeletePromo: 'profile/showDeletePromo',
      isShowDeleteProfi: 'profile/showDeleteProfi',
      isShowArticleComment: 'articles/showPopup'
    })
  },
  methods: {
    ...mapMutations({
      changePopupComment: 'card/changePopupComment',
      changePopupDiscountCosmetologist: 'basket/CHANGE_DISCOUNT_COSMETOLOGIST',
      changesPopupDiscountBonus: 'basket/CHANGE_DISCOUNT_BONUS',
      changesPopupDiscountOneTime: 'basket/CHANGE_DISCOUNT_ONE_TIME',
      changePopupFastBuy: 'card/CHANGE_POPUP_FAST_BUY',
      changePopupQuestion: 'card/CHANGE_POPUP_QUESTION',
      changePopupRegistration: 'card/CHANGE_POPUP_REGISTRATION',
      changePopupConsultation: 'header/CHANGE_POPUP_CONSULTATION',
      changePopupUserCosmetologist: 'user/CHANGE_POPUP_USER_COSMETOLOGIST',
      changeShowPopupAddBasket: 'basket/CHANGE_POPUP_ADD_BASKET',
      changePopupSetNewPassword: 'auth/CHANGE_POPUP_SET_NEW_PASSWORD',
      changeRecommendationPopup: 'profile/CHANGE_RECOMMENDATION_POPUP',
      changeShowAddPromo: 'user/SHOW_POPUP_ADD_PROMO',
      changeShowErrorPromo: 'user/SHOW_POPUP_ERROR_PROMO',
      changeShowchangePromo: 'user/SHOW_CHANGE_PROMOCODE',
      changeShowGenerate: 'user/SHOW_GENERATE_PROMO',
      changeShowPromotion: 'card/CHANGE_POPUP_PROMOTION',
      showPopup: 'order/CHANGE_SHOW_THANKS_POPUP',
      changeShowPopupDeletePromo: 'profile/CHANGE_SHOW_DELETE_PROMO',
      changeShowPopupDeleteProfi: 'profile/CHANGE_SHOW_DELETE_PROFI',
      showArticleCommentPopup: 'articles/SHOW_ADD_COMMENT_POPUP'
    })
  },

}
