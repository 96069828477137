<template>
  <div class="popup">
    <div class="popup-wrap" >
      <div class="popup-bg" @click="closePopup()"></div>
      <div class="popup-container" :style="{ 'max-width': width + 'px' }">
        <button class="popup-close" @click="closePopup()">
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.3827 6.76917L23.2311 7.61757L7.61793 23.2296L6.76953 22.3818L22.3827 6.76917Z" fill="#262626"/>
            <path d="M7.61793 6.76917L23.2311 22.3812L22.3827 23.2302L6.76953 7.61816L7.61793 6.76917Z" fill="#262626"/>
          </svg>
        </button>
        <div class="popup-top" :class="{'hide_title' : $route.name === 'basket'||$route.name === 'bonus'}">
          <div class="popup-top__title action">
            {{ title }}
          </div>
        </div>
        <div class="popup-content">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'popup-wrap',
  props: ['title','width','show-row','id'],
  methods: {
    closePopup() {
      this.$emit('close-popup');
    }
  }
}
</script>
<style lang="scss">

.popup {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 50;
  overflow-y: auto;

  &-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $color-gray;
    opacity: 0.9;
  }

  &-wrap {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 10;
    overflow-y: auto;
  }

  &-container {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    max-width: 840px;
    background: #fff;
    padding: 25px 0;
    position: relative;
    z-index: 2;
  }

  &-content {

  }

  .hide_title{
    visibility: hidden;
  }

  &-top {
    width: 100%;
    margin-top: 35px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    &:after{
      content: '';
      position: absolute;
      width: 100%;
      height: 0.5px;
      top: 100%;
      background-color: #DCDCDC;
    }
    &__title {
      font: $font-fira;
      font-size: 24px;
      line-height: 29px;
      color: $color-mainBlack;
      display: flex;
      justify-content: center;
      position: relative;
      padding-bottom: 5px;
      &:after{
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        top: 100%;
        background-color: #0E4E39;
        z-index: 2;
      }
    }
  }

  &-close {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 0;
    width: 30px;
    height: 30px;

    svg {
      width: 30px;
      height: 30px;
      color: $color-mainBlack;
    }
  }
}

@media screen and (max-width: 900px) {
  .popup-bg {
    position: fixed;
  }
  .popup-wrap {
    height: 100vh;
    padding-left: 15px;
    padding-right: 15px;
    display: block;
  }
  .popup-wrap {
    overflow-y: auto;
  }
}

@media screen and (max-width: 425px) {
  .popup-container {
    padding: 15px 20px;
  }
  .popup-top__title {
    font-size: 16px;
  }
  .popup-top {
    padding-bottom: 10px;
  }
}
</style>

