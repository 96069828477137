import {$http} from '@/utils/http'
import {objectToEncodedQueryString} from '@/helpers/variables/index';

import {
  VIDEOS_LIST,
  VIDEOS_LIST_LOADING,
  VIDEOS_DATA,
  VIDEOS_DATA_LOADING,
} from '../mutation-types';

import {
  VIDEOS_LIST_GET,
  VIDEOS_DATA_GET,
} from '../action-types';


const state = {
  videos: {
    list: [],
    meta: null,
    isLoading: false,
    isDone: false,
    error: null,
    limit: 6,
  },
  video: {
    data: null,
    isLoading: false,
    isDone: false,
    error: null,
  },
};

const getters = {
  videosList: state => state.videos.list,
  videosLimit: state => state.videos.limit,
  videosMeta: state => state.videos.meta,
  isLoadingVideos: state => state.videos.isLoading,

  videoData: state => state.video.data,
  isLoadingVideo: state => state.video.isLoading,
};

const actions = {
  [VIDEOS_LIST_GET]: async ({commit}, params) => {
    commit(VIDEOS_LIST_LOADING, true);
    try {
      const query = `limit=${state.videos.limit}&${objectToEncodedQueryString(params)}`;
      const response = await $http.get(`v1/library/video?${query}`);
      commit(VIDEOS_LIST, response.data);
    } catch (e) {
      throw e;
    } finally {
      commit(VIDEOS_LIST_LOADING, false);
    }
  },

  [VIDEOS_DATA_GET]: async ({commit}, slug) => {
    commit(VIDEOS_DATA_LOADING, true);
    try {
      const response = await $http.get(`v1/library/video/${slug}`);
      commit(VIDEOS_DATA, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(VIDEOS_DATA_LOADING, false);
    }
  },
};

const mutations = {
  [VIDEOS_LIST](state, data) {
    state.videos.list = data.data ? data.data : [];
    state.videos.meta = data.meta ? data.meta : null;
  },
  [VIDEOS_LIST_LOADING](state, status) {
    state.videos.isLoading = status;
  },
  [VIDEOS_DATA](state, data) {
    state.video.data = data ? data : null;
  },
  [VIDEOS_DATA_LOADING](state, status) {
    state.video.isLoading = status;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
