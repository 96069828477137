import {mapGetters, mapActions, mapMutations} from 'vuex';
import _ from 'lodash';
import {directive as onClickaway} from 'vue-clickaway';

export default {
    name: 'header-search',
    data() {
        return {
            show: false
        }
    },
    components: {
    },
    directives: {
        onClickaway: onClickaway,
    },
    computed: {
        ...mapGetters({
            querySearch: 'search/querySearch',
            resultSearch: 'search/resultHeaderSearch',
            searchLoader: 'search/headerSearchLoader',
            noResultSearchHeader: 'search/noResultSearchHeader',
            searchListLength: 'search/searchListLength',
        })
    },
    watch: {
        '$route'() {
            this.setFocusField(false);
        }
    },
    methods: {
        ...mapActions({
            fetchSearchTooltip: `search/GET_HEADER_SEARCH_TOOLTIP`,
            fetchSearch: `search/GET_SEARCH_LIST`
        }),
        ...mapMutations({
            reset: `search/RESET_HEADER_SEARCH_TOOLTIP`,
            setQuerySearch: `search/SET_QUERY_SEARCH`,
            changeNoResultSearch: `search/SHOW_NOT_RESULT_SEARCH_HEADER`,
            changeLoader: `search/SET_HEADER_SEARCH_LOADER`,
        }),
        sendRequest: _.debounce(function () {
            if (this.querySearch.length > 2) {
                this.fetchSearchTooltip(this.querySearch);
            }
        }, 500),
        changeSearch(query) {
            this.setFocusField(true);
            this.setQuerySearch(query);
            this.changeLoader(true);
            this.changeNoResultSearch(false);
            this.reset();
            this.sendRequest();
        },
        setFocusField(status){
                this.show = status;
        },
        hideHeaderSearch(){
            this.show = false;
        }
    }
}
