//about
export const SET_SHORT_CATEGORY_LIST = 'SET_SHORT_CATEGORY_LIST';
export const SET_SHORT_CATEGORY_LIST_LOADING = 'SET_SHORT_CATEGORY_LIST_LOADING';
export const SET_ABOUT_LIST = 'SET_ABOUT_LIST';
export const SET_ABOUT_LIST_LOADING = 'SET_ABOUT_LIST_LOADING';
export const SET_PRODUCTS_SORT_TYPE = 'SET_PRODUCTS_SORT_TYPE';
export const SET_REPEAT_ORDER = 'SET_REPEAT_ORDER';
export const SET_VARIABLES = 'SET_VARIABLES';
export const SEND_FEEDBACK_LOADING = 'SEND_FEEDBACK_LOADING';
export const CHANGE_ADDRESS = 'CHANGE_ADDRESS';
export const SET_LOADING_SUBSCRIBE = 'SET_LOADING_SUBSCRIBE';
export const CHANGE_RECOMMENDATION_POPUP = 'CHANGE_RECOMMENDATION_POPUP';
export const RESET_FILTERS = 'RESET_FILTERS';
export const SEARCH_LIST_LOADING = 'SEARCH_LIST_LOADING';
export const SAVE_MEDIA_CONTENT = 'SAVE_MEDIA_CONTENT'
export const SET_ABOUT_MEDIA = 'SET_ABOUT_MEDIA'

export const CHANGE_CURRENT_CATEGORY = 'CHANGE_CURRENT_CATEGORY';

//header
export const CHANGE_POPUP_CONSULTATION = 'CHANGE_POPUP_CONSULTATION'

//TODO: DELETE THIS AFTER MAKE AUTH
export const CHANGE_FAKE_USER_AUTH = 'CHANGE_FAKE_USER_AUTH'
export const CHANGE_FAKE_PROF_AUTH = 'CHANGE_FAKE_PROF_AUTH'

//auth
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const CHANGE_POPUP_REGISTER = 'CHANGE_POPUP_REGISTER';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_TOKEN_SOCIAL = 'SET_TOKEN_SOCIAL';
export const REMOVE_TOKEN = 'REMOVE_TOKEN';
export const CHANGE_POPUP_RESTORE_PASSWORD = 'CHANGE_POPUP_RESTORE_PASSWORD';
export const CHANGE_POPUP_SET_NEW_PASSWORD = 'CHANGE_POPUP_SET_NEW_PASSWORD';
export const CHANGE_POPUP_PERSONAL_DATA = 'CHANGE_POPUP_PERSONAL_DATA';
export const SET_LOADING_REGISTRATION = 'SET_LOADING_REGISTRATION';
export const SING_IN_WITH_EMAIL_LOADING = 'SING_IN_WITH_EMAIL_LOADING';
export const SING_IN_WITH_PHONE_LOADING = 'SING_IN_WITH_PHONE_LOADING';
export const SING_IN_WITH_FACEBOOK_LOADING = 'SING_IN_WITH_FACEBOOK_LOADING';
export const SING_IN_WITH_GOOGLE_LOADING = 'SING_IN_WITH_GOOGLE_LOADING';
export const SEND_CODE_LOADING = 'SEND_CODE_LOADING';
export const RESTORE_PASSWORD_LOADING = 'RESTORE_PASSWORD_LOADING';
export const SET_NEW_PASSWORD_LOADING = 'SET_NEW_PASSWORD_LOADING';
export const SET_LOGIN_TOKEN = 'SET_LOGIN_TOKEN'
export const SET_LINK_SOCIAL = 'SET_LINK_SOCIAL'
export const SET_RECOMMENDATIONS = 'SET_RECOMMENDATIONS'
export const SET_CURRENT_RECOMMENDATION = 'SET_CURRENT_RECOMMENDATION'
export const SET_PRICES = 'SET_PRICES'
export const SET_REFERRALS = 'SET_REFERRALS'
export const SET_STATE = 'SET_STATE'
export const SET_PARTNERS = 'SET_PARTNERS'

export const SHOW_GLOBAL_LOADER = 'SHOW_GLOBAL_LOADER';
export const FIX_BODY = 'FIX_BODY';
//settings
export const SET_TRANSLATIONS = 'SET_TRANSLATIONS';
export const SET_CATALOG_MENU_LOADING = 'SET_CATALOG_MENU_LOADING';
export const SET_CATALOG_MENU = 'SET_CATALOG_MENU';
export const SET_LIST_CATEGORY_FILTERS = 'SET_LIST_CATEGORY_FILTERS';
export const SET_LIST_CATEGORY_FILTERS_LOADING = 'SET_LIST_CATEGORY_FILTERS_LOADING';
export const CHANGE_POPUP_NEW_PASSWORD = 'CHANGE_POPUP_NEW_PASSWORD'
//search
export const SET_HEADER_SEARCH = 'SET_HEADER_SEARCH';
export const SET_HEADER_SEARCH_LOADER = 'SET_HEADER_SEARCH_LOADER';
export const SHOW_NOT_RESULT_SEARCH_HEADER = 'SHOW_NOT_RESULT_SEARCH_HEADER';
export const SET_SEARCH_LIST_LOADER = 'SET_SEARCH_LIST_LOADER';
export const SET_SEARCH_LIST = 'SET_SEARCH_LIST';
export const SHOW_NOT_RESULT_SEARCH = 'SHOW_NOT_RESULT_SEARCH';
export const RESET_SEARCH = 'RESET_SEARCH';
export const SEARCH_CHANGE_SKIP = 'SEARCH_CHANGE_SKIP';
export const SEARCH_SET_COUNT = 'SEARCH_SET_COUNT';
export const SET_QUERY_SEARCH = 'SET_QUERY_SEARCH';
export const RESET_HEADER_SEARCH_TOOLTIP = 'RESET_HEADER_SEARCH_TOOLTIP';
export const SET_SEARCH_LENGTH = 'SET_SEARCH_LENGTH';
export const SHOW_POPUP_TEXT_CONTENT = 'SHOW_POPUP_TEXT_CONTENT'

//contacts
export const SET_LOADING_FEEDBACK = 'SET_LOADING_FEEDBACK';
export const SET_CONTACTS = 'SET_CONTACTS';
export const SET_CONTACTS_LOADING = 'SET_CONTACTS_LOADING';
export const SET_ADDRESS_PARTNER = 'SET_ADDRESS_PARTNER'

//system
export const CHANGE_OPEN_HEADER_MENU = 'CHANGE_OPEN_HEADER_MENU';
export const CHANGE_OPEN_FILTER = 'CHANGE_OPEN_FILTER';

//home
export const SET_HOME_SLIDER = 'SET_HOME_SLIDER';
export const SET_HOME_SLIDER_LOADING = 'SET_HOME_SLIDER_LOADING';
export const SET_PRODUCTS_LIST = 'SET_PRODUCTS_LIST';
export const SET_LOADING_PRODUCTS_LIST = 'SET_LOADING_PRODUCTS_LIST';
export const SET_BANNER_LIST = 'SET_BANNER_LIST';
export const SET_BANNER_LIST_LOADING = 'SET_BANNER_LIST_LOADING';
export const SET_DISCOUNT_LIST = 'SET_DISCOUNT_LIST';
export const SET_DISCOUNT_LIST_LOADING = 'SET_DISCOUNT_LIST_LOADING';
export const SET_CLIENTS_SLIDER = 'SET_CLIENTS_SLIDER';
export const GET_CLIENTS_SLIDER_LOADING = 'GET_CLIENTS_SLIDER_LOADING';
export const SET_ARTICLES_LIST = 'SET_ARTICLES_LIST';
export const SET_ARTICLES_LIST_LOADING = 'SET_ARTICLES_LIST_LOADING';
export const SET_ARTICLES_ITEM = 'SET_ARTICLES_ITEM';
export const SET_ARTICLES_ITEM_LOADING = 'SET_ARTICLES_ITEM_LOADING';
export const SET_CERTIFICATE_LIST = 'SET_CERTIFICATE_LIST'
export const SET_BUY_REQUEST_SENDING = 'SET_BUY_REQUEST_SENDING'
export const SET_FAST_BUY_PRODUCT = 'SET_FAST_BUY_PRODUCT'
export const RESET_PRODUCTS = 'RESET_PRODUCTS'
export const SET_CATEGORIES = 'SET_CATEGORIES'

//products
export const DECREMENT_AVAILABILITY_PROPS = 'DECREMENT_AVAILABILITY_PROPS';
export const INCREMENT_AVAILABILITY_PROPS = 'INCREMENT_AVAILABILITY_PROPS';
export const RESET_AVAILABILITY_PROPS = 'RESET_AVAILABILITY_PROPS';
//basket
export const CHANGE_SHOW_BASKET = 'CHANGE_SHOW_BASKET';
export const CHANGE_SHOW_ORDER = 'CHANGE_SHOW_ORDER';
export const ADD_ITEM_TO_BASKET = 'ADD_ITEM_TO_BASKET';
export const CURRENT_ITEM = 'CURRENT_ITEM';
export const REMOVE_ITEM_FROM_BASKET = 'REMOVE_ITEM_FROM_BASKET';
export const RESET_BASKET = 'RESET_BASKET';
export const DECREMENT_BASKET_EDIT_COUNT = 'DECREMENT_BASKET_EDIT_COUNT';
export const INCREMENT_BASKET_EDIT_COUNT = 'INCREMENT_BASKET_EDIT_COUNT';
export const SET_BASKET_EDIT_COUNT = 'SET_BASKET_EDIT_COUNT';
export const INCREMENT_BASKET_LIST_COUNT = 'INCREMENT_BASKET_LIST_COUNT';
export const DECREMENT_BASKET_LIST_COUNT = 'DECREMENT_BASKET_LIST_COUNT';
export const SET_BASKET_LIST_COUNT = 'SET_BASKET_LIST_COUNT';
export const DECREMENT_BASKET_ITEM = 'DECREMENT_BASKET_ITEM';
export const INCREMENT_BASKET_ITEM = 'INCREMENT_BASKET_ITEM';
export const CHANGE_SHOW_CATALOG_MENU = 'CHANGE_SHOW_CATALOG_MENU';
export const CHANGE_SHOW_CATALOG_MENU_MOBILE = 'CHANGE_SHOW_CATALOG_MENU_MOBILE';
export const CREATE_ORDER_LOADING = 'CREATE_ORDER_LOADING';
export const SET_BASKET_LIST_LOADING = 'SET_BASKET_LIST_LOADING';
export const SET_BASKET_LIST = 'SET_BASKET_LIST';
export const SET_BASKET_LENGTH = 'SET_BASKET_LENGTH';
export const ADD_PRODUCT_TO_BASKET_LOADING = 'ADD_PRODUCT_TO_BASKET_LOADING';
export const REMOVE_PRODUCT_FROM_BASKET_LOADING = 'REMOVE_PRODUCT_FROM_BASKET_LOADING';
export const SEND_BASKET_LOADING = 'SEND_BASKET_LOADING';
export const CHANGE_DISCOUNT_COSMETOLOGIST = 'CHANGE_DISCOUNT_COSMETOLOGIST';
export const CHANGE_DISCOUNT_BONUS = 'CHANGE_DISCOUNT_BONUS';
export const CHANGE_DISCOUNT_ONE_TIME = 'CHANGE_DISCOUNT_ONE_TIME';
export const SET_FAST_BUY_LOADING = 'SET_FAST_BUY_LOADING'
export const SET_UPDATE_BASKET = 'SET_UPDATE_BASKET'

//category
export const SET_FILTER_LIST_LENGTH = 'SET_FILTER_LIST_LENGTH';
export const SET_FILTER_PRODUCTS_LIST = 'SET_FILTER_PRODUCTS_LIST';
export const GET_FILTRED_PRODUCTS_LIST = 'GET_FILTRED_PRODUCTS_LIST';
export const SET_LOADING_FILTER_PRODUCTS_LIST = 'SET_LOADING_FILTER_PRODUCTS_LIST';
export const SET_LOADING_ALL_PRODUCTS_LIST = 'SET_LOADING_ALL_PRODUCTS_LIST';
export const SET_CHECKED_FILTERS = 'SET_CHECKED_FILTERS';
export const SET_FILTERS = 'SET_FILTERS';
export const INCREMENT_SKIP = 'INCREMENT_SKIP'
export const RESET_CATEGORY_SKIP = 'RESET_CATEGORY_SKIP'


export const CHANGE_STATUS_FAVORITE = 'CHANGE_STATUS_FAVORITE';

//setting
export const SET_USER_DATA = 'SET_USER_DATA';
export const LOADING_USER_DATA = 'LOADING_USER_DATA';
export const SET_CURRENCY = 'SET_CURRENCY';
export const SET_CURRENT_CURRENCY = 'SET_CURRENT_CURRENCY'

//support
export const SET_SUPPORT_LIST_LOADING = 'SET_SUPPORT_LIST_LOADING';
export const SET_SUPPORT_LIST = 'SET_SUPPORT_LIST';


//profile
export const UPDATE_USER_DATA_LOADING = 'UPDATE_USER_DATA_LOADING';
export const SET_ACTIVE_ORDER_LIST = 'SET_ACTIVE_ORDER_LIST';
export const SET_ACTIVE_ORDER_LIST_LOADING = 'SET_ACTIVE_ORDER_LIST_LOADING';
export const SET_ARCHIVE_ORDER_LIST = 'SET_ARCHIVE_ORDER_LIST';
export const SET_ARCHIVE_ORDER_LIST_LOADING = 'SET_ARCHIVE_ORDER_LIST_LOADING';
export const SET_FAVOURITE_LIST = 'SET_FAVOURITE_LIST';
export const SET_FAVOURITE_LIST_LOADING = 'SET_FAVOURITE_LIST_LOADING';
export const UPDATE_USER_PASSWORD_LOADING = 'UPDATE_USER_PASSWORD_LOADING';
export const ADD_USER_ADDRESS_LOADING = 'ADD_USER_ADDRESS_LOADING';
export const REMOVE_USER_ADDRESS_LOADING = 'REMOVE_USER_ADDRESS_LOADING';
export const EDIT_USER_ADDRESS_LOADING = 'EDIT_USER_ADDRESS_LOADING';
export const ADD_USER_ICON_LOADING = 'ADD_USER_ICON_LOADING';
export const SET_FAVOURITES_LIST_LENGTH = 'SET_FAVOURITES_LIST_LENGTH';
export const FAVOURITES_CHANGE_PAGE = 'FAVOURITES_CHANGE_PAGE';
export const SET_FAVOURITES_LENGTH = 'SET_FAVOURITES_LENGTH';
export const SET_ACTIVE_ORDER_LIST_LENGTH = 'SET_ACTIVE_ORDER_LIST_LENGTH';
export const SET_ARCHIVE_ORDER_LIST_LENGTH = 'SET_ARCHIVE_ORDER_LIST_LENGTH';
export const CHANGE_SHOW_DELETE_PROMO = 'CHANGE_SHOW_DELETE_PROMO'
export const CHANGE_SHOW_DELETE_PROFI = 'CHANGE_SHOW_DELETE_PROFI'

//basket
export const SET_RECOMMENDED_LIST = 'SET_RECOMMENDED_LIST';
export const SET_RECOMMENDED_LIST_LOADING = 'SET_RECOMMENDED_LIST_LOADING';
export const SET_RECOMMENDED_LIST_LENGTH = 'SET_RECOMMENDED_LIST_LENGTH';
// export const SET_FAST_BUY_LOADING = 'SET_FAST_BUY_LOADING';
export const CHANGE_POPUP_ADD_BASKET = 'CHANGE_POPUP_ADD_BASKET';
export const SET_CHECK_DISCOUNT = 'SET_CHECK_DISCOUNT'
export const SET_TOTAL_PRICE = 'SET_TOTAL_PRICE'
export const SET_CHECK_BONUSES = 'SET_CHECK_BONUSES'
export const SET_BONUSES = 'SET_BONUSES'
export const SET_PERCENT_DISCOUNT = 'SET_PERCENT_DISCOUNT'


//favorites
export const SET_FAVORITES_LIST_LOADING = 'SET_FAVORITES_LIST_LOADING';
export const SET_FAVORITES_LIST = 'SET_FAVORITES_LIST';
export const ADD_PRODUCT_TO_FAVORITE_LOADING = 'ADD_PRODUCT_TO_FAVORITE_LOADING';
export const UPDATE_FAVORITES_LIST_AFTER_REMOVE_ITEM = 'UPDATE_FAVORITES_LIST_AFTER_REMOVE_ITEM';
export const REMOVE_PRODUCT_IN_FAVORITE_LOADING = 'REMOVE_PRODUCT_IN_FAVORITE_LOADING';
export const SET_FAVORITES_LIST_LENGTH = 'SET_FAVORITES_LIST_LENGTH';
export const ADD_ITEM_TO_FAVOURITES = 'ADD_ITEM_TO_FAVOURITES';
export const REMOVE_ITEM_FROM_FAVOURITES = 'REMOVE_ITEM_FROM_FAVOURITES';
export const SET_SELECT_FAVOURITES_ITEM = 'SET_SELECT_FAVOURITES_ITEM';

// card
export const SET_PROMO_LIST = 'SET_PROMO_LIST';
export const SET_PROMO_LOADING = 'SET_PROMO_LOADING';
export const SET_NEW_COLLECTION_LIST = 'SET_NEW_COLLECTION_LIST';
export const SET_NEW_COLLECTION_LIST_LOADING = 'SET_NEW_COLLECTION_LIST_LOADING';
export const SET_PRODUCT = 'SET_PRODUCT';
export const CHANGE_PRODUCT_LOADING = 'CHANGE_PRODUCT_LOADING';
export const SET_REVIEWS = 'SET_REVIEWS';
export const CHANGE_REVIEWS_LOADING = 'CHANGE_REVIEWS_LOADING';
export const SET_REVIEWS_LENGTH = 'SET_REVIEWS_LENGTH';
export const SET_LOADING_SEND_REVIEW = 'SET_LOADING_SEND_REVIEW';
export const REVIEWS_CHANGE_PAGE = 'REVIEWS_CHANGE_PAGE';
export const RESET_PRODUCT = 'RESET_PRODUCT';
export const changePopupComment = 'changePopupComment';
export const CHANGE_POPUP_FAST_BUY = 'CHANGE_POPUP_FAST_BUY';
export const CHANGE_POPUP_QUESTION = 'CHANGE_POPUP_QUESTION';
export const CHANGE_POPUP_REGISTRATION = 'CHANGE_POPUP_REGISTRATION';
export const CHANGE_POPUP_PROMOTION = 'CHANGE_POPUP_PROMOTION'


// payments-methods
export const SET_PAYMENT_METHODS_LIST = 'SET_PAYMENT_METHODS_LIST';
export const SET_PAYMENT_METHODS_LIST_LOADING = 'SET_PAYMENT_METHODS_LIST_LOADING';

// delivery-methods
export const SET_DELIVERY_METHODS_LIST = 'SET_DELIVERY_METHODS_LIST';
export const SET_DELIVERY_METHODS_LIST_LOADING = 'SET_DELIVERY_METHODS_LIST_LOADING';

// List
export const CHANGE_SKIP = 'CHANGE_SKIP';
export const SET_LIST_LENGTH = 'SET_LIST_LENGTH';
export const SET_BREADCRUMBS = 'SET_BREADCRUMBS';
export const SET_PRODUCTS_ITEM = 'SET_PRODUCTS_ITEM';
export const SET_LOADING_PRODUCTS_ITEM = 'SET_LOADING_PRODUCTS_ITEM';
export const CHANGE_CATEGORY = 'CHANGE_CATEGORY';
export const SET_PROMO_LIST_LENGTH = 'SET_PROMO_LIST_LENGTH';
export const SET_NEW_LIST_LENGTH = 'SET_NEW_LIST_LENGTH';
export const PRODUCTS_CHANGE_PAGE = 'PRODUCTS_CHANGE_PAGE';
export const CHANGE_SKIP_MOBILE = 'CHANGE_SKIP_MOBILE';
export const SET_MOBILE_PRODUCTS_LIST = 'SET_MOBILE_PRODUCTS_LIST';
export const SET_MOBILE_LIST_LENGTH = 'SET_MOBILE_LIST_LENGTH';
export const SET_MOBILE_LOADING_PRODUCTS_LIST = 'SET_MOBILE_LOADING_PRODUCTS_LIST';

// articles
export const SET_RANDOM_ARTICLES_LIST_LOADING = 'SET_RANDOM_ARTICLES_LIST_LOADING';
export const SET_RANDOM_ARTICLES_LIST = 'SET_RANDOM_ARTICLES_LIST';
export const SET_RANDOM_LIST_LENGTH = 'SET_RANDOM_LIST_LENGTH';
export const SHOW_ADD_COMMENT_POPUP = 'SHOW_ADD_COMMENT_POPUP'
export const ARTICLES_COMMENT_LOADING = 'ARTICLES_COMMENT_LOADING'
export const SET_ARTICLE_ID = 'SET_ARTICLE_ID'

// order
export const CHANGE_USER_ADDRESS = 'CHANGE_USER_ADDRESS';
export const RESET_USER_ADDRESS = 'RESET_USER_ADDRESS';
export const NOVA_POSHTA_GET_DEPARTMENS_LOADING = 'NOVA_POSHTA_GET_DEPARTMENS_LOADING';
export const NOVA_POSHTA_GET_TOWNS_LOADING = 'NOVA_POSHTA_GET_TOWNS_LOADING';
export const NOVA_POSHTA_SET_DEPARTMENS = 'NOVA_POSHTA_SET_DEPARTMENS';
export const NOVA_POSHTA_SET_TOWNS = 'NOVA_POSHTA_SET_TOWNS';
export const CHANGE_USER_INFO = 'CHANGE_USER_INFO';
export const RESET_USER_INFO = 'RESET_USER_INFO';
export const SET_INVOICE = 'SET_INVOICE';
export const CHANGE_INVOICE_LOADING = 'CHANGE_INVOICE_LOADING';
export const SET_TRANSACTION = 'SET_TRANSACTION';
export const CHANGE_TRANSACTION_LOADING = 'CHANGE_TRANSACTION_LOADING';
export const RESET_PAYMENT = 'RESET_PAYMENT';
export const SET_ORDER = 'SET_ORDER';
export const CHANGE_POPUP_CONFIRM_PAYMENT = 'CHANGE_POPUP_CONFIRM_PAYMENT';
export const CERTIFICATE_CHECK_LOADING = 'CERTIFICATE_CHECK_LOADING';
export const SET_CERTIFICATE_CHECK = 'SET_CERTIFICATE_CHECK'
export const CHANGE_SHOW_THANKS_POPUP = 'CHANGE_SHOW_THANKS_POPUP'
export const SET_DELIVERY_TYPES = 'SET_DELIVERY_TYPES'

//---faq
export const FAQ_LIST = 'FAQ_LIST';
export const FAQ_LIST_LOADING = 'FAQ_LIST_LOADING';

//---blog
export const BLOG_DATA = 'BLOG_DATA';
export const BLOG_DATA_LOADING = 'BLOG_DATA_LOADING';

//---videos
export const VIDEOS_LIST = 'VIDEOS_LIST';
export const VIDEOS_LIST_LOADING = 'VIDEOS_LIST_LOADING';
export const VIDEOS_DATA = 'VIDEOS_DATA';
export const VIDEOS_DATA_LOADING = 'VIDEOS_DATA_LOADING';

//---master-classes
export const MASTERCLASS_LIST = 'MASTERCLASS_LIST';
export const MASTERCLASS_LIST_LOADING = 'MASTERCLASS_LIST_LOADING';
export const MASTERCLASS_DATA = 'MASTERCLASS_DATA';
export const MASTERCLASS_DATA_LOADING = 'MASTERCLASS_DATA_LOADING';
export const CONSULTATION_LOADING = 'CONSULTATION_LOADING'

//---articles
export const ARTICLES_LIST = 'ARTICLES_LIST';
export const ARTICLES_LIST_LOADING = 'ARTICLES_LIST_LOADING';
export const ARTICLES_DATA = 'ARTICLES_DATA';
export const ARTICLES_DATA_LOADING = 'ARTICLES_DATA_LOADING';

//---recommendations
export const RECOMMENDATIONS_LIST = 'RECOMMENDATIONS_LIST';
export const RECOMMENDATIONS_LIST_LOADING = 'RECOMMENDATIONS_LIST_LOADING';
export const RECOMMENDATIONS_DATA = 'RECOMMENDATIONS_DATA';
export const RECOMMENDATIONS_DATA_LOADING = 'RECOMMENDATIONS_DATA_LOADING';

//---before-after
export const BEFORE_AFTER_DATA = 'BEFORE_AFTER_DATA';
export const BEFORE_AFTER_DATA_LOADING = 'BEFORE_AFTER_DATA_LOADING';

//---ingredient
export const INGREDIENTS_SEARCH_LIST = 'INGREDIENTS_SEARCH_LIST';
export const INGREDIENTS_SEARCH_LIST_LOADING = 'INGREDIENTS_SEARCH_LIST_LOADING';
export const INGREDIENT_DATA = 'INGREDIENT_DATA';
export const INGREDIENT_DATA_LOADING = 'INGREDIENT_DATA_LOADING';

//---about-us
export const ABOUT_STATIC_LINKS = 'ABOUT_STATIC_LINKS';
export const ABOUT_STATIC_LINKS_LOADING = 'ABOUT_STATIC_LINKS_LOADING';
export const ABOUT_STATIC_LIST = 'ABOUT_STATIC_LIST';
export const ABOUT_STATIC_LIST_LOADING = 'ABOUT_STATIC_LIST_LOADING';
export const ABOUT_MEDIA = 'ABOUT_MEDIA';
export const ABOUT_MEDIA_LOADING = 'ABOUT_MEDIA_LOADING';

//user
export const CHANGE_POPUP_USER_COSMETOLOGIST = 'CHANGE_POPUP_USER_COSMETOLOGIST';
export const SET_COUNTRIES = 'SET_COUNTRIES';
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const SET_DATE_BONUSES = 'SET_DATE_BONUSES'
export const GET_TOWNS = 'GET_TOWNS';
export const SET_TOWNS = 'SET_TOWNS';
export const SET_CURRENT_ORDERS = 'SET_CURRENT_ORDERS';
export const SET_TEST = 'SET_TEST'
export const SET_PROMO = 'SET_PROMO'
export const SET_SAVE_TEST = 'SET_SAVE_TEST'
export const SHOW_POPUP_ADD_PROMO = 'SHOW_POPUP_ADD_PROMO'
export const SHOW_POPUP_ERROR_PROMO = 'SHOW_POPUP_ERROR_PROMO'
export const SHOW_CHANGE_PROMOCODE = 'SHOW_CHANGE_PROMOCODE'
export const SHOW_GENERATE_PROMO = 'SHOW_GENERATE_PROMO'




