import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: 'thanks-popup',
  data() {
    return {}
  },
  props: {
    title: {
      type: String,
      default: () => ''
    },
    mainTitle:{
      type: String,
      default: () => ''
    },
    buttonTitle:{
      type: String,
      default: () => ''
    }
  },
  watch: {},
  computed: {
    ...mapGetters({
      isShowPopup: 'order/showThanksPopup'
    }),
  },
  methods: {
    ...mapActions({}),
    ...mapMutations({
      showPopup: 'order/CHANGE_SHOW_THANKS_POPUP'
    }),
    changeEvent(item) {
      this.$emit('close-event', item)
    },
  },
  created() {

  },
  destroyed() {

  }
}
