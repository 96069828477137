import {mapActions, mapGetters, mapMutations} from "vuex";

import breadcrumb from './atoms/breadcrumb/index.vue'

export default {
  name: 'breadcrumbs',
  components: {
    breadcrumb
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapActions({}),
    ...mapMutations({}),
  }
}
