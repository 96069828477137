import {fixBody, globalLoader} from '@/helpers/variables/index';
import {$http} from '@/utils/http';

import {
  LOADING_USER_DATA,
  SET_ACTIVE_ORDER_LIST,
  SET_ACTIVE_ORDER_LIST_LOADING,
  SET_ARCHIVE_ORDER_LIST,
  SET_ARCHIVE_ORDER_LIST_LOADING,
  SET_USER_DATA,
  UPDATE_USER_DATA_LOADING,
  UPDATE_USER_PASSWORD_LOADING,
  ADD_USER_ADDRESS_LOADING,
  REMOVE_USER_ADDRESS_LOADING,
  EDIT_USER_ADDRESS_LOADING,
  ADD_USER_ICON_LOADING,
  SET_ACTIVE_ORDER_LIST_LENGTH,
  SET_ARCHIVE_ORDER_LIST_LENGTH,
  CHANGE_RECOMMENDATION_POPUP,
  SET_FAVOURITES_LENGTH, CHANGE_SHOW_DELETE_PROMO, CHANGE_SHOW_DELETE_PROFI,
} from '../mutation-types';

import {
  ADD_USER_ICON,
  ADD_USER_ADDRESS,
  REMOVE_USER_ADDRESS,
  EDIT_USER_ADDRESS,
  FETCH_USER_DATA,
  GET_ACTIVE_ORDER_LIST,
  GET_ARCHIVE_ORDER_LIST,
  UPDATE_USER_DATA,
  UPDATE_USER_PASSWORD,
  LINK_WITH_GOOGLE,
  LINK_WITH_FACEBOOK,
  UNLINK_WITH_GOOGLE,
  UNLINK_WITH_FACEBOOK, CHANGE_PROFESSIONAL,
} from '../action-types';

const state = {
  countriesList: null,
  loadingCountriesList: false,
  regionsList: null,
  loadingRegionsList: false,
  user: null,
  loadingUser: false,
  updateUserLoading: false,
  updatePasswordLoading: false,
  activeOrderList: null,
  activeOrderListLength: null,
  activeOrderListLoading: false,
  archiveOrderList: null,
  archiveOrderListLength: null,
  archiveOrderListLoading: false,
  favouriteList: null,
  favouriteListLoading: false,
  addAddressLoading: false,
  removeAddressLoading: false,
  editAddressLoading: false,
  addUserIconLoading: false,
  popupAddAddress: false,
  recommendationPopup: false,
  openPopUpAddressChange: {
    show: false,
    id: null,
  },
  agentClients: [],
  editProfileData: {
    show: false,
    name: '',
    second_name: '',
    last_name: '',
    phone: '',
    email: '',
    birthday: ''
  },
  orders: null,
  ordersLoading: false,
  showDeletePromo: false,
  showDeleteProfi: false
};
//
const getters = {
  showDeleteProfi: state => state.showDeleteProfi,
  showDeletePromo: state => state.showDeletePromo,
  editProfileData: state => state.editProfileData,
  agentClients: state => state.agentClients,
  updatePasswordLoading: state => state.updatePasswordLoading,
  openPopUpAddressChange: state => state.openPopUpAddressChange,
  user: state => state.user,
  loadingUser: state => state.loadingUser,
  updateUserLoading: state => state.updateUserLoading,
  activeOrderList: state => state.activeOrderList,
  activeOrderListLength: state => state.activeOrderListLength,
  activeOrderListLoading: state => state.activeOrderListLoading,
  archiveOrderList: state => state.archiveOrderList,
  archiveOrderListLength: state => state.archiveOrderListLength,
  archiveOrderListLoading: state => state.archiveOrderListLoading,
  favouriteList: state => state.favouriteList,
  favouriteListLoading: state => state.favouriteListLoading,
  addAddressLoading: state => state.addAddressLoading,
  removeAddressLoading: state => state.removeAddressLoading,
  editAddressLoading: state => state.editAddressLoading,
  addUserIconLoading: state => state.addUserIconLoading,
  popupAddAddress: state => state.popupAddAddress,
  countriesList: state => state.countriesList,
  loadingCountriesList: state => state.loadingCountriesList,
  regionsList: state => state.regionsList,
  loadingRegionsList: state => state.loadingRegionsList,
  orders: state => state.orders,
  ordersLoading: state => state.ordersLoading,
  recommendationPopup: state => state.recommendationPopup,
};

const actions = {
  [CHANGE_PROFESSIONAL]: async ({commit}, payload) => {
    try {
      commit(UPDATE_USER_DATA_LOADING, true);
      const response = await $http.post('v1/change_request', payload);
      return response.data.data
    } catch (e) {
      throw e;
    } finally {
      commit(UPDATE_USER_DATA_LOADING, false);
    }
  },
  [FETCH_USER_DATA]: async ({commit}) => {
    try {
      commit(LOADING_USER_DATA, true);
      const response = await $http.get('v1/auth/me');
      commit(SET_USER_DATA, response.data.data);
      return response.data.data
    } catch (e) {
      throw e;
    } finally {
      commit(LOADING_USER_DATA, false);
    }
  },
  [UPDATE_USER_DATA]: async ({commit}, payload) => {
    try {
      commit(UPDATE_USER_DATA_LOADING, true);
      const response = await $http.post('v1/auth/edit/info', payload);
      return response.data.data
    } catch (e) {
      throw e;
    } finally {
      commit(UPDATE_USER_DATA_LOADING, false);
    }
  },
  [UPDATE_USER_PASSWORD]: async ({commit}, payload) => {
    try {
      commit(UPDATE_USER_PASSWORD_LOADING, true);
      return await $http.post('v1/auth/password/change', payload);
    } catch (e) {
      // console.log(e);
      throw e;
    } finally {
      commit(UPDATE_USER_PASSWORD_LOADING, false);
    }
  },
  [LINK_WITH_GOOGLE]: async ({commit}, payload) => {
    try {
      const result = await $http.post('v1/auth/connect/google', payload);
    } catch (e) {
      throw e;
    } finally {
    }
  },
  [LINK_WITH_FACEBOOK]: async ({commit}, payload) => {
    try {
      const result = await $http.post('v1/auth/connect/facebook', payload);
    } catch (e) {
      throw e;
    } finally {
    }
  },
  [UNLINK_WITH_GOOGLE]: async ({commit}, payload) => {
    globalLoader(true);
    try {
      const result = await $http.post('v1/auth/delete/google');
    } catch (e) {
      throw e;
    } finally {
      globalLoader(false);
    }
  },
  [UNLINK_WITH_FACEBOOK]: async ({commit}, payload) => {
    globalLoader(true);
    try {
      const result = await $http.post('v1/auth/delete/facebook');
    } catch (e) {
      throw e;
    } finally {
      globalLoader(false);
    }
  },
  [GET_ACTIVE_ORDER_LIST]: async ({commit}) => {
    commit(SET_ACTIVE_ORDER_LIST_LOADING, true);
    try {
      const response = await $http.get(`v1/user/active-orders`);
      commit(SET_ACTIVE_ORDER_LIST, response.data.data);
      commit(SET_ACTIVE_ORDER_LIST_LENGTH, response.data.data.length);
      // console.log(response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(SET_ACTIVE_ORDER_LIST_LOADING, false);
    }
  },
  [GET_ARCHIVE_ORDER_LIST]: async ({commit}) => {
    commit(SET_ARCHIVE_ORDER_LIST_LOADING, true);
    try {
      const response = await $http.get(`v1/user/archive`);
      commit(SET_ARCHIVE_ORDER_LIST, response.data.data);
      commit(SET_ARCHIVE_ORDER_LIST_LENGTH, response.data.data.length);
    } catch (e) {
      throw e
    } finally {
      commit(SET_ARCHIVE_ORDER_LIST_LOADING, false);
    }
  },

  [ADD_USER_ADDRESS]: async ({commshowDeletePromoit}, payload) => {
    try {
      commit(ADD_USER_ADDRESS_LOADING, true);
      return await $http.post('v1/address/create', payload);
    } catch (e) {
      throw e;
    } finally {
      commit(ADD_USER_ADDRESS_LOADING, false);
    }
  },
  [REMOVE_USER_ADDRESS]: async ({commit}, payload) => {
    try {
      commit(REMOVE_USER_ADDRESS_LOADING, true);
      return await $http.delete(`v1/address/delete/${payload.id}?type=${payload.type}`);
    } catch (e) {
      throw e;
    } finally {
      commit(REMOVE_USER_ADDRESS_LOADING, false);
    }
  },
  [EDIT_USER_ADDRESS]: async ({commit}, payload) => {
    try {
      commit(EDIT_USER_ADDRESS_LOADING, true);
      return await $http.post(`v1/address/update/${payload.id}`, payload.data);
    } catch (e) {
      throw e;
    } finally {
      commit(EDIT_USER_ADDRESS_LOADING, false);
    }
  },
};
//
const mutations = {
  [SET_USER_DATA](state, data) {
    state.user = null
    state.user = data;
  },
  [LOADING_USER_DATA](state, status) {
    state.loadingUser = status;
  },
  [UPDATE_USER_DATA_LOADING](state, status) {
    state.updateUserLoading = status;
  },
  [SET_ACTIVE_ORDER_LIST](state, list) {
    state.activeOrderList = list;
  },
  [SET_ACTIVE_ORDER_LIST_LOADING](state, status) {
    state.activeOrderListLoading = status;
  },
  [SET_ARCHIVE_ORDER_LIST](state, list) {
    state.archiveOrderList = list;
  },
  [SET_ARCHIVE_ORDER_LIST_LOADING](state, status) {
    state.archiveOrderListLoading = status;
  },
  [UPDATE_USER_PASSWORD_LOADING](state, status) {
    state.updatePasswordLoading = status;
  },
  [ADD_USER_ADDRESS_LOADING](state, status) {
    state.addAddressLoading = status;
  },
  [REMOVE_USER_ADDRESS_LOADING](state, status) {
    state.removeAddressLoading = status;
  },
  [EDIT_USER_ADDRESS_LOADING](state, status) {
    state.editAddressLoading = status;
  },
  [ADD_USER_ICON_LOADING](state, status) {
    state.addUserIconLoading = status;
  },
  [SET_ACTIVE_ORDER_LIST_LENGTH](state, data) {
    state.activeOrderListLength = data;
  },
  [SET_ARCHIVE_ORDER_LIST_LENGTH](state, data) {
    state.archiveOrderListLength = data;
  },
  [CHANGE_RECOMMENDATION_POPUP](state, data) {
    state.recommendationPopup = data;
  },
  [CHANGE_SHOW_DELETE_PROMO](state, status) {
    state.showDeletePromo = status
  },
  [CHANGE_SHOW_DELETE_PROFI](state, status) {
    state.showDeleteProfi = status
  }
};
export default {
  state,
  getters,
  actions,
  mutations,
};
