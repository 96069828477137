import {$http} from '@/utils/http';
import {fixBody} from '@/helpers/variables/index';
import {includes} from 'lodash'

import {
  CHANGE_POPUP_CONSULTATION,
  CHANGE_FAKE_USER_AUTH,
  CHANGE_FAKE_PROF_AUTH,
} from '../mutation-types';

import {
  GET_NEW_COLLECTION_LIST,
  GET_PRODUCT,
  GET_PRODUCT_REVIEWS,
  GET_PROMO_LIST,
  SEND_REVIEW
} from '../action-types';

const state = {
  popupConsultation: false,
  userFakeAuth: localStorage.getItem('fakeAuth') ? JSON.parse(localStorage.getItem('fakeAuth')) : [],
  profFakeAuth: localStorage.getItem('profFakeAuth') ? JSON.parse(localStorage.getItem('profFakeAuth')) : [],

};
//
const getters = {
  popupConsultation: state => state.popupConsultation,
  userFakeAuth: state => state.userFakeAuth,
  profFakeAuth: state => state.profFakeAuth,
};
//
const actions = {
  // [GET_PRODUCT]: async ({commit}, payload) => {
  //   commit(CHANGE_PRODUCT_LOADING, true);
  //   // commit(RESET_PRODUCT);
  //   try {
  //     const response = await $http.get(`v1/product/${payload.slug}`);
  //     commit(SET_PRODUCT, response.data.data);
  //   } catch (e) {
  //     throw e
  //   } finally {
  //     commit(CHANGE_PRODUCT_LOADING, false);
  //   }
  // },
};

const mutations = {
    [CHANGE_POPUP_CONSULTATION](state, params) {
      state.popupConsultation = params;
      fixBody(params);
    },
    [CHANGE_FAKE_USER_AUTH](state, params) {
        state.userFakeAuth = params;
    },
    [CHANGE_FAKE_PROF_AUTH](state, params) {
        state.profFakeAuth = params;
    },

};
export default {
  state,
  getters,
  actions,
  mutations,
};
