export const translation = {
  // buyNow: 'купити зараз',
  // popularProduct: 'Популярні продукти',
  // bestSellers: 'Бестселери',
  // noProductNow: 'Поки немає данних',
  // showMore: 'Показати більше',
  // noData: 'Данних немає',
  // sortRating: 'По рейтингу',
  // catalogProduct: 'Каталог продуктів',
  // home: 'Головна',
  // discountClub: 'Дисконт клуб',
  // searchInCatalog: 'Пошук по каталогу',
  // itemAddToBasket: 'Товар добавлен в корзину',
  // send: 'Відправити повідомлення',
  //
  //
  // //Rus
  // CommentEnterName: 'Імя',
  // CommentEnterSurname: 'Прізвище',
  // CommentEnterPatro: 'По батькові',
  // CommentEnterEmail: 'E-mail',
  // CommentEnter: 'Комментарій',
  // CommentConfirm: 'Додати відгук',
  //
  // QuestionEnter: 'Ваше питання',
  // QuestionConfirm: 'Задати питання',
  // QuestionWrite: 'Напишіть ваше питання',
  //
  // RegistrationInput: 'Вхід',
  // RegistrationIsInput: 'Війти',
  // RegistrationBackToLogin: 'Повернутись до авторизації',
  // Registration: 'Реестрація',
  // RegistrationSendMessage: 'Відправити лист',
  // RegistrationIs: 'Зарееструватися',
  // FirstIncome: 'Вперше на сайті?',
  // IsRegistration: 'Вже зареестровані?',
  // RegistrationInputAccount: 'Війти в аккаунт',
  // PasswordRestore: 'Восстановіть пароль',
  // loginSuccess: 'Авторизація пройшла успішно',
  //
  // RegistrationOr: 'або',
  // SaveMe: 'Запомнити мене',
  // ResetMe: 'Забули пароль?',
  //
  // professionalRegistration: 'Для професіоналів',
  // professionalRegistrationUpload: 'Фото диплом/сертификаты',
  // registrationForProfessional: 'Реестрація професіонала',
  //
  //
  // country: 'Країна',
  // area: 'Область',
  // city: 'Город',
  // address: 'Адрес работи, назва',
  //
  // infoPolitic: 'Регистрируясь вы соглашаетесь с',
  // attachPhoto: 'Прикрепите фото',
  // deliveryUa: 'Доставка',
  // consultationTitle: 'Запись на бесплатную консультацию',
  // consultationComment: 'Комментарий',
  // consultationConfirm: 'Отправить',
  // partners: 'Партеры и косметологи',
  // partnersTable: 'Таблица партнеров',
  // shippingPayment: 'Доставка и оплата',
  // oneTimeDiscount: 'Разовая скидка',
  //
  // paymentSum: 'Сумма заказа',
  // paymentPresent: 'Скидка:',
  // paymentPresentAdd: 'Накопительные бонусы:',
  // paymentDescription: 'Описание',
  //
  //
  // goToShoppingCart: 'Перейти в корзину',
  // goShopping: 'Продолжить покупки',
  //
  // basket: 'Корзина',
  // inStock: 'В наличии',
  // order: 'Оформление заказа',
  //
  // profile: 'Особистий кабінет',
  // street: 'Улица',
  // homeNumber:'Дом',
  // room:'Квартира',
  // deliveryTime:'Время доставки',
  //
  // personalData:'Личные данные',
  // bonus:'Мой бонусный счет',
  // recommendations:'Рекомендации косметолога',
  // questionary:'Анкета',
  // CosmetologistPromoCode :'Промокод косметолога',
  // currentOrders: 'Поточні замовлення',
  //
  //
  //
  // mailing:'Рассылки',
  // favorites:'Обране',
  //
  // historyOrders:'Історія замовлень',
  //
  // successDeletedFavourites:'Продукт видалений з обраного',
  // successAuth:"Авторизовано",
  // validationMax:'Максимальна кількість символів :count',
  // validationRequired:'Обовязкове поле',
  // validationMin:'Мінімальна кількість символів :count',
  // successCreateReview:'Відгук відправлено на перевірку',
  // successAddToCard:'Продукт добавлено в корзину',
  // validationEmailError:'Введіть правильно пошту',
  // validationEmail:'Введіть правильно пошту',
  // successAddFeedback:'Повідомлення відправлено',
  // successAddFavourites:'Товар додано до обраного',
  // validationSameAs:'Паролі не співпадають',
  // emailAlreadyTaken:'Така пошта вже зареестрована',
  // successChangeUserData:'Інформація Успішно змінена',
  // successCreateAddress:'Адрес успішно створено',
  // successSetNewMainAddress:'Основний адрес успішно змінено',
  // successDeletedAddress:'Адрес успішно видалено',
  // successAddBasket:'Товар добавлено в корзину',
  // successCreateOrder:'Замовлення створено',




  oneTimeDiscountText:'На товары, участвующие в акциях (специально отмеченные), разовая скидка не применяется.',
  conditionsDiscount:'Умови участі в дисконт клубі',
  cosmetologistDiscountText:'Сумма указанная в подарочном сертификате используется единожды и не делится на две суммы',
  // cosmetologistBonusText:'Бонуси дорівнюють величині накопичувальної знижки від суми закуплених товарів, протягом 90 календарних днів. Через 90 днів після замовлення розраховується відсоток знижки. 1 Бонус = 1 Гривня. Після нарахування бонуси зберігаються 90 днів, потім невикористані бонуси анулюються. Бонусами можна оплатити не більше 30% від суми замовлення.',
  //
  // registrationSuccess:'Реєстрація пройшла успішно',
  // failLoadFiles:'Завантажте фото сертифіката або диплома',
  // successCreateProfessional:'Профіль успішно створено, очікуйте підтвердження акаунта',
  // addAddressButton:'Додати адресу',
  // cancelButton:'Відмінити',
  // save:'Зберегти',
  // password:'Пароль',
  // passwordConfirmation:'Повторіть пароль',
  // confirmPassword:'Підтвердження пароля',
  // registerPolitic:'Реєструючись ви погоджуєтеся з',
  // registerPoliticRed:'політикою конфіденційності',
  // registerPolitic2:'При реєстрації ваші дані можуть бути передані третім особам',
  resetPasswordText:'Введіть email, який ви використовували при реєстрації. На нього прийде лист з подальшими вказівками',
  // contactData:'Контактні дані',
  // typeDelivery:'Спосіб доставки',
  // deliveryData:'Дані для доставки',
  // paymentType:'Спосіб оплати',
  // contactDataRecipient:'Контактні данні отримувача',
  // orderRules:'Отримання замовлення від 5000 ₴ тільки за паспортом (Закон від 06.12.2019 № 361-IX)',
  // whoRecipient:'Хто одержувач замовлення?',
  // orderMessage:'Додаткова інформація',
  // successChangePassword:'Пароль успішно змінено',
  // noneOrders:'Немає замовлень',
  // addToBasket:'Додати в корзину',
  // description:'Опис',
  // typeToUse:'Спосіб застосування',
  // components:'Склад',
  // recommendations:'Рекомендації',
  // questionsAnswer:'Питання відповідь',
  // feedback:'Відгуки',
  // questionsAlwaysAsk:'ЧАСТО ЗАДАВАНІ ПИТАННЯ',
  // question:'ПИТАННЯ',
  // answer:'ВІДПОВІДЬ',
  // askQuestion:'Задати питання',
  // createComment:'Залишити відгук',
  // showMore:'Показати більше',
  // recommendProducts:'З цим продуктом також рекомендується',
  // completeForm:'Заповніть форму, щоб задати питання до товару',
  // agreeWithPolitic:'Відправляючи заявку ви погоджуєтеся з',
  // regularCustomer:'Я постійний покупець',
  // changePassword:'Змінити пароль',
  // sendComment:'Залишити відгук',
  // productAddToBasket:'Товар доданий в кошик',
  // successQuestionSend:'Питання відправлено',
  // sumOrder:'Сума замовлення',
  // totalSum:'Всьго до сплати',
  // continueBuy:'Продовжити покупки',
  // makeOrder:'Оформити замовлення',
  // product:'Товар',
  // price:'Ціна',
  // count:'Кількість',
  // sum:'Сумма',
  // basketEmpty:'Корзина пуста',
  // instructionSendOnEmail:'На вашу пошту буде відправлено лист з активацією',
  // cancelChangePassword:'Скасувати зміну пароля',
  // yourOrder:'Ваше замовлення',
  // edit:'повернутись в корзину',
  // free:'безкоштовно',
  // presentCertificate:'ПОДАРУНКОВИЙ СЕРТИФІКАТ',
  // dontCallMe:'Мені не потрібно передзвонювати. Я впевнена (ий) в замовленні',
  // orderPolitica:'Оформляючи замовлення ви погоджуєтеся з',
  // confirmOrder:'Підтвердити замовлення',
  // pickupFromStore:'Самовивіз з нашого магазину за адресою:',
  // pickupFromStoreDescription:'м. Київ, проспект Перемоги 77а\n' +
  //   'Пн.-Пт. с 10.00 до 18.00\n',
  // deliveryNp:'Самовивіз з відділення Нової Пошти:',
  // deliveryNpDescription:'Безкоштовно при замовленні від 1500 грн',
  // deliveryCourier:'Доставка курьером на ваш адрес (м. Киев)',
  // deliveryCourierDescription:'Бесплатно при заказе от 3000 грн',
  // deliveryUkrPoshta:'Самовивіз з відділення Укрпошти',
  // deliveryUkrPoshtaDescription:'Безкоштовно при замовленні от 3000 грн',
  // paymentCash:'Оплата при отримані',
  // paymentCard:'Картою онлайн',
  // enterCertificate:'Введіть номер сертифіката',
  //
  // emptyData: 'Даних немає',
  //
  // faq: 'Поширені запитання',
  //
  // aboutUs: 'Про нас',
  // aboutUsHistory: 'Бренд и история',
  // aboutUsPhilosophy: 'Философия',
  // aboutUsProduction: 'Производство',
  // aboutUsMedia: 'Медиа',
  // phoneNumber: 'Номер телефона',


//  ---------------------------------------------------------------------------------------------------

  // personalData: 'Особисті данні',
  // discountClubDelav: 'Дисконт клуб DeLav',
  // recommendationsProfessional: 'Рекомендації косметолога',
  // newsletters: 'Рассылки',
  // questionnaire: 'Анкета',
  // exit: 'ВИХІД',
  // questionnaireTitle: 'Анкета для подбора индивидуального ухода',
  // cancel: 'Відмінити',
  // contacts: 'Контакти',
  // phone: 'Телефон',
  // deliveryCourierTitle: 'Доставка курьером',
  // defaultAddress: 'Адрес по замовчуванню',
  // anotherAddresses: 'Другі адреса',
  // novaPoshta: 'Нова пошта',
  // newAddress: 'Новый адрес',
  // additionalInfo: 'Додаткова інформація',
  // takeCity: 'Виберіть місто',
  // takeDepartment: 'Виберіть відділення',
  // add: 'Додати',
  // orderNumber: 'Номер заказа',
  // date: 'Дата',
  // status: 'Статус',
  // code: 'Код',
  // deliveryAddress: 'Адрес доставки',
  // orderTo: 'Замовлено на',
  // sumDiscount: 'Сумма скидок',
  // total: 'Итого',
  // repeatOrder: 'Повторить заказ',
  // articleType: 'Артикул',
  // inStock: 'В наличии',
  // endDiscount: 'Завершается',
  // inquireDetails: 'Узнать детали',
  // noProducts: 'Нету продуктов',
  // sort: 'Сортувати',
  // resetFilters: 'Сбросить фильтры',
  // atLeast8Characters: 'Не менее 8 символов',
  // basket: 'Корзина',
  // fillYourContacts: 'Залиште свої контакти і ми з Вами зв\'яжемось.',
  // submittingPrivacyPolicy: 'Отправляя заявку вы соглашаетесь с политикой конфиденциальности',
  // phones: 'Телефони',
  // mails: 'Почта',
  // address: 'Адрес',
  // schedule: 'График работы',
  // byClickingYouAgreeToOur: 'Нажимая кнопку отправить сообщение вы соглашаетесь с нашей',
  // privacyPolicy: 'политикой конфиденциальности',
  // loadFile: 'Выбрать файл',
  // firstToKnowAboutSales: 'Узнавайте о распродажах, новинках и акциях первыми!',
  // subscribe: 'Підписатися',
  // max5Mb: 'Максимум 5 МБ',
  // inUkraine: 'По Украине',
  // whereToBy: 'Где купить',
  //
  //
  // emptyData: 'Даних немає',
  // media: 'Медіа',
  // faq: 'Поширені запитання',
  // // library: 'Бібліотека',
  // blog: 'Блог',
  // video: 'Відео',
  // videos: 'Відео',
  // watchTheVideo: 'Дивитись відео',
  // watch: 'Переглянути',
  // aboutUs: 'Про нас',
  // readMore: 'Читати далі',
  // masterClasses: 'Мастер класи',
  // masterClass: 'Мастер клас',
  // articles: 'Статті',
  // article: 'Стаття',
  // ingredients: 'Інгредієнти',
  // ingredient: 'Інгредієнт',
  // backToIngredients: 'Назад до списку інгрідієнтів',
  // productsWithIngredient: 'Продукты с этим ингредиентом:',
  // beforeAfterTitle: 'Фото До/Після',
  // prev: 'Попередній',
  // next: 'Наступний',
  // before: 'До',
  // after: 'Після',
  // enterNewPassword: 'Введите новый пароль.',
  // enterNewPasswordRules: 'Он должен содержать не менее 6 символов (A-z, 0-9)',
  // passwordPlaceholder: 'Не менее 6 символов',
  // message: 'Сообщение',
  // sendMessage: 'Отправить сообщение',
  // work: 'Адрес работы, название',
  // load: 'Загрузить',
  // photoAndCertificates: 'Фото диплом/сертификаты',
  // all: 'Всі продукти',
  // shortStreet: 'вул.',
  // shortCity: 'м.',
  // cosmetologistBonusText: 'Бонусы равны величине накопительной скидки от суммы закупленных товаров, в течение 90 календарных дней. Спустя 90 дней после заказа рассчитывается процент скидки: \n' +
  //   '1 Бонус = 1 Гривна. \n' +
  //   'После начисления бонусы сохраняются 90 дней, затем неиспользованные бонусы аннулируются.\n' +
  //   'Бонусами можно оплатить не более 30% от суммы заказа.',
};

//      {{ $t('showMore') }}
